import { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import { activityRegistration, getActivityList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import {
  customFormatDatetimeWithLocale,
  dateCompare,
  formatDate,
} from "../../util/utils";
import { FormattedDate, FormattedMessage } from "react-intl";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import { Chip } from "@mui/material";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { Card, Col, Row } from "react-bootstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

export const Activities = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: Store) => state.activityReducer.isLoading,
  ) as any;
  const onSaveLoading = useSelector(
    (state: Store) => state.activityReducer.onSaveLoading,
  ) as any;
  const activityLst = useSelector(
    (state: Store) => state.activityReducer.activityLst,
  ) as any;
  const userData = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;
  const totalRecord = useSelector(
    (state: Store) => state.activityReducer.totalRecord,
  ) as any;
  const [registerAct, setRegisterAct] = useState({ id: 0, modal: false });
  const [pageNumber, setPageNumber] = useState(1);

  const locale = useSelector((state: Store) => state.userDataReducer.local) as
    | "zh"
    | "en";
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [sortBy, setSortBy] = useState("startDate");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterQ, setFilterQ] = useState("");
  const [filterText, setFilterText] = useState("");
  const [Alltext, setAllText] = useState("");

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(filterText);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [filterText]);

  useEffect(() => {
    setFilter(filterText);
  }, [Alltext]);

  const setFilter = (text: string) => {
    let q = "";
    if (text.length > 0) q = q + `&q=${text?.trim()}`;

    setFilterQ(q);
    setPageNumber(1);
    return 0;
  };

  useEffect(() => {
    dispatch(
      getActivityList(
        false,
        pageNumber,
        { by: sortBy, order: sortOrder },
        filterQ,
        pageLimit,
      ),
    );
  }, [filterQ, sortOrder, sortBy, pageNumber, pageLimit]);

  const registerActivity = (id: any) => {
    dispatch(activityRegistration(true, id));
    setRegisterAct(() => ({ id: 0, modal: false }));
    setPageNumber(1);
  };

  const onPageChange = (pageNum) => {
    setPageNumber(pageNum);
  };

  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage id="activity" defaultMessage="Activity" />
              </h1>
            </div>
          </div>
        </header>

        <section className="page-content container-fluid">
          {isLoading && <CenterLoader />}
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Row className="p-20">
                <Col style={{ display: "inline-flex" }}>
                  <label style={{ position: "absolute", bottom: "0" }}>
                    <FormattedMessage id="Filter" defaultMessage="Filter" />
                    {": "}
                  </label>
                  <input
                    onChange={(e) => setFilterText(e.target.value)}
                    type="text"
                    style={{
                      maxWidth: "220px",
                      paddingLeft: "39px",
                      borderRadius: "0px",
                      border: "none",
                      borderBottom: "1px solid",
                    }}
                    className="form-control"
                  />
                </Col>
              </Row>

              {activityLst.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-striped m-0">
                      <thead>
                        <tr>
                          <th
                            key="head_name"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_name")}
                          >
                            {" "}
                            <FormattedMessage
                              id="activity.Name"
                              defaultMessage="Name"
                            />
                            {sortBy === "head_name" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_class"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_class")}
                          >
                            {" "}
                            <FormattedMessage
                              id="activity.Class"
                              defaultMessage="Class"
                            />
                            {sortBy === "head_class" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_start"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_start")}
                          >
                            {" "}
                            <FormattedMessage
                              id="activity.Start"
                              defaultMessage="Start"
                            />
                            {sortBy === "head_start" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_end"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_end")}
                          >
                            {" "}
                            <FormattedMessage
                              id="activity.End"
                              defaultMessage="End"
                            />
                            {sortBy === "head_end" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_application"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_application")}
                          >
                            <FormattedMessage
                              id="activity.Application"
                              defaultMessage="Close Application "
                            />
                            {sortBy === "head_application" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            key="head_register"
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("head_register")}
                          >
                            {" "}
                            <FormattedMessage
                              id="activity.Register"
                              defaultMessage="Register"
                            />
                            {sortBy === "head_register" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {activityLst &&
                          activityLst.map((act: any) => (
                            <tr>
                              <td>{act.name}</td>
                              <td>{act.class.name}</td>
                              <td>
                                {customFormatDatetimeWithLocale(
                                  act.startDate,
                                  locale,
                                )}
                              </td>
                              <td>
                                {customFormatDatetimeWithLocale(
                                  act.endDate,
                                  locale,
                                )}
                              </td>
                              <td>
                                {customFormatDatetimeWithLocale(
                                  act.closeApplicationDate,
                                  locale,
                                )}
                              </td>
                              <td>
                                {act.needToRegister ? (
                                  <>
                                    {(act?.studentActivityList || [])
                                      .map((i: any) => i?.Activity?.id)
                                      .includes(act.id) ||
                                    !dateCompare(
                                      new Date(act.closeApplicationDate),
                                      new Date(),
                                    ) ? (
                                      " "
                                    ) : (act?.studentActivityList || [])
                                        .map((i: any) => i?.student?.id)
                                        .includes(userData.id) ? (
                                      <Button
                                        onClick={() =>
                                          setRegisterAct({
                                            id: act.id,
                                            modal: true,
                                          })
                                        }
                                        size="sm"
                                        disabled
                                        hidden
                                      >
                                        <FormattedMessage
                                          id="page.activityRegistered"
                                          defaultMessage="Registered"
                                        />
                                      </Button>
                                    ) : act?.registerCount < act?.user_limit ? (
                                      <Button
                                        onClick={() =>
                                          setRegisterAct({
                                            id: act.id,
                                            modal: true,
                                          })
                                        }
                                        hidden
                                        size="sm"
                                      >
                                        <FormattedMessage
                                          id="page.activityRegister"
                                          defaultMessage="Register"
                                        />
                                      </Button>
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          setRegisterAct({
                                            id: act.id,
                                            modal: true,
                                          })
                                        }
                                        size="sm"
                                        disabled
                                        hidden
                                      >
                                        <FormattedMessage
                                          id="page.activityRegister"
                                          defaultMessage="Register"
                                        />
                                      </Button>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Chip
                                      size={"small"}
                                      variant="filled"
                                      color="primary"
                                      label={
                                        <>
                                          <FormattedMessage
                                            id="subHead.openActivity"
                                            defaultMessage="Open Activity"
                                          />
                                        </>
                                      }
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <div className="mt-3">
                          <Pagination
                            itemsPerPage={pageLimit}
                            totalPages={totalRecord}
                            onPageChage={onPageChange}
                            page={pageNumber - 1}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <p>{!isLoading && <NoDatFound />}</p>
              )}
            </Card.Body>
          </Card>
        </section>
        {registerAct.modal && (
          <DeletePopup
            message={
              <FormattedMessage
                id="msg.activityRegister"
                defaultMessage="Are you sure want to register to activity ?"
              />
            }
            onClose={() => setRegisterAct(() => ({ id: 0, modal: false }))}
            open={registerAct.modal}
            onConfirm={() => registerActivity(registerAct.id)}
            isLoading={onSaveLoading}
          />
        )}
      </div>
    </div>
  );
};

export default Activities;
