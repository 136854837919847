import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

interface ManagementRowNameTooltipProps {
  name: string;
}

const ManagementRowNameTooltip = (props: ManagementRowNameTooltipProps) => {
  const { name } = props;
  return (
    <td style={{ overflow: "hidden", maxWidth: "32px", whiteSpace: "nowrap" }}>
      <Tooltip
        arrow
        followCursor
        title={<Typography color="inherit">{name}</Typography>}
      >
        <div>{name}</div>
      </Tooltip>
    </td>
  );
};

export default ManagementRowNameTooltip;
