import { Table, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createVPSMachine,
  getVPSMachineList,
  getVPSMachineTypeList,
  VPSMachineContactList,
} from "../../redux/action/VPSmachineActions";
import { Store } from "../../redux/Actions";
import { TBL_HEAD } from "../../Static/VpsMachineConstant";
import DeletePopup from "../../Components/common/Popups/DeletePopup";
import { toast } from "react-toastify";
import NoDatFound from "../../Components/common/NoDatFound";
import { FormattedMessage, useIntl } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { getGiftVPSList, getQR } from "../../redux/action";
import { FaAngleLeft, FaCheck, FaTimes, FaTimesCircle } from "react-icons/fa";
import { appIntl } from "../../util/intl";

const urls = [
  "https://forexforest.com/hk/enroll/gems-panel-with-type-lite-vps-package/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-a-vps-package/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-b-vps-package/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-c-vps-package/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-d-vps-package/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-e-vps-package/",
];

export const VpsCreation = () => {
  const vpsList = useSelector(
    (state: Store) => state.VPSmachineReducer.VPSMachineList,
  );
  const vpsTypeList = useSelector(
    (state: Store) => state.VPSmachineReducer.VPSMachineTypeList,
  );

  const intl = useIntl();

  const [vpsMappedList, setVpsMappedList] = useState<any>({});
  const [btnClick, setbtnClick] = useState<any>(false);
  const [btnModal, setModal] = useState<any>(false);
  const [text, setText] = useState<any>("");
  const [userCreated, setUserCreated] = React.useState<Array<any>>([]);
  const [adminAllowed, setAdminAllowed] = React.useState<Array<any>>([]);
  const [optionValueSelect, setOptionValueSelect] = useState<Array<any>>([]);

  const [errorMessage, setErrorMessage] = useState<boolean[]>([]);

  const handleErrorMessage = (index: number) => {
    setErrorMessage((prev) => {
      const newArr = [...prev];
      newArr[index] = true;
      return newArr;
    });
  };

  const myInfo: any = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as Array<Object>;

  const firstClassName =
    (myInfo.userClass || []).filter(
      (classTaken) => classTaken.status === "approved",
    )[0]?.class?.name || "";

  const email = myInfo.email;
  const englishFirstName = myInfo.englishFirstName;
  const englishLastName = myInfo.englishLastName;
  const englishFullName = `${englishFirstName} ${englishLastName}`;

  const isAPILoading = useSelector(
    (state: Store) => state.VPSmachineReducer.isLoading,
  );
  const giftDetails = useSelector(
    (state: Store) => state.giftReducer.giftDetails,
  );

  const [confirmationPopupConfig, setConfirmationPopupConfig] = useState<any>({
    open: false,
    message: "",
    vpsType: "",
    vpsMonths: "",
  });

  const [confirmationPopupLearnMore, setConfirmationPopupLearnMore] =
    useState<boolean>(false);
  const [learnMoreUrl, setLearnMoreUrl] = useState<string>("");

  const prop = [
    "OS",
    "processor_cpu",
    "RAM",
    "storage",
    "VPS_Location",
    "isMT4Installed",
    // "numberOfMt4",
    // "broker",
    "EA",
    "ExpertAdvisorVersionList",
    // "DefaultEASet",
    "indicator",
    // "DefaultIndicatorSet",
    "utilities",
    // "DefaultUtilitiesSet",
    // "GEMS_server_connection",
    // "minimum_requirement",
    "subscription_period",
  ];

  const [vpsTypeMapping, setVpsTypeMapping] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVPSMachineList());
    dispatch(getVPSMachineTypeList());
    dispatch(getQR());
    dispatch(getGiftVPSList());
  }, []);

  useEffect(() => {
    const arrAdminAllowed = [];
    const arrUserCreated = [];

    const vpsData = myInfo.UsersVpsData || [];
    vpsData.map((v: any) => {
      if (v.adminAllowed) {
        arrAdminAllowed.push(v.vpsType.id);
      }
      if (v.isCreated) {
        arrUserCreated.push(v.vpsType.id);
      }
    });
    if (arrAdminAllowed.length > 0) {
      setAdminAllowed(arrAdminAllowed);
    }

    if (arrUserCreated.length > 0) {
      setUserCreated(arrUserCreated);
    }
  }, [myInfo]);

  useEffect(() => {
    if (vpsList.length) {
      vpsMachineDataMapping();
    }
  }, [vpsList, vpsTypeList]);

  useEffect(() => {
    const selectedEA = {};
    vpsTypeList.map((i: any, index: any) => {
      selectedEA[index] = [];
    });
    if (vpsTypeList.length) {
      const mapping = {};
      for (const index in vpsTypeList) {
        mapping[index] = vpsTypeList[index].id;
      }
      setVpsTypeMapping(mapping);
    }
  }, [vpsTypeList]);

  const arrayToStr = (arr: any, field: string) => {
    const name: any = [];
    if (arr) {
      arr.map((i: any) => {
        if (Object.prototype.hasOwnProperty.call(i, field)) {
          name.push(i[field]);
        }
      });

      return name.join(",");
    }
    return "";
  };

  const vpsMachineDataMapping = () => {
    const vpsDataMapp: any = {
      OS: [],
      processor_cpu: [],
      RAM: [],
      storage: [],
      VPS_Location: [],
      isMT4Installed: [],
      numberOfMt4: [],
      broker: [],
      EA: [],
      ExpertAdvisorVersionList: [],
      DefaultEASet: [],
      indicator: [],
      DefaultIndicatorSet: [],
      utilities: [],
      DefaultUtilitiesSet: [],
      GEMS_server_connection: [],
      // minimum_requirement: [],
      subscription_period: [],
      price: [],
    };
    vpsTypeList.map((vt: any) => {
      let vpaMachine: any = {};
      vpsList.map((vm: any) => {
        if (vt.id === vm?.VPSType?.id || 0) {
          vpaMachine = vm;
        }
      });

      if (Object.keys(vpaMachine).length > 0) {
        vpsDataMapp["OS"].push(vpaMachine["OS"]);
        vpsDataMapp["processor_cpu"].push(vpaMachine["processor_cpu"]);
        vpsDataMapp["RAM"].push(vpaMachine["RAM"]);

        if (vpaMachine["storage"] == -1) {
          vpsDataMapp["storage"].push(["More"]);
        } else {
          vpsDataMapp["storage"].push(vpaMachine["storage"] + " GB");
        }
        vpsDataMapp["VPS_Location"].push(vpaMachine["VPS_Location"]);
        vpsDataMapp["isMT4Installed"].push(vpaMachine["isMT4Installed"]);
        vpsDataMapp["numberOfMt4"].push(vpaMachine["numberOfMt4"]);
        vpsDataMapp["EA"].push(vpaMachine["EA"]);

        vpsDataMapp["broker"].push(arrayToStr(vpaMachine["broker"], "name"));

        // The version name is only for Lite, Pro and Adv
        // Empty array for Special Type
        if (vpaMachine["EA"].length) {
          vpsDataMapp["ExpertAdvisorVersionList"].push(["Lite / Pro / Adv."]);
        } else {
          vpsDataMapp["ExpertAdvisorVersionList"].push([]);
        }
        // vpsDataMapp["DefaultEASet"].push(vpaMachine["DefaultEASet"]);

        vpsDataMapp["indicator"].push(vpaMachine["indicator"]);
        // vpsDataMapp["DefaultIndicatorSet"].push(
        // arrayToStr(vpaMachine["DefaultIndicatorSet"], "name")
        // );
        vpsDataMapp["utilities"].push(vpaMachine["utilities"]);
        // vpsDataMapp["DefaultUtilitiesSet"].push(
        //   arrayToStr(vpaMachine["DefaultUtilitiesSet"], "name")
        // );
        vpsDataMapp["GEMS_server_connection"].push(
          arrayToStr(vpaMachine["GEMS_server_connection"], "name"),
        );
        // vpsDataMapp["minimum_requirement"].push(
        //   vpaMachine["minimum_requirement"]
        // );
        vpsDataMapp["subscription_period"].push(
          vpaMachine["subscription_period"],
        );
        vpsDataMapp["price"].push(vpaMachine["price"]);
      }
    });
    setVpsMappedList(vpsDataMapp);
  };

  const onStartVPSMachine = () => {
    setbtnClick(true);
    dispatch(
      createVPSMachine(
        true,
        confirmationPopupConfig.vpsType,
        confirmationPopupConfig.vpsMonths,
      ),
    );
    const temp = optionValueSelect.map((item: any) => {
      return { ...item, data: "" };
    });
    setOptionValueSelect(temp);
    setConfirmationPopupConfig((prv: any) => ({ ...prv, open: false }));
  };

  const onCreateVPSMachine = (name: string, id: number, index: number) => {
    const cell = optionValueSelect[index];
    if (!cell) {
      toast.info(`${name} is not available at a moment`);
    }

    setConfirmationPopupConfig((prv: any) => ({
      ...prv,
      open: true,
      message: `${name || ""} `,
      vpsType: id,
      vpsMonths: cell.data,
    }));
  };

  useEffect(() => {
    if (!isAPILoading && confirmationPopupConfig.open) {
      setConfirmationPopupConfig((prv: any) => ({
        ...prv,
        open: false,
      }));
    }
  }, [isAPILoading]);

  const checkMonthsFun = ({ id, data }: { id: number; data: string }) => {
    let targetGiftCategoryId = -1;
    switch (data) {
      case "3-months":
        targetGiftCategoryId = 12;
        break;
      case "6-months":
        targetGiftCategoryId = 13;
        break;
      case "12-months":
        targetGiftCategoryId = 14;
        break;
      default:
        break;
    }

    return giftDetails?.gift?.some(
      (g) =>
        g?.giftCategory?.id === targetGiftCategoryId &&
        Number(g?.code || 0) === vpsTypeMapping[id],
    );
  };

  const checkPermission = (typeId: number) => {
    const cell = optionValueSelect[typeId];
    return cell && checkMonthsFun(cell);
  };

  const handleOnChange = (index, data) => {
    const selectOption = {
      id: index,
      data: data.target.value,
    };
    setOptionValueSelect((oldOptionValueSelect) => {
      const newOptionValueSelect = [...oldOptionValueSelect];
      newOptionValueSelect[index] = selectOption;
      return newOptionValueSelect;
    });

    const availableGifts = giftDetails?.gift?.some((g) => {
      return (
        parseInt(g?.code) === vpsTypeMapping[index] &&
        ((data.target.value === "3-months" && g?.giftCategory?.id === 12) ||
          (data.target.value === "6-months" && g?.giftCategory?.id === 13) ||
          (data.target.value === "12-months" && g?.giftCategory?.id === 14))
      );
    });

    if (!availableGifts) {
      toast.warn(
        appIntl().formatMessage({ id: "no.sufficient.gift.to.create.vps" }),
      );
    }
  };

  const handleValue = (index) => {
    const cell = optionValueSelect[index];
    return cell?.data;
  };

  const handleRedirect = () => {
    window.open(learnMoreUrl, "blank");
    setConfirmationPopupLearnMore(false);
    setLearnMoreUrl("");
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="vpsMachine.VPS"
                  defaultMessage="VPS Machine"
                />
              </h1>
            </div>
          </div>
        </header>

        <section className="page-content container-fluid">
          {isAPILoading && <CenterLoader />}

          <div className="card">
            <div className="card-body p-0">
              {vpsTypeList.length ? (
                <Table
                  responsive
                  hover
                  striped
                  className="m-0 vps-machine-table text-center"
                >
                  <thead className="bg-danger">
                    <tr>
                      <th className="text-left text-black font-weight-bold p-l-10">
                        {" "}
                        <FormattedMessage
                          id="vpsMachine.Types"
                          defaultMessage="Types"
                        />{" "}
                      </th>
                      {vpsTypeList.map((i: any) => (
                        <th id={i.id} className="lh-24">
                          {i.typeName} <br />{" "}
                          <FormattedMessage
                            id="vpsMachine.VPS1"
                            defaultMessage="VPS"
                          />{" "}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {prop.map((pr: any) => (
                      <tr>
                        <th className="text-left text-black p-l-10">
                          <i className="zmdi zmdi-chevron-right zmdi-hc-2x vps-arrow mr-1"></i>{" "}
                          {TBL_HEAD[pr]}
                        </th>
                        {(vpsMappedList[pr] || []).map(
                          (vp: any, index: any) => (
                            <td>
                              {pr === "isMT4Installed" ? (
                                <i
                                  className={
                                    vp ? "vps-check" : "vps-check text-danger"
                                  }
                                >
                                  {vp ? <FaCheck /> : <FaTimes />}
                                </i>
                              ) : pr === "EA" ? (
                                <>
                                  <ul>
                                    {vp.map((i) => (
                                      <li style={{ textAlign: "left" }}>
                                        {i.name}
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              ) : pr === "ExpertAdvisorVersionList" ? (
                                <>
                                  <ul>
                                    {vp.map((i) => (
                                      <li style={{ textAlign: "left" }}>{i}</li>
                                    ))}
                                  </ul>
                                </>
                              ) : pr === "DefaultEASet" ? (
                                <>{vp[0].name}</>
                              ) : pr === "subscription_period" ? (
                                <>
                                  <select
                                    className="vps-select"
                                    onChange={(e) => handleOnChange(index, e)}
                                    value={handleValue(index)}
                                    defaultValue={""}
                                  >
                                    <option disabled value="">
                                      Select Redemption Period
                                    </option>
                                    {vp &&
                                      vp.map((i) => (
                                        <option
                                          style={{ textAlign: "left" }}
                                          value={i.name}
                                        >
                                          {i.name}
                                        </option>
                                      ))}
                                  </select>
                                </>
                              ) : (
                                <>{vp}</>
                              )}
                            </td>
                          ),
                        )}
                      </tr>
                    ))}
                  </tbody>

                  <tbody>
                    <tr>
                      <th className="text-left text-black p-l-10">
                        <i className="zmdi zmdi-chevron-right zmdi-hc-2x vps-arrow mr-1"></i>{" "}
                        <FormattedMessage
                          id="vpsMachine.redeem"
                          defaultMessage="Redeem VPS"
                        />{" "}
                      </th>
                      {vpsTypeList.map((i: any, index) => (
                        <td>
                          {i.id !== 6 && (
                            <>
                              <button
                                disabled={btnClick}
                                id={index}
                                onClick={
                                  !checkPermission(parseInt(index))
                                    ? () => handleErrorMessage(parseInt(index))
                                    : () =>
                                        onCreateVPSMachine(
                                          i.typeName,
                                          i.id,
                                          index,
                                        )
                                }
                                className={
                                  !checkPermission(parseInt(index)) || btnClick
                                    ? "btn btn-default btn-sm h-35 btn-block"
                                    : "btn btn-danger btn-sm h-35 btn-block"
                                }
                                style={
                                  !checkPermission(parseInt(index))
                                    ? {
                                        opacity: 0.65,
                                      }
                                    : {}
                                }
                              >
                                {" "}
                                <FormattedMessage
                                  id="vpsMachine.StartVPS"
                                  defaultMessage="Start Create VPS"
                                />{" "}
                              </button>
                              {errorMessage[index] &&
                              !checkPermission(parseInt(index)) ? (
                                <div className="text-error-login">
                                  Please Select Redemption Period
                                </div>
                              ) : null}
                            </>
                          )}
                          {i.id === 6 && (
                            <button
                              onClick={() => setModal(true)}
                              id={index}
                              className={
                                !checkPermission(parseInt(index)) && btnClick
                                  ? "btn btn-default btn-sm h-35 btn-block"
                                  : "btn btn-danger btn-sm h-35 btn-block"
                              }
                            >
                              {" "}
                              <FormattedMessage
                                id="vpsMachine.CONTACTUS"
                                defaultMessage="CONTACT US"
                              />{" "}
                            </button>
                          )}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                  <tbody>
                    <th className="text-left text-black p-l-10">
                      <i className="zmdi zmdi-chevron-right zmdi-hc-2x vps-arrow mr-1"></i>{" "}
                      Gems Panel package with VPS Machine
                    </th>
                    {urls.map((url, i) => {
                      let create_url = `${url}?`;
                      create_url += `billing_first_name=${englishFullName}`;
                      create_url += `&billing_email=${email}`;
                      create_url += `&your_class=${firstClassName}`;

                      if (i === 0) {
                        return (
                          <td key={i}>
                            <div>
                              <div
                                className=""
                                style={{
                                  backgroundColor: "lightgray",
                                  borderRadius: "0.5rem",
                                  opacity: 0.6,
                                }}
                              >
                                <div style={{ color: "#212529" }}>
                                  Details & Pay
                                </div>
                              </div>
                            </div>
                          </td>
                        );
                      }

                      return (
                        <td key={i}>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setLearnMoreUrl(create_url);
                              setConfirmationPopupLearnMore(true);
                            }}
                          >
                            <div
                              className=""
                              style={{
                                backgroundColor: "#54b49c",
                                borderRadius: "0.5rem",
                              }}
                            >
                              <div style={{ color: "#ffffff" }}>
                                Details & Pay
                              </div>
                            </div>
                          </div>
                        </td>
                      );
                    })}
                    <td></td>
                  </tbody>
                </Table>
              ) : (
                <p>{!isAPILoading && <NoDatFound />}</p>
              )}
            </div>
          </div>
          <div className="create-vps-warning-banner">
            <FormattedMessage
              id="vps.creation.learnmore"
              defaultMessage="This subscription pertains to the Gems Panel with VPS and does not include the usage rights for Gems EA."
            />
          </div>
        </section>
      </div>
      {btnModal && (
        <Modal
          centered
          size={"lg"}
          show={btnModal}
          className="modal-content-md"
        >
          <Modal.Header>
            <h4 className="modal-title">
              <FormattedMessage
                id="vpsMachine.GemsTeam"
                defaultMessage="Contact GemsFX Team"
              />
            </h4>
            <button className="close" onClick={() => setModal(false)}>
              <FaTimesCircle />
            </button>
          </Modal.Header>
          <Modal.Body>
            <b>
              <FormattedMessage
                id="vpsMachine.send.email"
                defaultMessage="Are you sure want to send email ? "
              />
            </b>
            <div className="form-group">
              <FormattedMessage id="vpsMachine.Email" defaultMessage=" Email" />
              <input
                type="text"
                name="email"
                className="form-control"
                value="info@forexforest.com"
                disabled
              />
              <br />
              <input
                type="text"
                name="email"
                className="form-control"
                value={text}
                onChange={(e: any) => {
                  setText(e.target.value);
                }}
                placeholder={intl.formatMessage({
                  id: "ponts.Message",
                  defaultMessage: "Message",
                })}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                setText("");
                setModal(false);
              }}
            >
              {" "}
              <FormattedMessage
                id="vpsMachine.Cancle"
                defaultMessage=" Cancel"
              />
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                if (text.trim().length > 0) {
                  dispatch(
                    VPSMachineContactList(true, {
                      email: "info@forexforest.com",
                      message: text,
                    }),
                  );
                  setModal(false);
                  setText("");
                } else {
                  toast.error("Message Please");
                }
              }}
            >
              <FormattedMessage
                id="vpsMachine.Send"
                defaultMessage=" Send... "
              />
            </button>
          </Modal.Footer>
        </Modal>
      )}

      <DeletePopup
        message={
          <FormattedMessage
            id="msg.vps"
            defaultMessage="are you sure want to create vps machine ?"
            values={{ name: confirmationPopupConfig.message }}
          />
        }
        onClose={() =>
          setConfirmationPopupConfig((prv: any) => ({ ...prv, open: false }))
        }
        open={confirmationPopupConfig.open}
        onConfirm={() => onStartVPSMachine()}
        isLoading={isAPILoading}
      />

      <DeletePopup
        message={
          <FormattedMessage
            id="vps.creation.learnmore"
            defaultMessage="This subscription pertains to the Gems Panel with VPS and does not include the usage rights for Gems EA."
          />
        }
        onClose={() => {
          setConfirmationPopupLearnMore(false);
          setLearnMoreUrl("");
        }}
        open={confirmationPopupLearnMore}
        onConfirm={() => handleRedirect()}
        isLoading={false}
      />
    </div>
  );
};

export default VpsCreation;
