import { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Col, Breadcrumb } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { dispatch } from "../../redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Store } from "../../redux/Actions";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CurrentVersion from "./CurrentVersion";
import ArchivedVersion from "./ArchivedVersion";
import { getUtilityVersionList } from "../../redux/action";
import { Link } from "react-router-dom";
import { saveFile } from "../../util/utils";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import noImage from "../../assets/img/noImage.jpeg";
import defaultImage from "../../assets/img/Indicator.svg";
import { FormattedMessage } from "react-intl";
import { ProgressDownload } from "../../Components/common/ProgressDownload";

export const UtilityVersionDetail = () => {
  const utilityDetail = useSelector(
    (state: Store) => state.utilityReducers.utilityDetail,
  ) as any;
  const currentData = useSelector(
    (state: Store) => state.utilityReducers.currentVData,
  ) as Array<any>;
  const archiveData = useSelector(
    (state: Store) => state.utilityReducers.archiveVData,
  ) as Array<any>;
  const advertisement = useSelector(
    (state: Store) => state.utilityReducers.advertisement,
  ) as Array<any>;

  const useParam = useParams();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const isLoading = useSelector(
    (state: Store) => state.utilityReducers.isLoading,
  ) as any;
  const location = useLocation();
  const btnRef = useRef<any>();

  const [Load, setLoad] = useState<any>([]);

  useEffect(() => {
    const { id } = useParam;
    const { state }: any = location;
    const isGranted = state?.isGranted || false;

    if (id && parseInt(id) > 0) {
      dispatch(getUtilityVersionList(false, id, isGranted));
    }
  }, []);

  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  const onDonwloadButtonClick = (id: any, url: any) => {
    if (!Load.includes(`${id}`)) {
      document.getElementById(`${id}1`)?.classList.remove("progress-hide");
      if (btnRef.current) {
        btnRef?.current.downloadFiles(url, `${id}`);
        setLoad((prv: any) => [...prv, `${id}`]);
      }
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <section className="page-content container-fluid">
          <Breadcrumb>
            <Breadcrumb.Item
              style={{ background: "transparent", verticalAlign: "middle" }}
            >
              <Link to="/utilities">
                {" "}
                <span style={{ verticalAlign: "middle" }}>
                  {" "}
                  <BsChevronLeft />
                  <FormattedMessage
                    id="utility"
                    defaultMessage="Utility"
                  />{" "}
                </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          {isLoading && <CenterLoader />}
          {utilityDetail && Object.keys(utilityDetail).length > 0 ? (
            <>
              <Row>
                <Col sm={6} md={5} lg={3}>
                  <Card style={{ width: "100%" }}>
                    <Card.Body>
                      <Row className="mb-3">
                        <Col style={{ textAlign: "center" }}>
                          <h4> {utilityDetail?.name}</h4>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col style={{ textAlign: "center" }}>
                          {utilityDetail?.image_path ? (
                            <div>
                              <img
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                                src={utilityDetail?.image_path}
                              />
                            </div>
                          ) : (
                            <i>
                              {" "}
                              <img
                                src={defaultImage}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                              />{" "}
                            </i>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "center" }}>
                          <h4 style={{ fontWeight: "500" }}>
                            USD$ {utilityDetail?.sellingPrice}
                          </h4>
                        </Col>
                      </Row>

                      <Row className="mb-1">
                        <Col style={{ textAlign: "left" }}>
                          <p style={{ lineHeight: "1.5" }}>
                            {utilityDetail?.info}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col style={{ textAlign: "left" }}>
                          {currentData?.[0]?.download_path ? (
                            <ProgressDownload
                              Load={Load}
                              setLoad={setLoad}
                              elId={`t${currentData[0]?.id}1`}
                              ref={btnRef}
                            >
                              <Button
                                style={{ width: "100%", whiteSpace: "normal" }}
                                size="sm"
                                id={`t${currentData[0]?.id}`}
                                onClick={() =>
                                  onDonwloadButtonClick(
                                    `t${currentData[0]?.id}`,
                                    currentData[0]?.download_path,
                                  )
                                }
                              >
                                <FormattedMessage
                                  id="head.DownloadLatestTool"
                                  defaultMessage="Download Latest Tool"
                                />
                              </Button>
                            </ProgressDownload>
                          ) : (
                            <Button
                              style={{ width: "100%", whiteSpace: "normal" }}
                              size="sm"
                              disabled
                            >
                              <FormattedMessage
                                id="head.DownloadLatestTool"
                                defaultMessage="Download Latest Tool"
                              />
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "left" }}>
                          {currentData?.[0]?.manual_path ? (
                            <ProgressDownload
                              Load={Load}
                              setLoad={setLoad}
                              elId={`m${currentData[0]?.id}1`}
                              ref={btnRef}
                            >
                              <Button
                                color="danger"
                                style={{
                                  width: "100%",
                                  whiteSpace: "normal",
                                  backgroundColor: "",
                                }}
                                id={`m${currentData[0]?.id}`}
                                onClick={() =>
                                  onDonwloadButtonClick(
                                    `m${currentData[0]?.id}`,
                                    currentData[0]?.manual_path,
                                  )
                                }
                                size="sm"
                              >
                                <FormattedMessage
                                  id="head.DownloadLatestManual"
                                  defaultMessage="Download Latest Manual"
                                />
                              </Button>
                            </ProgressDownload>
                          ) : (
                            <Button
                              color="danger"
                              style={{
                                width: "100%",
                                whiteSpace: "normal",
                                backgroundColor: "",
                              }}
                              size="sm"
                              hidden
                            >
                              <FormattedMessage
                                id="head.DownloadLatestManual"
                                defaultMessage="Download Latest Manual"
                              />
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={6} md={7} lg={9}>
                  <Card style={{ width: "100%" }}>
                    <Card.Body>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          padding: "0px",
                        }}
                        style={{ padding: "0px" }}
                        className={classes.tabs}
                      >
                        <Tabs
                          value={activeTab}
                          onChange={setAciveTab}
                          className={classes.tabs}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            label={
                              <FormattedMessage
                                id="tab.CURRENT"
                                defaultMessage="Current"
                              />
                            }
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={
                              <FormattedMessage
                                id="tab.ARCHIVE"
                                defaultMessage="Archieved"
                              />
                            }
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <TabPanel value={activeTab} index={0}>
                        {/* <PendingList /> */}
                        <CurrentVersion versionLst={currentData} />
                      </TabPanel>
                      <TabPanel value={activeTab} index={1}>
                        {/* <RecordsList /> */}
                        <ArchivedVersion versionLst={archiveData} />
                      </TabPanel>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {/* <div>
                <h2 className="mb-4">
                  <FormattedMessage
                    id="advertisement"
                    defaultMessage="Advertisement" /></h2>
                <Row>
                  {advertisement && advertisement.length > 0
                    ? advertisement.map((i: any) => (
                      <Col sm={12} md={5} lg={4} className="mb-3 mb-lg-0">
                        <Card className="equal-height">
                          <Card.Header><h4 className="m-0">{i?.name}</h4></Card.Header>
                          <Card.Body className="advertisement-body">
                            {i?.advertisementImage ? (
                              <img src={i?.advertisementImage} className="advertisement-images" />
                            ) : (
                              <img src={require('../../assets/img/noImage.jpeg')} className="advertisement-images" />
                            )}
                                                        <FormattedMessage
                              id="table.head.Description"
                              defaultMessage="Description"
                            /><br />
                            {i?.details ? <div dangerouslySetInnerHTML={{ __html: i?.details }} /> : null}
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                    : null}
                </Row>
              </div> */}
            </>
          ) : (
            <p style={{ lineHeight: "1.5" }}>{!isLoading && <NoDatFound />}</p>
          )}
        </section>
      </div>
    </div>
  );
};

export default UtilityVersionDetail;

const useStyles = makeStyles({
  tabs: {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f57c51",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f57c51",
      BsBorderBottom: "1px solid",
    },
    "& .MuiTypography-root": {
      lineHeight: "1.5",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
