import { VPS } from "../constants/action-types";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";
import { VpsData } from "../Actions";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  vpsLst: [],
  success: false,
  errors: false,
  isAllVpsMachinesListLoading: false,
  isAllVpsMachinesListError: false,
  allVpsMachinesList: [],
  totalVpsMachinesCount: 0,
  isUpdateVpsStatusLoading: false,
  isUpdateVpsStatusError: false,
  isUpdateVpsPasswordLoading: false,
  isUpdateVpsPasswordError: false,
  isUpdateVpsDetailLoading: false,
  isUpdateVpsDetailError: false,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: VpsData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case VPS.VPS_INITLIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case VPS.VPS_SUCCESS:
        return {
          ...state,
          vpsLst: action.payload,
          isLoading: false,
        };

      case VPS.VPS_ERORR:
        return {
          ...state,
          isLoading: false,
        };

      case VPS.ALL_VPS_MACHINES_LIST_INITIALIZATION:
        return {
          ...state,
          isAllVpsMachinesListLoading: true,
          isAllVpsMachinesListError: false,
          totalVpsMachinesCount: 0,
        };

      case VPS.ALL_VPS_MACHINES_LIST_SUCCESS: {
        const res: any = action.payload;
        return {
          ...state,
          isAllVpsMachinesListLoading: false,
          allVpsMachinesList: res.runningVPSMachines,
          totalVpsMachinesCount: res.totalVpsMachinesCount,
        };
      }

      case VPS.ALL_VPS_MACHINES_LIST_ERROR:
        return {
          ...state,
          isAllVpsMachinesListLoading: false,
          isAllVpsMachinesListError: true,
          allVpsMachinesList: [],
          totalVpsMachinesCount: 0,
        };

      case VPS.UPDATE_VPS_MACHINE_STATUS_INITIALIZATION:
        return {
          ...state,
          isUpdateVpsStatusLoading: true,
          isUpdateVpsStatusError: false,
        };

      case VPS.UPDATE_VPS_MACHINE_STATUS_SUCCESS: {
        return {
          ...state,
          isUpdateVpsStatusLoading: false,
        };
      }

      case VPS.UPDATE_VPS_MACHINE_STATUS_ERROR:
        return {
          ...state,
          isUpdateVpsStatusLoading: false,
          isUpdateVpsStatusError: true,
        };

      case VPS.UPDATE_VPS_MACHINE_PASSWORD_INITIALIZATION:
        return {
          ...state,
          isUpdateVpsPasswordLoading: true,
          isUpdateVpsPasswordError: false,
        };

      case VPS.UPDATE_VPS_MACHINE_PASSWORD_SUCCESS: {
        return {
          ...state,
          isUpdateVpsPasswordLoading: false,
        };
      }

      case VPS.UPDATE_VPS_MACHINE_PASSWORD_ERROR:
        return {
          ...state,
          isUpdateVpsPasswordLoading: false,
          isUpdateVpsPasswordError: true,
        };

      case VPS.UPDATE_VPS_MACHINE_DETAIL_INITIALIZATION:
        return {
          ...state,
          isUpdateVpsDetailLoading: true,
          isUpdateVpsDetailError: false,
        };

      case VPS.UPDATE_VPS_MACHINE_DETAIL_SUCCESS: {
        return {
          ...state,
          isUpdateVpsDetailLoading: false,
        };
      }

      case VPS.UPDATE_VPS_MACHINE_DETAIL_ERROR:
        return {
          ...state,
          isUpdateVpsDetailLoading: false,
          isUpdateVpsDetailError: true,
        };

      default:
        return state;
    }
  },
);

export default reducer;
