import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import { dispatch } from "../../redux";
import { getTempoararyAccountList } from "../../redux/action";
import { Store } from "../../redux/Actions";

export const TempararyAccount = () => {
  const account = useSelector(
    (state: Store) => state.accountReducer.tmpAccount,
  ) as Array<Object>;
  const isAPILoading = useSelector(
    (state: Store) => state.accountReducer.isLoading,
  );
  const [pageNumber, setPageNumber] = useState(1);
  const totalRecord = useSelector(
    (state: Store) => state.accountReducer.totalRecord,
  ) as any;

  useEffect(() => {
    dispatch(getTempoararyAccountList(false, 1));
  }, [pageNumber]);

  const onPageChange = (pageNum) => {
    setPageNumber(pageNum);
  };

  return (
    <>
      {isAPILoading && <CenterLoader />}

      {account.length > 0 ? (
        <>
          <div className="table-responsive">
            {" "}
            <table className="table table-hover table-striped m-0">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      id="table.head.Quantity"
                      defaultMessage="Quantity"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Type"
                      defaultMessage="Type"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.RemainingDays"
                      defaultMessage="Remaining Days"
                    />{" "}
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Remark"
                      defaultMessage="Remark"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="table.head.Authorizer"
                      defaultMessage="Authorizer"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {account.length &&
                  account.map((i: any) => (
                    <tr>
                      <td>{i.quantity}</td>
                      <td>{i?.Type || "N/A"}</td>
                      <td>{i?.Remaining_Days || "N/A"}</td>
                      <td>{i.remark}</td>
                      <td>
                        {i?.createdBy.id || "N/A"}(
                        {i?.createdBy.englishFirstName}){" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div>
              <Pagination
                itemsPerPage={process.env.REACT_APP_RECORDS_PER_PAGE}
                totalPages={totalRecord}
                onPageChage={onPageChange}
                page={pageNumber - 1}
              />
            </div>
          </div>
        </>
      ) : (
        <p>{!isAPILoading && <NoDatFound />}</p>
      )}
    </>
  );
};

export default TempararyAccount;
