import React, { useState, useRef } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { formatDate, saveFile } from "../../util/utils";
import Chip from "@mui/material/Chip";
import NoDatFound from "../../Components/common/NoDatFound";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import { getHtml } from "../ExpertAvisors/CurrentVersion";
import { ProgressDownload } from "../../Components/common/ProgressDownload";

const ArchivedVersion = ({ versionLst }: any) => {
  const btnRef = useRef<any>();
  const [Load, setLoad] = useState<any>([]);

  const onDonwloadButtonClick = (id: any, url: any) => {
    if (!Load.includes(`${id}`)) {
      document.getElementById(`${id}1`)?.classList.remove("progress-hide");
      if (btnRef.current) {
        btnRef?.current.downloadFiles(url, `${id}`);
        setLoad((prv: any) => [...prv, `${id}`]);
      }
    }
  };
  return (
    <div>
      {versionLst && versionLst.length ? (
        versionLst.map((v: any, index: any) => (
          <Card
            style={{
              width: "100%",
              boxShadow: "none",
              border: "1px solid #f3f3f3",
            }}
          >
            <Card.Body>
              <h4 style={{ fontWeight: "500" }}>
                {v.version}
                {"   "}({formatDate(v.createdAt, "DD/MM/YYYY")})
              </h4>
              <Row className="mt-1 mb-1">
                <Col>
                  <Typography component="div">{getHtml(v.info)}</Typography>
                </Col>
              </Row>
              <Chip
                size={"small"}
                variant="outlined"
                label={
                  <>
                    <FormattedMessage
                      id="subHead.LibararyVersion"
                      defaultMessage="Libarary Version"
                    />
                    <>{`: ${v?.library?.name || "GEMS.Dll"} - ${
                      v?.libraryVersion?.version
                    }`}</>
                  </>
                }
              />
              <Row className="mt-3">
                <Col>
                  {v.download_path ? (
                    <ProgressDownload
                      Load={Load}
                      setLoad={setLoad}
                      elId={`at${index}1`}
                      ref={btnRef}
                    >
                      <Button
                        id={`at${index}`}
                        onClick={() =>
                          onDonwloadButtonClick(`at${index}`, v?.download_path)
                        }
                        style={{ width: "100%" }}
                        size="sm"
                      >
                        <FormattedMessage
                          id="expert.Current.DownloadTool"
                          defaultMessage="Download Tool "
                        />
                      </Button>
                    </ProgressDownload>
                  ) : (
                    <Button style={{ width: "100%" }} size="sm" disabled>
                      <FormattedMessage
                        id="expert.Current.DownloadTool"
                        defaultMessage="Download Tool"
                      />
                    </Button>
                  )}
                </Col>
                <Col>
                  {v.manual_path ? (
                    <ProgressDownload
                      Load={Load}
                      setLoad={setLoad}
                      elId={`am${index}1`}
                      ref={btnRef}
                    >
                      <Button
                        color="danger"
                        id={`am${index}`}
                        onClick={() =>
                          onDonwloadButtonClick(`am${index}`, v?.manual_path)
                        }
                        style={{ width: "100%", backgroundColor: "" }}
                        size="sm"
                      >
                        <FormattedMessage
                          id="expert.Current.DownloadManual"
                          defaultMessage="Download Manual "
                        />
                      </Button>
                    </ProgressDownload>
                  ) : (
                    <Button
                      color="danger"
                      style={{
                        width: "100%",
                        backgroundColor: "",
                        visibility: "hidden",
                      }}
                      size="sm"
                      disabled
                    >
                      <FormattedMessage
                        id="expert.Current.DownloadManual"
                        defaultMessage="Download Manual"
                      />
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))
      ) : (
        <NoDatFound />
      )}
    </div>
  );
};

export default ArchivedVersion;
