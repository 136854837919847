/* eslint-disable no-case-declarations */
import { GET_ATTENDENCE_LIST } from "../constants/action-types";
import { AttendenceData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  attendenceLst: [],
  onSaveLoading: false,
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: AttendenceData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_ATTENDENCE_LIST.GET_ATTENDENCE_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          totalRecord: 0,
        };

      case GET_ATTENDENCE_LIST.GET_ATTENDENCE_LIST_SUCCESS:
        const allPost: any = action.payload;
        return {
          ...state,
          attendenceLst: allPost.data,
          totalRecord: allPost.totalCount,
          isLoading: false,
        };

      case GET_ATTENDENCE_LIST.GET_ATTENDENCE_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          totalRecord: 0,
        };

      default:
        return state;
    }
  },
);

export default reducer;
