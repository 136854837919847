/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_ACTIVITY_LIST,
  REGISTER_ACTIVITY_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import {
  GET_ACTIVITY_LIST_URL,
  REGISTER_ACTIVITY_LIST_URL,
} from "../../service/apiEndPoints";

export const getActivityList =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getActivityListInit(showToast, pageNumber, sort, filter, pageLimit),
    );
  };

export const getActivityListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_ACTIVITY_LIST_URL}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}${filter}`,
      (res: Object) => dispatch(getActivityListSuccess(res)),
      (err: any) => dispatch(getActivityListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getActivityListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_SUCCESS,
      payload: res,
    });
  };

export const getActivityListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ACTIVITY_LIST.GET_ACTIVITY_LIST_ERROR,
    });
  };

export const activityRegistration =
  (
    showToast = true,
    actId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(activityRegistrationInit(showToast, actId));
  };

export const activityRegistrationInit =
  (
    showToast = true,
    actId: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_INITIALIZATION,
    });
    apiCall(
      REGISTER_ACTIVITY_LIST_URL,
      (res: Object) => dispatch(activityRegistrationSuccess(res)),
      (err: any) => dispatch(activityRegistrationError()),
      { activityId: actId },
      METHOD.POST,
      { showToast },
    );
  };

export const activityRegistrationSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(getActivityList(false, 1, { order: "DESC", by: "startDate" }, ""));
  };

export const activityRegistrationError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: REGISTER_ACTIVITY_LIST.REGISTER_ACTIVITY_LIST_ERROR,
    });
  };
