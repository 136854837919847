import React from "react";
import { Dropdown } from "react-bootstrap";
import { ChangeVpsStatus } from "./ManagementRow";
import { VPSStatus } from "../../../util/constants/TabConstants";
import { FaLock, FaPowerOff, FaEdit } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import { AppendedMyComponent } from "../../append-to-body-drop-down";

interface ManagementRowActionsDropdownProps {
  vpsType: {
    id: number;
    typeName: string;
  };
  instanceId: string;
  setChangeVpsStatus: React.Dispatch<React.SetStateAction<ChangeVpsStatus>>;
  updateUpdateDetailPayLoad: () => void;
}

const ManagementRowActionsDropdown = (
  props: ManagementRowActionsDropdownProps,
) => {
  const { vpsType, instanceId, setChangeVpsStatus, updateUpdateDetailPayLoad } =
    props;

  return (
    <td>
      <Dropdown className="btn-group">
        <Dropdown.Toggle
          id="dropdown-basic"
          className="btn btn-sm btn-icon-only"
        >
          <i className="icon dripicons-dots-3 zmdi-hc-fw"></i>
        </Dropdown.Toggle>
        <AppendedMyComponent>
          <Dropdown.Menu align="end" style={{ width: "auto" }}>
            <Dropdown.Item href="#" onClick={updateUpdateDetailPayLoad}>
              <i className="text-accent-custom">
                <FaEdit />
              </i>
              <FormattedMessage
                id="vps.management.actions.updateDetails"
                defaultMessage="Update Name and Remarks"
              />
            </Dropdown.Item>
            <Dropdown.Item
              href="#"
              onClick={() =>
                setChangeVpsStatus({
                  modal: true,
                  instanceId: instanceId,
                  vpsStatus: VPSStatus.REBOOT,
                  type: vpsType?.id || 1,
                  text: "reboot",
                })
              }
            >
              <i className="text-accent-custom">
                <FaPowerOff />
              </i>
              <FormattedMessage
                id="vps.management.actions.reboot"
                defaultMessage="Reboot"
              />
            </Dropdown.Item>
            <Dropdown.Item
              href="#"
              onClick={() =>
                setChangeVpsStatus({
                  modal: true,
                  instanceId: instanceId,
                  vpsStatus: "resetPassword",
                  type: vpsType?.id || 1,
                  text: "resetPassword",
                })
              }
            >
              <i className="text-accent-custom">
                <FaLock />
              </i>
              <FormattedMessage
                id="vps.management.actions.resetPassword"
                defaultMessage="Reset Password"
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </AppendedMyComponent>
      </Dropdown>
    </td>
  );
};

export default ManagementRowActionsDropdown;
