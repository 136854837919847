import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { dispatch } from "../../redux";
import {
  addAlternativeNameList,
  getAlternativeNameList,
} from "../../redux/action";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { FormattedMessage } from "react-intl";
import { AddAlternativeNameSchema } from "../../util/validationSchema";
import { FaTimesCircle } from "react-icons/fa";

const AddAlterNativeName = ({ onCancle, showModal }: any) => {
  const alterNativeNames = useSelector(
    (state: Store) => state.accountReducer.alterNativeNames,
  );

  const formik = useFormik({
    initialValues: {
      altName: "",
    },
    validationSchema: AddAlternativeNameSchema,
    onSubmit: (values) => {
      dispatch(addAlternativeNameList(true, values.altName));
      onCancle();
    },
  });

  useEffect(() => {
    dispatch(getAlternativeNameList(false));
  }, []);

  const { errors, touched } = formik;

  return (
    <Modal centered size={"lg"} show={showModal} className="modal-content-md">
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage
            id="alternativeName.AddAlternativeName"
            defaultMessage="Add Alternative Name"
          />
        </h4>
        <button className="close" onClick={onCancle}>
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>
          <FormattedMessage
            id="alternativeName.note"
            defaultMessage="Add a maximum of 4 alternative names for your EA usage (for brokers that do not allow an account named after your full HKID name)."
          />
        </p>
        <p>
          <FormattedMessage
            id="alternativeName.note1"
            defaultMessage="Alternative names can contain at most one space. The first character must be a number."
          />
        </p>

        <p>
          <FormattedMessage
            id="alternativeName.note2"
            defaultMessage="This name can only be set once for free - any modifications thereafter would require a fee of 500 points."
          />
        </p>
        <p>
          <FormattedMessage
            id="alternativeName.name1"
            defaultMessage="Alternative Name 1"
          />
          :{alterNativeNames?.alt_name1 || ""}
        </p>
        <p>
          <FormattedMessage
            id="alternativeName.name2"
            defaultMessage="Alternative Name 2"
          />
          :{alterNativeNames?.alt_name2 || ""}
        </p>
        <p>
          <FormattedMessage
            id="alternativeName.name3"
            defaultMessage="Alternative Name 3"
          />
          :{alterNativeNames?.alt_name3 || ""}
        </p>
        <p>
          <FormattedMessage
            id="alternativeName.name4"
            defaultMessage="Alternative Name 4"
          />
          :{alterNativeNames?.alt_name4 || ""}
        </p>

        <div className="form-group">
          <FormattedMessage
            id="alternativeName.head"
            defaultMessage="Please Enter Alternative Name"
          />
          *
          <input
            type="text"
            name="altName"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {errors.altName && touched.altName && (
            <div className="error">
              <FormattedMessage
                id={errors.altName.toString()}
                defaultMessage="require"
              />
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onCancle} type="button">
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          className="btn btn-primary"
          onClick={(values: any) => formik.handleSubmit(values)}
        >
          <FormattedMessage id="page.Submit" defaultMessage="Submit" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAlterNativeName;
