/* eslint-disable no-case-declarations */
import {
  GET_INDUSTRY_LIST,
  GET_JOB_LIST,
  GET_REGISTRATION_MATERIAL,
} from "../constants/action-types";
import { RegisterData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  onSaveLoading: false,
  isLoading: false,
  industry: [],
  jobTitles: [],
  paymentTypeLst: [],
  classChoice: [],
  repeaterClass: [],
  districtLst: [],
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: RegisterData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_INITLIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_SUCCESS:
        return {
          ...state,
          industry: action.payload,
          isLoading: false,
        };

      case GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_ERORR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_JOB_LIST.GET_JOB_LIST_INITLIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_JOB_LIST.GET_JOB_LIST_SUCCESS:
        return {
          ...state,
          jobTitles: action.payload,
          isLoading: false,
        };

      case GET_JOB_LIST.GET_JOB_LIST_ERORR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_SUCCESS:
        const data: any = action.payload;
        return {
          ...state,
          paymentTypeLst: data.paymentTypeData,
          repeaterClass: data.repeaterClass,
          classChoice: data.classChoice,
          districtLst: data.districtData,
          isLoading: false,
        };

      case GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
