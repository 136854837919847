/* eslint-disable no-case-declarations */
import {
  GET_INDICATORS_LIST,
  GET_INDICATOR_VERSION_LIST,
  GET_UNAUTHORIZED_INDICATORS_LIST,
} from "../constants/action-types";
import { IndicatorData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  indicatorList: [],
  onSaveLoading: false,
  indicatorDetail: {},
  UnauthorizedIndicatorsLst: [],
  advertisement: {},
  currentVData: [],
  archiveVData: [],
  availableIndicatorsIsLoading: false,
  unavailableIndicatorsIsLoading: false,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: IndicatorData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_INDICATORS_LIST.GET_INDICATORS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          availableIndicatorsIsLoading: true,
        };

      case GET_INDICATORS_LIST.GET_INDICATORS_LIST_SUCCESS:
        const allPost = action.payload;
        return {
          ...state,
          indicatorList: allPost,
          isLoading: false,
          availableIndicatorsIsLoading: false,
        };

      case GET_INDICATORS_LIST.GET_INDICATORS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          availableIndicatorsIsLoading: false,
        };

      case GET_UNAUTHORIZED_INDICATORS_LIST.GET_UNAUTHORIZED_INDICATORS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          unavailableIndicatorsIsLoading: true,
        };

      case GET_UNAUTHORIZED_INDICATORS_LIST.GET_UNAUTHORIZED_INDICATORS_LIST_SUCCESS:
        return {
          ...state,
          UnauthorizedIndicatorsLst: action.payload,
          isLoading: false,
          unavailableIndicatorsIsLoading: false,
        };

      case GET_UNAUTHORIZED_INDICATORS_LIST.GET_UNAUTHORIZED_INDICATORS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          unavailableIndicatorsIsLoading: false,
        };

      case GET_INDICATOR_VERSION_LIST.GET_INDICATOR_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_INDICATOR_VERSION_LIST.GET_INDICATOR_VERSION_LIST_SUCCESS:
        const versionDetail: any = action.payload;
        return {
          ...state,
          indicatorDetail: versionDetail.indicatorDetail,
          currentVData: versionDetail.data,
          archiveVData: versionDetail.archiveData,
          advertisement: versionDetail.IndicatorAdvertisement,
          isLoading: false,
        };

      case GET_INDICATOR_VERSION_LIST.GET_INDICATOR_VERSION_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
