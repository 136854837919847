/* eslint-disable no-case-declarations */
import {
  GET_TICKET_LIST,
  GET_TICKET_DETAILS,
  ADD_TICKET_LIST,
  EDIT_TICKET_LIST,
  GET_TICKET_ISSUE_TYPE_LIST,
  GET_TICKET_COMMENT_LIST,
  ADD_TICKET_COMMENT_LIST,
  GET_TICKET_TOOL_LIST,
  GET_TICKET_TOOL_VERSION_LIST,
  GET_TICKET_ASSIGNEE_LIST,
} from "../constants/action-types";
import { TicketData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  onCSaveLoading: false,
  ticketList: [],
  commentList: [],
  issueList: [],
  ticketDetails: {},
  ticketComment: [],
  toolList: [],
  toolVersionList: [],
  totalRecords: 0,
  ticketAsignee: [],
  totalCommentRecords: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: TicketData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_TICKET_LIST.GET_TICKET_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_LIST.GET_TICKET_LIST_SUCCESS:
        const allPost: any = action.payload;
        return {
          ...state,
          ticketList: allPost.data,
          ticketDetails: [],
          totalRecords: allPost.totalCount,
          isLoading: false,
        };

      case GET_TICKET_LIST.GET_TICKET_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_TICKET_LIST.ADD_TICKET_LIST_INITIALIZATION:
        return {
          ...state,
          onSaveLoading: true,
        };

      case ADD_TICKET_LIST.ADD_TICKET_LIST_SUCCESS:
        return {
          ...state,
          onSaveLoading: false,
        };

      case ADD_TICKET_LIST.ADD_TICKET_LIST_ERROR:
        return {
          ...state,
          onSaveLoading: false,
        };

      case EDIT_TICKET_LIST.EDIT_TICKET_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case EDIT_TICKET_LIST.EDIT_TICKET_LIST_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case EDIT_TICKET_LIST.EDIT_TICKET_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_TICKET_DETAILS.GET_TICKET_DETAILS_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_DETAILS.GET_TICKET_DETAILS_SUCCESS:
        return {
          ...state,
          ticketDetails: action.payload,
          isLoading: false,
        };

      case GET_TICKET_DETAILS.GET_TICKET_DETAILS_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_SUCCESS:
        const issuesList = action.payload;
        return {
          ...state,
          issueList: issuesList,
          isLoading: false,
        };

      case GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_SUCCESS:
        const commentList: any = action.payload;
        return {
          ...state,
          ticketComment: commentList.data,
          totalCommentRecords: commentList.totalCount,
          isLoading: false,
        };

      case GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_INITIALIZATION:
        return {
          ...state,
          onCSaveLoading: true,
        };

      case ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_SUCCESS:
        return {
          ...state,
          onCSaveLoading: false,
        };

      case ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_ERROR:
        return {
          ...state,
          onCSaveLoading: false,
        };

      case GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_SUCCESS:
        return {
          ...state,
          toolList: action.payload,
          isLoading: false,
        };

      case GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_SUCCESS:
        return {
          ...state,
          toolVersionList: action.payload,
          isLoading: false,
        };

      case GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_SUCCESS:
        return {
          ...state,
          ticketAsignee: action.payload,
          isLoading: false,
        };

      case GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
