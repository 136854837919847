import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage } from "react-intl";
import Loader from "../Loader";

const DeletePopup = ({
  message,
  onClose,
  open,
  onConfirm,
  isLoading,
  isSubText,
  subText,
}: any) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        {message || "Are you sure want to perform action?"}{" "}
        <div style={{ textAlign: "end" }}>
          {" "}
          {isSubText ? <p className="mt-2">{subText}</p> : null}
        </div>
      </DialogTitle>
      <List
        sx={{ pt: 0 }}
        style={{ textAlign: "end", padding: "12px 12px 12px 12px" }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          startIcon={<CloseIcon />}
          className="mr-2"
          color="error"
        >
          <FormattedMessage id="page.No" defaultMessage="No" />
        </Button>
        <Button
          onClick={onConfirm}
          variant="outlined"
          startIcon={<DoneIcon />}
          color="success"
        >
          <FormattedMessage id="page.Yes" defaultMessage="Yes" />

          {isLoading && (
            <div className="item loading-1 ml-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
              >
                <circle
                  style={{ stroke: "#788db4" }}
                  cx="0.6em"
                  cy="0.6em"
                  r="0.5em"
                  className="circle"
                />
              </svg>
            </div>
          )}
        </Button>
      </List>
    </Dialog>
  );
};

DeletePopup.propTypes = {
  message: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubText: PropTypes.bool,
  subText: PropTypes.any,
};

export default DeletePopup;
