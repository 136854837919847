/* eslint-disable no-unused-vars */
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import { thunk } from "redux-thunk";
import { persistStore } from "redux-persist";

import rootReducer from "./reducers";
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export type RootState = ReturnType<typeof store.getState>;

const store: any = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk)),
);

export const persistor = persistStore(store);
export const { dispatch } = store;

export type AppDispatch = typeof store.dispatch;
export default store;
