import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import FileUploadImage from "../../Components/common/MediaUpload/FileUploadImage";
import { setRegisterSteps } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { _RegisterUploadMedia } from "../../service/MediaUpload/classes.service";
import { RegisterStep4Schema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FaTrash } from "react-icons/fa";

const indentityProof: any = [
  {
    label: "Hong Kong Identity Card",
    labelZh: "香港身份證",
    value: "Hong kong Id Card",
  },
  {
    label: "Passport",
    labelZh: "護照",
    value: "Passport",
  },
];

const RegisterStep4 = ({ onBack }: any) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [selectedIdentityType, setSelectedIdentityType] = useState([]);

  const local = useSelector((state: Store) => state.userDataReducer.local);

  const dispatch = useDispatch();
  const users = useSelector((state: Store) => state.userDataReducer.userObject);

  const intl = useIntl();

  useEffect(() => {
    indentityProof.map((i: any) => {
      if (i.value === users.identityType) {
        setSelectedIdentityType(i);
      }
    });
  }, [users]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      identityType: users.identityType || "",
      identityId: users.identityId || "",
      idImage: users.idImage || null,
    },
    validationSchema: RegisterStep4Schema,
    onSubmit: async (values) => {
      dispatch(
        setRegisterSteps(3, {
          ...values,
          identityId: values.identityId.toString(),
        }),
      );
      localStorage.setItem("reg", JSON.stringify(values));
    },
  });

  const { touched, errors } = formik;

  const handleDrop = async (acceptedFiles: any) => {
    setFileLoading(true);
    if (acceptedFiles?.[0]?.name) {
      const { fileUploadResponse, error }: any = await _RegisterUploadMedia(
        acceptedFiles[0] as File,
      );
      if (!error) {
        formik.setFieldValue("idImage", acceptedFiles[0]);
      }
      setFileLoading(false);
    } else {
      setFileLoading(false);
    }
  };

  const onDeleteImage = async () => {
    formik.setFieldValue("idImage", null);
  };

  return (
    <>
      <div className="mb-4">
        <div className="d-flex flex-wrap justify-content-between border-bottom pb-4 mb-4">
          <h1 className="h5 font-weight-600 m-0">
            {" "}
            <FormattedMessage
              id="register.setp4"
              defaultMessage="Step 4: Upload Authentication Information"
            />
          </h1>
          <button
            type="button"
            onClick={onBack}
            className="btn btn-primary btn btn-primary"
          >
            <BsChevronLeft />{" "}
            <FormattedMessage id="register.step3.Back" defaultMessage="Back" />{" "}
          </button>
        </div>
        {fileLoading && <CenterLoader />}
        <Row className="form-row">
          <Col sm={12}>
            <div className="form-group">
              <label>
                {" "}
                <FormattedMessage
                  id="register.step4.Identity"
                  defaultMessage="Identity Type *"
                />{" "}
              </label>
              <Select
                classNamePrefix="react-select-dropdown"
                name="identityType"
                onChange={(option: any) => {
                  formik.setFieldValue("identityType", option.value);
                  setSelectedIdentityType(option);
                  formik.setFieldValue("identityId", "");
                }}
                onBlur={() => formik.setFieldTouched("classCategory", true)}
                options={indentityProof}
                value={selectedIdentityType}
                getOptionLabel={(option: any) =>
                  local === "en" ? option.label : option.labelZh
                }
                getOptionValue={(option: any) => option.value}
                placeholder={intl.formatMessage({
                  id: "register.step4.SelectIdentity",
                  defaultMessage: "Select Identity Type",
                })}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.identityType && touched.identityType && (
                <div className="error">
                  {" "}
                  <FormattedMessage id={errors.identityType.toString()} />{" "}
                </div>
              )}
            </div>
          </Col>
          <Col sm={12}>
            <div className="form-group mb-2">
              <label>
                <FormattedMessage
                  id="register.step4.EnterIdCard"
                  defaultMessage="Identity Number *"
                />{" "}
              </label>
              {formik.values.identityType === "Hong kong Id Card" ? (
                <input
                  type="text"
                  className="form-control"
                  name="identityId"
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  onChange={(e: any) => {
                    if (e.target.value.length <= 4 || e.target.value == "") {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.identityId}
                  placeholder={intl.formatMessage({
                    id: "register.step4.EnterIdCard",
                    defaultMessage: "Identity Number",
                  })}
                />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  name="identityId"
                  onBlur={formik.handleBlur}
                  onChange={(e: any) => {
                    if (e.target.value.trim().length <= 6) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.identityId}
                />
              )}
            </div>
            {errors.identityId && touched.identityId && (
              <div className="text-error">
                <FormattedMessage id={errors.identityId.toString()} />
              </div>
            )}
          </Col>
        </Row>
        <small>
          <FormattedMessage
            id="register.step4.EnterDigitId"
            defaultMessage="  * Please enter the first four digits of your Hong Kong identity card number or the first six digits of your passport number."
          />
        </small>
        <Row sm={1} md={1} lg={1} className="form-row mt-3">
          <Col sm={12}>
            <div className="form-group">
              {formik.values.idImage == null && (
                <>
                  <label>
                    <FormattedMessage
                      id="register.step4.UploadIDProof"
                      defaultMessage="Upload ID Proof"
                    />{" "}
                    *
                  </label>
                  <FileUploadImage handleDrop={handleDrop} />
                </>
              )}

              {formik.values.idImage !== null && (
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <img
                    style={{
                      textAlign: "center",
                      border: "2px lightgrey solid",
                      width: "250px",
                      borderRadius: "1%",
                    }}
                    src={URL.createObjectURL(formik.values.idImage)}
                    width={200}
                    alt="Id Image"
                  />
                  <button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => onDeleteImage()}
                  >
                    <i className="text-danger ">
                      <FaTrash />{" "}
                    </i>
                  </button>
                </div>
              )}

              {errors.idImage && touched.idImage && (
                <div className="error">
                  <FormattedMessage id={errors.idImage.toString()} />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="form-row">
          <Col sm={12}>
            <p className="mb-2">
              <b>
                {" "}
                <FormattedMessage
                  id="register.step4.Notes"
                  defaultMessage="Notes:"
                />{" "}
              </b>
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step4.Notes1"
                defaultMessage="1. We only need the first four digits of the Hong Kong Identity Card number or the first six digits of the passport document number."
              />
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step4.Notes2"
                defaultMessage="2. Before uploading your ID photo, make sure you have covered the rest of your ID number."
              />
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step4.Notes3"
                defaultMessage="3. The identity document will only be used for identity verification purposes and will not be used for other purposes.."
              />
            </p>
          </Col>
        </Row>
      </div>
      <div className="text-right mt-auto">
        <Button
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          <FormattedMessage id="register.step4.Next" defaultMessage="Next" />
          <BsChevronRight />{" "}
        </Button>
      </div>
    </>
  );
};

export default RegisterStep4;
