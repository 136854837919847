import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncements } from "../../redux/action/announcementsActions";
import { Store } from "../../redux/Actions";
import { formatDateMM } from "../../util/utils";
import { Link, useNavigate } from "react-router-dom";
import { FormattedDate, FormattedMessage } from "react-intl";
import Pagination from "../../Components/common/Paginate/Pagination";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import TicketModal from "../../Components/Ticket/TicketModal";
import { BsChevronLeft } from "react-icons/bs";

export const AddTicket = () => {
  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                {/* <h4 className="modal-title"> */}{" "}
                <FormattedMessage
                  id="ticketmodal.Ticket"
                  defaultMessage="Add Ticket"
                />{" "}
                {/* </h4> */}
              </h1>
            </div>
            <Link to="/ticket">
              {" "}
              <span style={{ verticalAlign: "middle" }}>
                {" "}
                <BsChevronLeft />
                <FormattedMessage
                  id="head.Ticket"
                  defaultMessage="Tickets"
                />{" "}
              </span>
            </Link>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <TicketModal />
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};
