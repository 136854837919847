/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_LIBRARY_LIST,
  GET_LIBRARY_VERSION_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_LIBRARY_LIST_URL,
  GET_LIBRARY_VERSION_LIST_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";

//Get Admin Roles List
export const getLibraryList =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getLibraryListInit(showToast, sort));
  };

export const getLibraryListInit =
  (showToast = true, sort): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIBRARY_LIST.GET_LIBRARY_LIST_INITIALIZATION,
    });
    apiCall(
      GET_LIBRARY_LIST_URL + `?sort=${sort.order}&sortBy=${sort.by}`,
      (res: Object) => dispatch(getLibraryListSuccess(res)),
      (err: any) => dispatch(getLibraryListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getLibraryListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIBRARY_LIST.GET_LIBRARY_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getLibraryListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_LIBRARY_LIST.GET_LIBRARY_LIST_ERROR,
    });
  };

export const getLibraryVersionList =
  (
    showToast = true,
    eaId: string,
    isGranted: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getLibraryVersionListInit(showToast, eaId, isGranted));
  };

export const getLibraryVersionListInit =
  (
    showToast = true,
    eaId: string,
    isGranted: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      GET_LIBRARY_VERSION_LIST_URL + eaId, //+ `?isGranted=${isGranted}`,
      (res: Object) => dispatch(getLibraryVersionListSuccess(res)),
      (err: any) => dispatch(getLibraryVersionListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getLibraryVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_SUCCESS,
      payload: res,
    });
  };

export const getLibraryVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_ERROR,
    });
  };
