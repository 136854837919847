import { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaTimesCircle } from "react-icons/fa";
import { MdMoreTime } from "react-icons/md";
import { FormattedMessage } from "react-intl";

interface ManagementRowSubscriptionButtonProps {
  email: string;
  englishFullName: string;
  vpsId: number;
  vpsType: {
    id: number;
    typeName: string;
  };
  firstClassName: string;
}

const urls = [
  "https://forexforest.com/hk/enroll/gems-panel-with-type-a-vps-package-renewal/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-b-vps-package-renewal/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-c-vps-package-renewal/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-d-vps-package-renewal/",
  "https://forexforest.com/hk/enroll/gems-panel-with-type-e-vps-package-renewal/",
];

const ManagementRowSubscriptionButton = (
  props: ManagementRowSubscriptionButtonProps,
) => {
  const [show, setShow] = useState(false);
  const { email, englishFullName, vpsId, vpsType, firstClassName } = props;
  const vpsTypeId = vpsType?.id || 5;
  const handleClose = () => setShow(false);

  let renew_url = `${urls[vpsTypeId - 1] || urls[4]}?`;
  renew_url += `billing_first_name=${englishFullName}`;
  renew_url += `&billing_email=${email}`;
  renew_url += `&billing_vps_id=${vpsId}`;
  renew_url += `&your_class=${firstClassName}`;

  return vpsTypeId === 7 ? (
    <>
      <td>
        <i onClick={() => setShow(true)} style={{ cursor: "pointer" }}>
          <MdMoreTime size={20} />
        </i>
      </td>
      <Modal
        dialogClassName="modal-dialog-notification"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <h4 className="modal-title">
            <FormattedMessage id="vps.subscribe.not.allowed" />
          </h4>
          <button className="close" onClick={handleClose}>
            <FaTimesCircle />
          </button>
        </Modal.Header>
      </Modal>
    </>
  ) : (
    <td>
      <a href={renew_url} target="_blank" rel="noopener noreferrer">
        <i>
          <MdMoreTime size={20} />
        </i>
      </a>
    </td>
  );
};

export default ManagementRowSubscriptionButton;
