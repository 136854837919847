import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getFileDetail, getToken } from "../../util/utils";
import EditorContainer from "../common/RichTextEditor/Editor";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { FaTimesCircle } from "react-icons/fa";
import { dispatch } from "../../redux";
import { readAnnouncement } from "../../redux/action";
import htmlToDraft from "html-to-draftjs";

export const TICKET_ATTACHMENT = "ticketAttachment";
const IMAGE_TYPES = ["png", "jpeg", "jpg"];
const VIDEO_TYPES = ["mp4", "mov", "wmv", "avi", "mkv", "webm"];
const NotificationAlert = ({ setOpenNotification }: any) => {
  const isLoading: any = useSelector(
    (state: Store) => state.announcementsReducer,
  );

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  const [unreadAnnouncementsList, setUnreadAnnouncementsList] = useState<any>(
    [],
  );
  const [show, setShow] = useState(true);
  const blocksFromHTML = convertFromHTML("Test");
  const [currentAnnoucement, setCurrent] = useState<any>();
  const [isCurrentAnnoucementRead, setIsCurrentAnnoucementRead] =
    useState(false);
  const [currentIndex, setIndex] = useState(0);
  const [announcementAttachment, setAnnouncemenAttachment] = useState<any>([]);

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      ),
    ),
  );
  const editorConfig = {
    toolbar: false,
    editable: false,
    height: "200px",
    overflowY: "unset",
    border: "1px solid transparent",
  };
  useEffect(() => {
    const blocksFromHTML = htmlToDraft(currentAnnoucement?.content || "Test");
    const estate = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      ),
    );
    setEditorState(estate);
  }, [currentAnnoucement]);

  useEffect(() => {
    const an = getToken("unreadAnnouncement");
    const localStorageAnnounce = localStorage.getItem("announcement");
    if (localStorageAnnounce && localStorageAnnounce == "1") {
      if (an !== null && an !== undefined && an.length > 0) {
        setUnreadAnnouncementsList(JSON.parse(an) || []);
      }
    } else if (an !== null && an !== undefined && an.length > 0) {
      if (!localStorageAnnounce) {
        setUnreadAnnouncementsList(JSON.parse(an) || []);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (
      unreadAnnouncementsList &&
      currentIndex < unreadAnnouncementsList.length
    ) {
      setCurrent(unreadAnnouncementsList[currentIndex]);
      if (unreadAnnouncementsList[currentIndex].attechment) {
        const files = getFileDetail(
          unreadAnnouncementsList[currentIndex]?.attechment.map(
            (i: any) => i.attachmentPath,
          ),
        );
        const types = [...IMAGE_TYPES, ...VIDEO_TYPES];
        setAnnouncemenAttachment(
          files.find((vendor) => types.includes(vendor["extention"])),
        );
      }
      setShow(true);
    } else {
      if (unreadAnnouncementsList.length > 0 && !isLoading?.isLoading) {
        setOpenNotification(false);
        localStorage.setItem("announcement", "0");
      }
      setShow(false);
    }
  }, [unreadAnnouncementsList, currentIndex]);

  const handleClose = () => {
    if (currentIndex < unreadAnnouncementsList.length) {
      if (isCurrentAnnoucementRead) {
        dispatch(
          readAnnouncement(false, unreadAnnouncementsList[currentIndex].id),
        );
      }
      setIndex((oldCurrentIndex) => oldCurrentIndex + 1);
    }

    setShow(false);
  };

  const handleChangeCheckBox = (event) => {
    setIsCurrentAnnoucementRead(event.target.checked);
  };

  return show ? (
    <Modal
      dialogClassName="modal-dialog-notification"
      centered
      show={show}
      scrollable
      onHide={handleClose}
    >
      <Modal.Header>
        <h4 className="modal-title">{currentAnnoucement?.title}</h4>
        <button className="close" onClick={handleClose}>
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body bsPrefix="modal-body-notification">
        {
          <div className="notification-body">
            {
              announcementAttachment ? (
                VIDEO_TYPES.includes(announcementAttachment?.extention) ? (
                  <div className="embed-responsive embed-responsive-16by9 mb-3">
                    <video
                      className="notification-video embed-responsive-item"
                      src={announcementAttachment.path}
                      controls
                      loop
                      muted
                      autoPlay
                      playsInline
                    />
                  </div>
                ) : (
                  <div className="mb-3">
                    <img
                      style={{ cursor: "pointer" }}
                      className="rounded img-thumbnail"
                      src={announcementAttachment.path}
                    />
                  </div>
                )
              ) : null
              // <img
              //   style={{ cursor: "pointer" }}
              //   className="rounded img-thumbnail mb-3"
              //   src={require('../../assets/img/noImage.jpeg')}
              // />
            }

            <div className="notification-alert-editor">
              <EditorContainer
                onEditorStateChange={() => {
                  /** dsjhdsj */
                }}
                editorState={editorState}
                toolbar={editorConfig.toolbar}
                editable={false}
                height={editorConfig.height}
                overflowY={editorConfig.overflowY}
                border={editorConfig.border}
              />
            </div>
          </div>
        }
      </Modal.Body>
      <Modal.Footer style={{ position: "relative" }}>
        <label style={{ position: "absolute", left: 30 }}>
          <input
            type="checkbox"
            defaultChecked={isCurrentAnnoucementRead}
            onChange={handleChangeCheckBox}
            style={{ position: "absolute", left: -20, top: 4 }}
          />
          {locale !== "zh"
            ? "Don't show this announcement again"
            : "不再顯示此公告"}
        </label>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default NotificationAlert;
