import { FaAngleDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { translate } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { TranslationIcon } from "../../util/iconStyle";

export const Translation = () => {
  const local = useSelector((state: Store) => state.userDataReducer.local);

  const translateLocal = (language: string) => {
    dispatch(translate(language));
  };

  return (
    <div className="nav-wrapper">
      <div className="sl-nav">
        <ul>
          <li>
            <span className="text-white font-size-16">
              <i className="MuiListItemIcon-root" style={{ color: "white" }}>
                {" "}
                <TranslationIcon />
              </i>{" "}
              <i className="text-white" aria-hidden="true">
                <FaAngleDown />
              </i>{" "}
            </span>
            <div className="triangle"></div>
            <ul style={{ width: "auto" }}>
              <li
                onClick={() => translateLocal("zh")}
                style={{
                  display: "grid",
                  gridTemplateColumns: "16px auto",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <i className="sl-flag flag-de">
                  <div id="germany"></div>
                </i>{" "}
                <span
                  className={local === "zh" ? "active" : ""}
                  style={{ display: "inline-block", width: "max-content" }}
                >
                  中文（繁體）
                </span>
              </li>
              <li
                onClick={() => translateLocal("cn")}
                style={{
                  display: "grid",
                  gridTemplateColumns: "16px auto",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <i className="sl-flag flag-de">
                  <div id="germany"></div>
                </i>{" "}
                <span
                  className={local === "cn" ? "active" : ""}
                  style={{ display: "inline-block", width: "max-content" }}
                >
                  中文（简体）
                </span>
              </li>
              <li
                onClick={() => translateLocal("en")}
                style={{
                  display: "grid",
                  gridTemplateColumns: "16px auto",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                <i className="sl-flag flag-usa">
                  <div id="germany"></div>
                </i>{" "}
                <span
                  className={local === "en" ? "active" : ""}
                  style={{ display: "inline-block", width: "max-content" }}
                >
                  English
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};
