import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllClasses, registerStudentInClass } from "../../redux/action";
import { Store } from "../../redux/Actions";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import noImage from "../../assets/img/noImage.jpeg";
import RegisterClass from "../../Components/Class/RegisterClass";
import { FormattedMessage } from "react-intl";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import parse, { HTMLReactParserOptions } from "html-react-parser";

export const AllClass = () => {
  const dispatch = useDispatch();

  const userExpiryDate = useSelector(
    (state: Store) => state.userDataReducer.myInfo.expiryDate,
  );

  const isUserExpired = userExpiryDate < new Date().toISOString();

  const allClass = useSelector(
    (state: Store) => state.classReducer.allClass as Array<Object>,
  );
  const lstClasses = allClass || [];
  const isLoading = useSelector((state: Store) => state.classReducer.isLoading);

  const [modal, setModal] = useState(false);
  const [classId, setClassId] = useState(false);

  useEffect(() => {
    dispatch(getAllClasses());
  }, []);

  const registerStudent = (classVal: string) => {
    dispatch(registerStudentInClass(classVal, true));
  };

  const getHtml = (info: string) => {
    const div = info
      .split("\n")
      .filter((text) => text !== "")
      .map((htmlElement, i) => {
        if (htmlElement === "") htmlElement = "nbsp";
        return <div key={i}>{parse(htmlElement, customParserOption)}</div>;
      });
    return div;
  };

  const customParserOption: HTMLReactParserOptions = {
    transform: (reactNode, domNode) => {
      if (domNode.type === "tag" && domNode.children.length === 0) {
        return <p style={{ lineHeight: "1" }}>{"\u00A0"}</p>;
      }

      if (domNode.type === "text") {
        return <p style={{ lineHeight: "1" }}>{reactNode}</p>;
      }

      return <>{reactNode}</>;
    },
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage id="classes.All" defaultMessage="All Class" />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          {lstClasses.length <= 0 && isLoading && <CenterLoader />}
          {lstClasses.length ? (
            <Row>
              {lstClasses?.length &&
                lstClasses?.map((i: any) => (
                  <Col sm={12} md={6} lg={3} className="mb-3">
                    <Box className="equal-height">
                      <Card
                        variant="outlined"
                        className="equal-height d-flex flex-column"
                      >
                        <CardHeader
                          title={<h6 className="text-dark m-0">{i?.name}</h6>}
                          className="border-bottom p-t-10 p-b-10"
                        />
                        <CardContent style={{ minHeight: "252px" }}>
                          <div style={{ textAlign: "center" }}>
                            {i?.image_path ? (
                              <img
                                src={i?.image_path}
                                style={{ maxHeight: "225px" }}
                              />
                            ) : (
                              <i>
                                {" "}
                                <img
                                  src={noImage}
                                  style={{ opacity: ".3", maxHeight: "225px" }}
                                />{" "}
                              </i>
                            )}
                          </div>
                          <Typography component="div">
                            {i.info && getHtml(i.info)}
                          </Typography>
                        </CardContent>
                        <CardActions className="border-top py-2 mt-auto">
                          <Button
                            size="sm"
                            className="d-block w-100p mx-auto"
                            disabled={isUserExpired}
                            onClick={() => {
                              setModal(true);
                              setClassId(i);
                            }}
                          >
                            <FormattedMessage
                              id="classes.All.Register"
                              defaultMessage="Register"
                            />{" "}
                          </Button>
                        </CardActions>
                      </Card>
                    </Box>
                  </Col>
                ))}
            </Row>
          ) : (
            <p>{!isLoading && <NoDatFound />}</p>
          )}

          {modal && (
            <RegisterClass
              classId={classId}
              onClassRegister={(values: any) => registerStudent(values)}
              onCancle={() => setModal(false)}
              showModal={modal}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default AllClass;
