import axios from "axios";
// import moment from "moment";
import moment from "moment-timezone";
import { saveAs } from "file-saver";
import * as _ from "lodash";
import secureLocalStorage from "react-secure-storage";

interface DataObject {
  name: string;
}

export const getToken = (key: string) => {
  let v: any = "";
  v = secureLocalStorage.getItem(key);
  if (v !== null) {
    return v.toString();
  }
  return v;
};

export const valiadteToken = () => {
  const token = getToken("login-auth-token");
  const isLoggedIn = getToken("isLoggedIn");
  const time = parseInt(getToken("exp") || "0");
  const ctime = new Date().getTime();

  if (time > ctime) {
    if (token && isLoggedIn) {
      return true;
    }
  }
  return false;
};

export const setToken = (key: string, token: string) =>
  Promise.resolve().then(() => {
    secureLocalStorage.setItem(key, token);
  });

export const setSort = (sortOrder: string, sortBy: string) => {
  sessionStorage.setItem("sortOrder", sortOrder);
  sessionStorage.setItem("sortBy", sortBy);
};

export const getSort = () => {
  const sortOrder = sessionStorage.getItem("sortOrder");
  const sortBy = sessionStorage.getItem("sortBy");
  return { by: sortBy, order: sortOrder };
};

export const getPostData = async () => {
  const response = await axios.get(
    "https://jsonplaceholder.typicode.com/posts",
  );
  const { data } = response;
  return data;
};

export const customFormatDatetimeWithLocale = (
  datetimeString: string,
  locale: string,
  needTime = false,
): string => {
  if (datetimeString === null) {
    return "unknown";
  }

  const datetime = new Date(datetimeString);

  const paddedYear = moment.tz(datetime, "Asia/Hong_Kong").format("YYYY");
  const paddedMonth = moment
    .tz(datetime, "Asia/Hong_Kong")

    .format("MM");
  const paddedDay = moment
    .tz(datetime, "Asia/Hong_Kong")

    .format("DD");

  let s: string;
  s = `${paddedDay}`;
  if (locale === "en") {
    s = `${paddedYear}-${paddedMonth}-${paddedDay}`;
  } else {
    s = `${paddedYear}年${paddedMonth}月${paddedDay}日`;
  }

  if (needTime) {
    const hourAndMinute = moment.tz(datetime, "Asia/Hong_Kong").format("HH:mm");

    const hour = moment(datetime).format("HH:mm");
    //const hour = datetime.getHours();
    const minute = datetime.getMinutes();
    const paddedHour: string = String(hour).padStart(2, "0");
    const paddedMinute: string = String(minute).padStart(2, "0");

    s += ` ${paddedHour}`;
  }
  return s;
};

export const formatDate = (dateJson: number, format = "YYYY-MM-DD") => {
  const date = new Date(dateJson);

  return moment(date).subtract(2.5, "h").format(format);
};

export const formatDateTime = (dateJson: number) => {
  const date = new Date(dateJson);
  const format1 = "YYYY-MM-DD hh:mm:s";

  return moment(date).subtract(2.5, "h").format(format1);
};

export const isEmptyObject = (obj: Object = {}) => {
  return Object.keys(obj).length === 0;
};

export const formatDateMM = (dateJson: number, format = "MM-DD-YYYY") => {
  const date = new Date(dateJson);
  return moment(date).subtract(2.5, "h").format(format);
};

export const dateCompare = (firstDate: any, secondDate: any) => {
  const date1 = moment(formatDate(firstDate)).toJSON();
  const date2 = moment(formatDate(secondDate)).toJSON();

  return moment(date1).isSameOrAfter(date2, "second");
};

export const saveFile = (url: any) => {
  const fileName = getFileName(url);
  // const str = decodeURIComponent(fileName)
  // const str = fileName.substr(21, fileName.length);
  saveAs(url, fileName);
};

export function getFileName(fileURL: any) {
  try {
    if (fileURL && fileURL.length > 0) {
      let filename = fileURL.substring(
        fileURL.lastIndexOf("/") + 1,
        fileURL.length,
      );
      let splitFileName = filename.split("-");

      if (splitFileName.length > 1) {
        const hexRegex = /^[0-9A-Fa-f]{20}$/g;
        const hex = splitFileName[0].match(hexRegex);
        if (hex) {
          splitFileName = splitFileName.slice(1, splitFileName.length);
        }
      }

      filename = splitFileName.join("-");

      filename = filename.replace(/\+/g, "%20");
      filename = filename.replace(/ /g, "%20");
      filename = decodeURIComponent(filename);
      return filename;
    }
  } catch (error) {
    if (error instanceof URIError) {
      console.error("Invalid URL: " + fileURL);
    } else if (error instanceof Error) {
      console.error("Error: " + error.message);
    }
    return "";
  }
}

export const sort = (lst: any, by: string, order = "asc") => {
  const sign = 2 * Number(order === "asc") - 1;
  if (by === "name" || by === "info") {
    const customStringCollator = new Intl.Collator("en", {});

    return lst.slice().sort((a, b) => {
      const aName = a[by].trimStart(); // Remove leading spaces
      const bName = b[by].trimStart(); // Remove leading spaces
      return sign * customStringCollator.compare(aName, bName);
    });
  }

  if (by === "updatedAt") {
    return lst.slice().sort((a, b) => {
      const dateA = new Date(new Date(a[by]).toDateString()).getTime();
      const dateB = new Date(new Date(b[by]).toDateString()).getTime();
      return sign * (dateA - dateB);
    });
  }

  if (order === "asc") {
    return _.orderBy(lst, [by], ["asc"]);
  }
  if (order === "desc") {
    return _.orderBy(lst, [by], ["desc"]);
  }
};

export function get_url_extension(fileName: string) {
  const extension = fileName.split(".").pop();
  return extension;
}

export function get_url_extensions(url: any) {
  if (url != undefined && url != null && url != "") {
    const fileName: string = getFileName(url);
    const extension = fileName.split(".").pop();
    return extension;
  }
  return "";
}

export function getFileDetail(arrURL: any) {
  const tmp: any = [];
  arrURL.map((i: any) => {
    tmp.push({
      path: i,
      name: getFileName(i),
      extention: get_url_extension(getFileName(i)),
    });
  });
  return tmp;
}

export const formatDateDiff = (startDate: any, endDate: any) => {
  return moment(startDate).diff(moment(endDate), "days");
};

export const getTextFromHtmlRowText = (eState: any) => {
  let text = eState.toString().replaceAll("&nbsp;", "");
  text = text.replaceAll("<p>", "");
  text = text.replaceAll("</p>", "");
  text = text.replaceAll("<br>", "");
  text = text.replaceAll("\n", "");

  text = text.trim();
  return text;
};
export function modifyMessage(log: any): string {
  const matches1 = log.remarks.match(
    /GRANTS:Point Added:MEMBER\(\w+\):FROM:\((\d+)\):TO:\((\d+)\):TOTALLY_ADDED\((\d+)\): Remarks\((.+)\)/,
  );
  const matches2 = log.remarks.match(
    /^GRANTS:POINTS_DROPED:MEMBER\((\d+)\):FROM:\((-?\d+)\):TO:\((-?\d+)\):TOTALLY_DROPED\((\d+)\):REMARKS\((.+)\)$/,
  );
  const matches3 = log.remarks.match(
    /^GRANTS:POINTS_ADDED:MEMBER\((\d+)\):FROM:\((\d+)\):TO:\((\d+)\):TOTALLY_ADDED\((\d+)\):REMARKS\(([\w\s#-]+)\s#ROLLBACK\)$/,
  );

  const matches4 = log.remarks.match(
    /GRANTS:Point Added:MEMBER\((\d+)\):FROM:\((\d+)\):TO:\((\d+)\):TOTALLY_ADDED\((\d+)\):RemarksS\((.+)\)/,
  );

  if (log.message) {
    // Return the Gem 4.0 correct format
    return log.message;
  } else if (matches1) {
    // Change the Gem 3.0 message to the correct format
    const [_, previousBalance, newBalance, pointGained, adminRemark] = matches1;

    const modifiedString = `Point Added: ${pointGained} Remarks: ${adminRemark}`;

    return modifiedString;
  } else if (matches2) {
    const [_, previousBalance, newBalance, droppedPoint, adminRemark] =
      matches2;
    const modifiedString = `Point Dropped: ${droppedPoint} Remarks: ${adminRemark}`;
    return modifiedString;
  } else if (matches3) {
    const [_, memberId, previousPoint, newBalance, pointGained, activityName] =
      matches3;
    const modifiedString = `Point Added: ${pointGained} Remarks: ${activityName} (Roll Call)`;
    return modifiedString;
  } else if (matches4) {
    const [, , , , pointGained, activityName] = matches4;
    const modifiedString = `Point Added: ${pointGained} Remarks: ${activityName}`;
    return modifiedString;
  } else if (log.remarks.includes("REMARK")) {
    // Change the Gem 3.0 message to the correct format
    let modifiedMessage = log.remarks.replace("REMARK", "Remarks");
    modifiedMessage = modifiedMessage.replace("POINTS_ADDED", "Point Added");
    modifiedMessage = modifiedMessage.replace(
      "POINTS_DROPPED",
      "Point Dropped",
    );
    modifiedMessage = modifiedMessage.replace(
      "POINTS_REDEEMED",
      "Point Redeemed",
    );
    modifiedMessage = modifiedMessage.replace("ROLLBACK", "RollCall");
    return modifiedMessage;
  }
  return log.remarks;
}

export function findLatestVersionDownloadPath(CurrentData, isTool: boolean) {
  for (let i = 0; i < CurrentData.length; i++) {
    if (isTool ? CurrentData[i].download_path : CurrentData[i].manual_path) {
      if (CurrentData[i].isDeleted == false) return i;
    }
  }
  return 0;
}

const unsecuredCopyToClipboard = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus({ preventScroll: true });
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }
  document.body.removeChild(textArea);
};

export const copyToClipboard = (content: string) => {
  if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard.writeText(content);
  } else {
    unsecuredCopyToClipboard(content);
  }
};

export const thumbImageURL = (url: string) => {
  if (!url) {
    return null;
  }
  const fileExtension = [".jpg", ".jpeg", ".png", ".bmp"];
  if (fileExtension.includes(url.substring(url.lastIndexOf(".")))) {
    return url.replace(/^([^.]+)$|(\.[^.]+)$/i, "$1" + "-thumb" + "$2");
  }
  return url;
};
