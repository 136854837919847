import Dashboard from "../Pages/Dashboard";
import LandingPage from "../Pages/LandingPage";
import Login from "../Pages/Login";
import Register from "../Pages/Register";
import Tickets from "../Pages/Tickets";
import TicketDetail from "../Pages/Tickets/TicketDetail";
import VpsCreation from "../Pages/VPSMachine/VpsCreation";
import VpsManagement from "../Pages/VPSMachine/VpsManagement";
import Gifts from "../Pages/Gifts";
import Faqs from "../Pages/Faqs";
import Announcements from "../Pages/Annoucements";
import { AllClass } from "../Pages/Classes/AllClass";
import Activities from "../Pages/Activities";
import Attendence from "../Pages/Attendence";
import Indicators from "../Pages/Indicators";
import Scripts from "../Pages/Scripts";
import Utilities from "../Pages/Utilities";
import { LiveAccount } from "../Pages/Accounts/LiveAccount";
import { MySingal } from "../Pages/Signals/MySingal";
import ExpertAdvisors from "../Pages/ExpertAvisors";
import Libraries from "../Pages/Libraries";
import AnnouncementDetail from "../Pages/Annoucements/AnnouncementDetail";
import MyClass from "../Pages/Classes/MyClass";
import GainedPoints from "../Pages/Points/GainedPoints";
import EAVersionDetail from "../Pages/ExpertAvisors/EAVersionDetail";
import LibraryVersionDetail from "../Pages/Libraries/LibraryVersionDetail";
import IndicatorVersionDetail from "../Pages/Indicators/IndicatorVersionDetail";
import ScriptVersionDetail from "../Pages/Scripts/ScriptVersionDetail";
import UtilityVersionDetail from "../Pages/Utilities/UtilityVersionDetail";
import Account from "../Pages/Accounts";
import TempararyAccount from "../Pages/Accounts/TempararyAccount";
import ClassSingal from "../Pages/Signals/ClassSingal";
import Point from "../Pages/Points";
import ForgotPassword from "../Pages/ForgotPassword";
import SetPasswod from "../Pages/SetPasswod";
import EmailVerification from "../Pages/EmailVerification";
import ResetPassword from "../Pages/ResetPassword";
import Profile from "../Pages/Profile";
import ChangePassword from "../Pages/ChangePassword";
import PageNotFound from "../Components/common/PageNotFound";
import DirectEmailVerification from "../Pages/DirectEmailVerification";
import { AddTicket } from "../Pages/Tickets/AddTicket";
import ProtectedToolsRoute from "../Pages/ProtectedToolsRoute";

interface CustomRoute {
  path: string;
  protection?: React.FC<{ children?: React.ReactNode }>;
  element: React.FC;
  sideBar: boolean;
  navBar: boolean;
}

export const publicRoutes: CustomRoute[] = [
  {
    path: "/login",
    element: Login,
    sideBar: false,
    navBar: false,
  },
  {
    path: "/forgot-password",
    element: ForgotPassword,
    sideBar: false,
    navBar: false,
  },
  {
    path: "/email-verification",
    element: EmailVerification,
    sideBar: false,
    navBar: false,
  },
  {
    path: "/direct-email-verification",
    element: DirectEmailVerification,
    sideBar: false,
    navBar: false,
  },
  {
    path: "/set-password",
    element: SetPasswod,
    sideBar: false,
    navBar: false,
  },

  {
    path: "/register",
    element: Register,
    sideBar: false,
    navBar: false,
  },
  {
    path: "/resetPassword",
    element: ResetPassword,
    sideBar: true,
    navBar: false,
  },
  {
    path: "/",
    element: LandingPage,
    sideBar: false,
    navBar: false,
  },
  {
    path: "/pageNotFound",
    element: PageNotFound,
    sideBar: false,
    navBar: false,
  },
];

export const privateRoutes: CustomRoute[] = [
  {
    path: "/home",
    element: Dashboard,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/points",
    element: Point,
    sideBar: true,
    navBar: true,
  },

  {
    path: "/gainedpoints",
    element: GainedPoints,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/gifts",
    element: Gifts,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/faqs",
    element: Faqs,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/announcement/:id",
    element: AnnouncementDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/announcement",
    element: Announcements,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/vps-creation",
    element: VpsCreation,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/vps-management",
    element: VpsManagement,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/ticket",
    element: Tickets,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/ticket/:id",
    element: TicketDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/myClass",
    element: MyClass,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/allClass",
    element: AllClass,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/activities",
    element: Activities,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/attendance",
    element: Attendence,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/expert-advisors",
    element: ExpertAdvisors,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/libraries",
    element: Libraries,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/indicators",
    element: Indicators,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/scripts",
    element: Scripts,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/utilities",
    element: Utilities,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/account/temparary",
    element: TempararyAccount,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/account",
    element: Account,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/account/live",
    element: LiveAccount,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/mySignal",
    element: MySingal,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/classSignal",
    element: ClassSingal,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/expert-advisors/:id",
    protection: ProtectedToolsRoute,
    element: EAVersionDetail,
    sideBar: true,
    navBar: true,
  },

  {
    path: "/libraries/:id",
    protection: ProtectedToolsRoute,
    element: LibraryVersionDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/indicators/:id",
    protection: ProtectedToolsRoute,
    element: IndicatorVersionDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/scripts/:id",
    protection: ProtectedToolsRoute,
    element: ScriptVersionDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/utilities/:id",
    protection: ProtectedToolsRoute,
    element: UtilityVersionDetail,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/profile",
    element: Profile,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/change-password",
    element: ChangePassword,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/tickets/add",
    element: AddTicket,
    sideBar: true,
    navBar: true,
  },
  {
    path: "/*",
    element: PageNotFound,
    sideBar: false,
    navBar: false,
  },
];
