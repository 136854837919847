import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { copyToClipboard } from "../../../util/utils";
import { FormattedMessage } from "react-intl";

interface ManagementRowCopyOnClickProps {
  publicIp: string;
}

const ManagementRowCopyOnClick = (props: ManagementRowCopyOnClickProps) => {
  const { publicIp } = props;
  const [open, setOpen] = useState(false);

  const handleOnClickToCopy = () => {
    copyToClipboard(publicIp);
    setOpen(true);
  };

  return (
    <td style={{ cursor: "pointer" }} onClick={handleOnClickToCopy}>
      <Tooltip
        arrow
        followCursor
        open={open}
        placement="bottom"
        onMouseLeave={() => setOpen(false)}
        title={
          <Typography color="inherit">
            <FormattedMessage
              id="vps.management.publicIp.copied"
              defaultMessage="Copied"
            />
          </Typography>
        }
      >
        <div>{publicIp}</div>
      </Tooltip>
    </td>
  );
};

export default ManagementRowCopyOnClick;
