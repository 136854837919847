import { useGetAllUserGemsAdvertisements } from "../../../features/gemsAdvertisements/api";
import Loader from "../../common/Loader";
import Marquee from "react-fast-marquee";
import GemsAdvertisementsMarqueeSlide from "./GemsAdvertisementsMarqueeSlide";
import { useMemo } from "react";

const GemsAdvertisementsMarquee = () => {
  const currentDatetimeString = useMemo(() => new Date().toISOString(), []);

  const { data, isLoading, isFetching, isError } =
    useGetAllUserGemsAdvertisements({
      pageIndex: 0,
      pageSize: 64,
      columnFilters: [
        { id: "startDate", value: ["", currentDatetimeString] },
        { id: "endDate", value: [currentDatetimeString, ""] },
      ],
    });

  if (isLoading || isFetching) {
    return <Loader />;
  }

  if (isError || !data.gemsAdvertisementsCount) {
    return null;
  }

  const { gemsAdvertisements } = data;

  return (
    <Marquee
      autoFill
      pauseOnHover
      speed={16}
      gradient
      gradientWidth={16}
      style={{ borderRadius: 4 }}
    >
      {gemsAdvertisements.map((gemsAdvertisement) => (
        <GemsAdvertisementsMarqueeSlide
          {...gemsAdvertisement}
          key={gemsAdvertisement.id}
        />
      ))}
    </Marquee>
  );
};

export default GemsAdvertisementsMarquee;
