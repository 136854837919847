/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
import { toast } from "react-toastify";
import { dispatch } from "../redux";
import { logout } from "../redux/action";
import { instance as axios } from "./axiosConfig";
import { appIntl } from "../util/intl";

export const METHOD = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};

export const apiCall = (
  endpoint: string,
  onSuccess: Function,
  onFailure: Function,
  params = {},
  method = METHOD.GET,
  DyanamicConfig: any,
) => {
  const request = (): Promise<any> => {
    switch (method) {
      case METHOD.POST:
        return axios.post(endpoint, params, { ...DyanamicConfig });
      case METHOD.GET:
        return axios.get(endpoint, { ...params });
      case METHOD.DELETE:
        return axios.delete(endpoint, { ...DyanamicConfig });
      case METHOD.PUT:
        return axios.put(endpoint, params, { ...DyanamicConfig });
      case METHOD.PATCH:
        return axios.patch(endpoint, params, { ...DyanamicConfig });
    }
    return new Promise((resolve, reject) => resolve({}));
  };

  const req = request();

  function extractMessage(payload: any): string | undefined {
    if (typeof payload.message === "string") {
      return payload.message;
    } else if (
      typeof payload.message === "object" &&
      typeof payload.message.message === "string"
    ) {
      return payload.message.message;
    } else {
      return undefined;
    }
  }

  req
    .then((response) => {
      if (
        (response.status === 200 ||
          response.status === 201 ||
          response.status === 204 ||
          response.data) &&
        response.data !== ""
      ) {
        if (DyanamicConfig.showToast && response.data.message) {
          let message = extractMessage(response.data);
          message =
            appIntl().formatMessage({ id: message }) || response.data.message;
          toast.success(message);
        }
        onSuccess(response.data);
      } else if (response.status === 200 || response.status === 204) {
        onSuccess("success");
      } else {
        onFailure("Something went wrong");
      }
    })
    .catch((error) => {
      if (error && error.response) {
        if (DyanamicConfig.showToast && error.response.data.message) {
          extractMessage(error.response.data);
          const message =
            appIntl().formatMessage({ id: error.response.data.message }) ||
            error.response.data.message;
          toast.error(message);
        }
        switch (error.response.status) {
          case 401:
            onFailure(error.response);
            dispatch(logout(false));
            break;

          case 404:
            window.location.replace("/pageNotFound");
            break;

          default:
            onFailure(
              error.response.data
                ? error.response.data
                : "Something went wrong",
            );
            break;
        }
      } else {
        toast.error("Something went wrong");
        onFailure && onFailure("Something went wrong");
      }
    });
};
