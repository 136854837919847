/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_MY_SIGNAL_LIST,
  GET_CLASS_SIGNAL_LIST,
  ADD_CLASS_SIGNAL,
  EDIT_CLASS_SIGNAL,
  DELETE_CLASS_SIGNAL,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import {
  GET_MY_SIGNAL_LIST_URL,
  GET_CLASS_SIGNAL_LIST_URL,
  ADD_CLASS_SIGNAL_URL,
  EDIT_CLASS_SIGNAL_URL,
  DELETE_CLASS_SIGNAL_URL,
} from "../../service/apiEndPoints";

export const getMySignalList =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter: string,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getMySignalListInit(showToast, pageNumber, sort, filter, pageLimit),
    );
  };

export const getMySignalListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter = "",
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_MY_SIGNAL_LIST.GET_MY_SIGNAL_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_MY_SIGNAL_LIST_URL}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}${filter}`,
      (res: Object) => dispatch(getMySignalListSuccess(res)),
      (err: any) => dispatch(getMySignalListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getMySignalListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_MY_SIGNAL_LIST.GET_MY_SIGNAL_LIST_SUCCESS,
      payload: res,
    });
  };

export const getMySignalListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_MY_SIGNAL_LIST.GET_MY_SIGNAL_LIST_ERROR,
    });
  };

export const getClassSignalList =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getClassSignalListInit(showToast, pageNumber, sort, filter, pageLimit),
    );
  };

export const getClassSignalListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASS_SIGNAL_LIST.GET_CLASS_SIGNAL_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_CLASS_SIGNAL_LIST_URL}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}${filter}`,
      (res: Object) => dispatch(getClassSignalListSuccess(res)),
      (err: any) => dispatch(getClassSignalListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getClassSignalListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CLASS_SIGNAL_LIST.GET_CLASS_SIGNAL_LIST_SUCCESS,
      payload: res,
    });
  };

export const getClassSignalListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_CLASS_SIGNAL_LIST.GET_CLASS_SIGNAL_LIST_ERROR,
    });
  };

export const addClassSignal =
  (
    showToast = true,
    signal: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addClassSignalInit(showToast, signal));
  };

export const addClassSignalInit =
  (
    showToast = true,
    signal: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_CLASS_SIGNAL.ADD_CLASS_SIGNAL_INITIALIZATION,
    });
    apiCall(
      ADD_CLASS_SIGNAL_URL,
      (res: Object) => dispatch(addClassSignalSuccess(res)),
      (err: any) => dispatch(addClassSignalError()),
      signal,
      METHOD.POST,
      { showToast },
    );
  };

export const addClassSignalSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_CLASS_SIGNAL.ADD_CLASS_SIGNAL_SUCCESS,
      payload: res,
    });

    dispatch(getMySignalList(false, 1, { by: "createdAt", order: "DESC" }, ""));
  };

export const addClassSignalError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_CLASS_SIGNAL.ADD_CLASS_SIGNAL_ERROR,
    });
  };

export const editClassSignal =
  (
    showToast = true,
    signal: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editClassSignalInit(showToast, signal, id));
  };

export const editClassSignalInit =
  (
    showToast = true,
    signal: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_CLASS_SIGNAL.EDIT_CLASS_SIGNAL_INITIALIZATION,
    });
    apiCall(
      `${EDIT_CLASS_SIGNAL_URL}${id}`,
      (res: Object) => dispatch(editClassSignalSuccess(res)),
      (err: any) => dispatch(editClassSignalError()),
      signal,
      METHOD.PUT,
      { showToast },
    );
  };

export const editClassSignalSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_CLASS_SIGNAL.EDIT_CLASS_SIGNAL_SUCCESS,
      payload: res.data,
    });
    dispatch(getMySignalList(false, 1, { by: "createdAt", order: "ASC" }, ""));
  };

export const editClassSignalError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_CLASS_SIGNAL.EDIT_CLASS_SIGNAL_ERROR,
    });
  };

export const deleteClassSignal =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteClassSignalInit(showToast, id));
  };

export const deleteClassSignalInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_CLASS_SIGNAL.DELETE_CLASS_SIGNAL_INITIALIZATION,
    });
    apiCall(
      `${DELETE_CLASS_SIGNAL_URL}${id}`,
      (res: Object) => dispatch(deleteClassSignalSuccess(res)),
      (err: any) => dispatch(deleteClassSignalError()),
      {},
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteClassSignalSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_CLASS_SIGNAL.DELETE_CLASS_SIGNAL_SUCCESS,
      payload: res.data,
    });
    dispatch(getMySignalList(false, 1, { by: "createdAt", order: "ASC" }, ""));
  };

export const deleteClassSignalError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_CLASS_SIGNAL.DELETE_CLASS_SIGNAL_ERROR,
    });
  };
