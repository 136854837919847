/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_TICKET_LIST,
  ADD_TICKET_LIST,
  EDIT_TICKET_LIST,
  GET_TICKET_DETAILS,
  GET_TICKET_ISSUE_TYPE_LIST,
  ADD_TICKET_COMMENT_LIST,
  GET_TICKET_COMMENT_LIST,
  GET_TICKET_TOOL_VERSION_LIST,
  GET_TICKET_TOOL_LIST,
  GET_TICKET_ASSIGNEE_LIST,
  UPDATE_TICKET_ASSIGNEE_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_TICKET_LIST_URL,
  ADD_TICKET_LIST_URL,
  EIDT_TICKET_LIST_URL,
  GET_TICKET_ISSUE_TYPE_LIST_URL,
  GET_TICKET_TOOL_LIST_URL,
  GET_TICKET_TOOL_VERSION_LIST_URL,
  ADD_TICKET_COMMENT_LIST_URL,
  GET_TICKET_COMMENT_LIST_URL,
  GET_TICKET_DETAIL_URL,
  UPDATE_TICKET_ASSIGNEE_LIST_URL,
  GET_TICKET_ASSIGNEE_LIST_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";

//Get Admin Roles List
export const getTicketList =
  (
    showToast = false,
    pageNumber: any,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketListInit(showToast, pageNumber, sort, filter, pageLimit));
  };

export const getTicketListInit =
  (
    showToast: boolean,
    pageNumber: any,
    sort: any,
    filter: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_LIST.GET_TICKET_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_TICKET_LIST_URL}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}${filter}`,
      (res: Object) => dispatch(getTicketListSuccess(res)),
      (err: any) => dispatch(getTicketListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_LIST.GET_TICKET_LIST_SUCCESS,
      payload: res,
    });
  };

export const getTicketListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_LIST.GET_TICKET_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addTicketList =
  (
    showToast = true,
    indicatorData: any,
    pageNumber: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addTicketListInit(showToast, indicatorData, pageNumber));
  };

export const addTicketListInit =
  (
    showToast = true,
    indicatorData: any,
    pageNumber: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TICKET_LIST.ADD_TICKET_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_TICKET_LIST_URL,
      (res: Object) => dispatch(addTicketListSuccess(res, pageNumber)),
      (err: any) => dispatch(addTicketListError()),
      indicatorData,
      METHOD.POST,
      { showToast },
    );
  };

export const addTicketListSuccess =
  (
    res: any,
    pageNumber: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TICKET_LIST.ADD_TICKET_LIST_SUCCESS,
    });
    dispatch(
      getTicketList(false, pageNumber, { order: "DESC", by: "createdAt" }, ""),
    );
  };

export const addTicketListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_TICKET_LIST.ADD_TICKET_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const editTicketList =
  (
    showToast = true,
    id: any,
    indicatorData: any,
    pageNumber: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(editTicketListInit(showToast, id, indicatorData, pageNumber));
  };

export const editTicketListInit =
  (
    showToast = true,
    id: any,
    indicatorData: any,
    pageNumber: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_TICKET_LIST.EDIT_TICKET_LIST_INITIALIZATION,
    });
    apiCall(
      EIDT_TICKET_LIST_URL + id,
      (res: Object) => dispatch(editTicketListSuccess(res, id)),
      (err: any) => dispatch(editTicketListError()),
      indicatorData,
      METHOD.PUT,
      { showToast },
    );
  };

export const editTicketListSuccess =
  (res: any, id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EDIT_TICKET_LIST.EDIT_TICKET_LIST_SUCCESS,
      payload: res.data,
    });

    dispatch(getTicketDetail(false, id));
    dispatch(getTicketCommentList(false, id, 1, 10));
  };

export const editTicketListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: EDIT_TICKET_LIST.EDIT_TICKET_LIST_ERROR,
    });
  };
//Get Admin Roles Detail
export const getTicketDetail =
  (
    showToast = false,
    id: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketDetailInit(showToast, id));
  };

export const getTicketDetailInit =
  (
    showToast: boolean,
    id: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_DETAILS.GET_TICKET_DETAILS_INITIALIZATION,
    });
    apiCall(
      GET_TICKET_DETAIL_URL + id,
      (res: Object) => dispatch(getTicketDetailSuccess(res)),
      (err: any) => dispatch(getTicketDetailError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_DETAILS.GET_TICKET_DETAILS_SUCCESS,
      payload: res.data,
    });
  };

export const getTicketDetailError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_DETAILS.GET_TICKET_DETAILS_ERROR,
    });
  };

export const getTicketIssueTypeList =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketIssueTypeListInit(showToast));
  };

export const getTicketIssueTypeListInit =
  (showToast: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_INITIALIZATION,
    });
    apiCall(
      GET_TICKET_ISSUE_TYPE_LIST_URL,
      (res: Object) => dispatch(getTicketIssueTypeListSuccess(res)),
      (err: any) => dispatch(getTicketIssueTypeListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketIssueTypeListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getTicketIssueTypeListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_ISSUE_TYPE_LIST.GET_TICKET_ISSUE_TYPE_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getTicketCommentList =
  (
    showToast = false,
    id: number,
    pageNumber: number,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketCommentListInit(showToast, id, pageNumber, pageLimit));
  };

export const getTicketCommentListInit =
  (
    showToast: boolean,
    id: number,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_TICKET_COMMENT_LIST_URL}${id}?page=${pageNumber}&perPage=${pageLimit}&sortBy=createdAt&sort=DESC`,
      (res: Object) => dispatch(getTicketCommentListSuccess(res, id)),
      (err: any) => dispatch(getTicketCommentListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketCommentListSuccess =
  (res: any, id: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getTicketCommentListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_COMMENT_LIST.GET_TICKET_COMMENT_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const addTicketCommentList =
  (
    showToast = true,
    comment: any,
    id: number,
    pageNumber: number,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      addTicketCommentListInit(showToast, comment, id, pageNumber, pageLimit),
    );
  };

export const addTicketCommentListInit =
  (
    showToast = true,
    comment: any,
    id: number,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_INITIALIZATION,
    });

    apiCall(
      ADD_TICKET_COMMENT_LIST_URL + id,
      (res: Object) =>
        dispatch(addTicketCommentListSuccess(res, id, pageNumber, pageLimit)),
      (err: any) => dispatch(addTicketCommentListError()),
      comment,
      METHOD.POST,
      { showToast },
    );
  };

export const addTicketCommentListSuccess =
  (
    res: any,
    id: number,
    pageNumber: number,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_SUCCESS,
    });
    dispatch(getTicketCommentList(false, id, pageNumber, pageLimit));
    dispatch(getTicketDetail(false, id));
  };

export const addTicketCommentListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_TICKET_COMMENT_LIST.ADD_TICKET_COMMENT_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getTicketToolList =
  (
    showToast = true,
    toolType: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketToolListInit(showToast, toolType));
  };

export const getTicketToolListInit =
  (
    showToast = true,
    toolType: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_TICKET_TOOL_LIST_URL}`,
      (res: Object) => dispatch(getTicketToolListSuccess(res)),
      (err: any) => dispatch(getTicketToolListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketToolListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getTicketToolListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_LIST.GET_TICKET_TOOL_LIST_ERROR,
    });
  };

//Get Admin Roles List
export const getTicketToolVersionList =
  (
    showToast = true,
    toolId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketToolVersionListInit(showToast, toolId, tool));
  };

export const getTicketToolVersionListInit =
  (
    showToast = true,
    toolId: any,
    tool: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_TICKET_TOOL_VERSION_LIST_URL}${tool}/${toolId}`,
      (res: Object) => dispatch(getTicketToolVersionListSuccess(res)),
      (err: any) => dispatch(getTicketToolVersionListError()),
      {},
      METHOD.GET,
      {
        showToast: showToast,
      },
    );
  };

export const getTicketToolVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getTicketToolVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_TOOL_VERSION_LIST.GET_TICKET_TOOL_VERSION_LIST_ERROR,
    });
  };

export const getTicketAssigneeList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTicketAssigneeListInit(showToast));
  };

export const getTicketAssigneeListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_INITIALIZATION,
    });
    apiCall(
      GET_TICKET_ASSIGNEE_LIST_URL + "?groupType=Ticket",
      (res: Object) => dispatch(getTicketAssigneeListSuccess(res)),
      (err: any) => dispatch(getTicketAssigneeListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getTicketAssigneeListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getTicketAssigneeListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TICKET_ASSIGNEE_LIST.GET_TICKET_ASSIGNEE_LIST_ERROR,
    });
  };

export const updateTicketAssigneeList =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(updateTicketAssigneeListInit(showToast, id));
  };

export const updateTicketAssigneeListInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TICKET_ASSIGNEE_LIST.UPDATE_TICKET_ASSIGNEE_LIST_INITIALIZATION,
    });
    apiCall(
      UPDATE_TICKET_ASSIGNEE_LIST_URL + id,
      (res: Object) => dispatch(updateTicketAssigneeListSuccess(res)),
      (err: any) => dispatch(updateTicketAssigneeListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const updateTicketAssigneeListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TICKET_ASSIGNEE_LIST.UPDATE_TICKET_ASSIGNEE_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const updateTicketAssigneeListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPDATE_TICKET_ASSIGNEE_LIST.UPDATE_TICKET_ASSIGNEE_LIST_ERROR,
    });
  };

export const setPasswordManual =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(setPasswordManualInit(showToast, id));
  };

export const setPasswordManualInit =
  (
    showToast = true,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TICKET_ASSIGNEE_LIST.UPDATE_TICKET_ASSIGNEE_LIST_INITIALIZATION,
    });
    apiCall(
      UPDATE_TICKET_ASSIGNEE_LIST_URL + id,
      (res: Object) => dispatch(setPasswordManualSuccess(res)),
      (err: any) => dispatch(setPasswordManualError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const setPasswordManualSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TICKET_ASSIGNEE_LIST.UPDATE_TICKET_ASSIGNEE_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const setPasswordManualError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPDATE_TICKET_ASSIGNEE_LIST.UPDATE_TICKET_ASSIGNEE_LIST_ERROR,
    });
  };
