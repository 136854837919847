import { Card, CardActionArea, CardMedia } from "@mui/material";
import { GemsAdvertisementResponse } from "../../../features/gemsAdvertisements/types";

interface GemsAdvertisementsMarqueeSlideProps
  extends GemsAdvertisementResponse {}

const GemsAdvertisementsMarqueeSlide = (
  props: GemsAdvertisementsMarqueeSlideProps,
) => {
  const { title, imageUrl, promotionUrl } = props;

  return (
    <Card sx={{ maxWidth: 345, margin: 2 }}>
      <CardActionArea href={promotionUrl} target="_blank" rel="noopener">
        <CardMedia component="img" height="256" image={imageUrl} alt={title} />
      </CardActionArea>
    </Card>
  );
};

export default GemsAdvertisementsMarqueeSlide;
