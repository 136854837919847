import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { dispatch } from "../../redux";
import {
  setMediaUploadProgress,
  uploadMediaList,
  uploadMediaListError,
  uploadMediaListSuccess,
} from "../../redux/action";
import { getToken, valiadteToken } from "../../util/utils";

const BASE_URL = process.env.REACT_APP_BASE_URL; // "http://localhost:3333/"

const _UploadMediaMutiple = (imageData: File[], statekey: string) => {
  dispatch(uploadMediaList());

  const token = getToken("login-auth-token");

  if (valiadteToken()) {
    const auth = token ? `Bearer ${token}` : "";

    const formData = new FormData();
    imageData.map((img: any) => {
      formData.append("file", img);
    });
    axios
      .post(`${process.env.REACT_APP_BASE_URL}files/multiple`, formData, {
        headers: {
          Authorization: auth,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data: any) => {
          if (Math.round((100 * data.loaded) / data.total) > 0) {
            dispatch(
              setMediaUploadProgress(
                Math.round((100 * data.loaded) / data.total),
              ),
            );
          }
        },
      })
      .then((res: AxiosResponse) => {
        if (res.data.data) {
          const newlyUploaded: any = [];
          res.data.data.map((i: any) => {
            newlyUploaded.push({ key: i.key, location: i.location });
          });
          dispatch(
            uploadMediaListSuccess({
              newlyUploaded: newlyUploaded,
              stateKey: statekey,
            }),
          );
        }
      })
      .catch((err: any) => {
        toast.error("File upload failed !");
        dispatch(uploadMediaListError(err));
      });
  }
};

const _RegisterUploadMedia = (imageData: any) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("image", imageData);
    axios
      .post(BASE_URL + `files/image/register`, formData, {
        headers: {
          // Authorization: auth
        },
      })
      .then((res: AxiosResponse) => {
        if (res?.data?.data?.location) {
          resolve({ fileUploadResponse: res.data.data.location, error: false });
        } else {
          toast.error("Something went wrong while uploading");
          resolve({ fileUploadResponse: "", error: true });
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong while uploading");
        resolve({ fileUploadResponse: "", error: true });
      });
  });
};

const _UploadMedia = (imageData: any, showToast = true) => {
  const token = getToken("login-auth-token");

  if (token) {
    const auth = token ? `Bearer ${token}` : "";

    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("image", imageData);
      axios
        .post(BASE_URL + `files/image`, formData, {
          headers: {
            Authorization: auth,
          },
        })
        .then((res: AxiosResponse) => {
          resolve({
            fileUploadResponse: res.data.data.location,
            error: false,
          });
        })
        .catch((err: any) => {
          toast.error("Something went wrong while uploading");
          resolve({
            fileUploadResponse: "",
            error: true,
          });
          // reject(err);
        });
    });
  }
};

const _RegisterDeleteMedia = (location: string) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BASE_URL + `files/delete/register`, {
        data: { location: location },
        headers: {
          // Authorization: auth
        },
      })
      .then((res: Object) => {
        resolve(res);
      })
      .catch((err: any) => {
        toast.error("File upload failed !");
        reject(err);
      });
  });
};

const _DeleteMedia = (location: string) => {
  const token = getToken("login-auth-token");

  if (valiadteToken()) {
    const auth = token ? `Bearer ${token}` : "";

    return new Promise((resolve, reject) => {
      axios
        .delete(BASE_URL + `files/delete`, {
          data: location,
          headers: {
            Authorization: auth,
          },
        })
        .then((res: Object) => {
          resolve(res);
        })
        .catch((err: any) => {
          toast.error("File upload failed !");
          reject(err);
        });
    });
  }
};

export {
  _UploadMedia,
  _DeleteMedia,
  _RegisterUploadMedia,
  _RegisterDeleteMedia,
  _UploadMediaMutiple,
};
