import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../assets/img/gemsFX_normal.png";
import {
  forgotPasswordSchema,
  forgotPasswordSchema1,
} from "../util/validationSchema";
import { emailVerification, resendOtp, set_email } from "../redux/action";
import { Store } from "../redux/Actions";
import OtpInput from "react-otp-input";
import Loader from "../Components/common/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import { Translation } from "../Components/common/Translation";

const EmailVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isEmailAdded, setIsEmailAdded] = useState(false);

  const email_send_success = useSelector(
    (state: Store) => state.userDataReducer.isResended,
  );

  const OTP_Resended = useSelector(
    (state: Store) => state.userDataReducer.email_send_success,
  );

  const otpVerified = useSelector(
    (state: Store) => state.userDataReducer.otpVerified,
  );
  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );

  const intl = useIntl();

  const formik = useFormik({
    initialValues: {
      email: "",
      OTP: "",
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      dispatch(emailVerification(values.email, parseInt(values.OTP)));
      dispatch(set_email(true));
    },
  });
  const resendOtpFunction = (email: string) => {
    setIsEmailAdded(true);
    dispatch(resendOtp(email, true));
  };
  useEffect(() => {
    const email = sessionStorage.getItem("verify_email") || "";
    if (email?.length > 0) {
      formik.setFieldValue("email", email);
      setIsEmailAdded(true);
    }
  }, []);

  useEffect(() => {
    if (otpVerified) {
      sessionStorage.clear();
    }
  }, [otpVerified]);

  useEffect(() => {
    dispatch(set_email(true));
  }, []);

  useEffect(() => {
    if (email_send_success && OTP_Resended) {
      setIsEmailAdded(true);
    }
  }, [email_send_success, OTP_Resended]);

  const formik1 = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgotPasswordSchema1,
    onSubmit: (values) => {
      dispatch(resendOtp(values.email, true));
      formik.setFieldValue("email", values.email);
    },
  });
  const touched1 = formik1.touched;
  const errors1 = formik1.errors;
  const VerificationRemainder = () => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-between">
        <h1 className="text-center m-3  font-weight-500">
          <FormattedMessage
            id="Admin.approve"
            defaultMessage="Congrats! You have successfully verified the account. You can login after GemsFX Team admin’s approval. "
          />
          <Link to={"/login"} className="mt-5 btn btn-primary btn-block btn-lg">
            <FormattedMessage id="Login.Home" defaultMessage="Home" />
          </Link>
        </h1>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="main-container d-flex align-items-center justify-content-center bg-login-image">
        <div className="login-box common-box">
          <Translation />
          <div className="login-logo">
            <img style={{ height: "50px" }} src={Logo} alt="Logo" />
          </div>
          <div className="login-box-body">
            <h1 className="text-center mb-3 font-weight-500">
              {!otpVerified && (
                <FormattedMessage
                  id="head.VerifyEmail"
                  defaultMessage="Verify Your Email"
                />
              )}
            </h1>
            {!isEmailAdded && !otpVerified && (
              <form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  formik1.handleSubmit();
                }}
              >
                <p className="text-center mb-4">
                  <FormattedMessage
                    id="head.RegisteredEmail"
                    defaultMessage="Enter your registered email"
                  />
                </p>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    value={formik1.values.email}
                    onBlur={formik1.handleBlur}
                    onChange={formik1.handleChange}
                    placeholder={intl.formatMessage({
                      id: "login.Email",
                      defaultMessage: "Email Id",
                    })}
                  />
                  {errors1.email && touched1.email && (
                    <div className="text-error">
                      <FormattedMessage id={errors1.email.toString()} />
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  style={{ display: "inherit" }}
                  className="btn btn-primary btn-block btn-lg"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <FormattedMessage
                      id="head.sendEmail"
                      defaultMessage="Send Email"
                    />
                    {isLoading && (
                      <div className="ml-2">
                        <Loader />
                      </div>
                    )}
                  </div>
                </button>
                <div className="d-flex align-items-center justify-content-between m-t-10">
                  <button
                    className="text-accent-custom font-size-14"
                    style={{ background: "none", border: "none" }}
                    onClick={() => {
                      dispatch(set_email(true));
                      setIsEmailAdded(false);
                      navigate("/login");
                    }}
                  >
                    <FormattedMessage id="login" defaultMessage="Login" />
                  </button>
                </div>
              </form>
            )}
            {isEmailAdded && !otpVerified && (
              <form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  if (formik.values.OTP.length < 4) {
                    formik.setFieldTouched("OTP", true);
                  }
                }}
              >
                <div className="form-group otp-box mb-4">
                  <div style={{ fontSize: 14 }}>
                    <FormattedMessage
                      id="login.Email.OTP"
                      defaultMessage="We have sent a one time password to your email address at {Email}. {br} Please enter the digits from your email in you one time password field"
                      values={{ Email: formik.values.email, br: <br /> }}
                    />
                  </div>
                  <label>
                    <FormattedMessage id="login.Email" defaultMessage="Email" />
                    : {formik.values.email}
                  </label>
                  <br />
                  <label>
                    <FormattedMessage
                      id="head.EnterOtp"
                      defaultMessage="Enter Your OTP"
                    />
                    {" : "}
                  </label>
                  <OtpInput
                    value={formik.values.OTP}
                    onChange={(OTP: any) => {
                      formik.setFieldTouched("OTP", true);
                      if (!isNaN(OTP)) {
                        formik.setFieldValue("OTP", OTP);
                      }

                      if (OTP.length > 0) {
                        formik.setFieldTouched("OTP", false);
                      }
                    }}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        className="form-control form-control-lg"
                      />
                    )}
                  />
                </div>
                {formik.errors.OTP && formik.touched.OTP && (
                  <div className="text-danger">
                    <FormattedMessage id={formik.errors.OTP.toString()} />
                  </div>
                )}
                <button
                  type="submit"
                  style={{ display: "inherit" }}
                  className="btn btn-primary btn-block btn-lg"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <FormattedMessage id="verify" defaultMessage="Verify" />
                    {isLoading && (
                      <div className="ml-2">
                        <Loader />
                      </div>
                    )}
                  </div>
                </button>
                <div className="d-flex align-items-center justify-content-between m-t-10">
                  <div className="password-reset-link">
                    <a
                      className="text-accent-custom font-size-14"
                      onClick={() => {
                        resendOtpFunction(formik.values.email);
                      }}
                    >
                      <FormattedMessage
                        id="resend.Otp"
                        defaultMessage="Resend OTP"
                      />
                      ?
                    </a>
                  </div>
                  <button
                    className="text-accent-custom font-size-14"
                    style={{ background: "none", border: "none" }}
                    onClick={() => {
                      dispatch(set_email(true));
                      setIsEmailAdded(false);
                      sessionStorage.clear();
                      navigate("/login");
                    }}
                  >
                    <FormattedMessage id="login" defaultMessage="Login" />
                  </button>
                </div>
              </form>
            )}

            {otpVerified && <VerificationRemainder />}
          </div>
          {!otpVerified && (
            <div className="login-box-footer border-top">
              <div className="password-reset-link text-center">
                <FormattedMessage
                  id="dont.Account"
                  defaultMessage="Don't have an account"
                />
                ?
                <Link className="text-accent-custom" to="/register">
                  <FormattedMessage
                    id="login.Register"
                    defaultMessage="Register here"
                  />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmailVerification;
