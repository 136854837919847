/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_TEMPOARARY_ACCOUNT_LIST,
  GET_DEMO_ACCOUNT_LIST,
  GET_LIVE_ACCOUNT_LIST,
  GET_ALL_ACCOUNT_LIST,
  ADD_ACCOUNT_LIST,
  DELETE_ACCOUNT_LIST,
  ADD_ALTERNATIVE_NAME_LIST,
  GET_ALTERNATIVE_NAME_LIST,
  GET_TRADING_ACCOUNT_LIST,
  GET_BROKER_LIST,
  ADD_TRADING_ACCOUNT,
  DELETE_TRADING_ACCOUNT,
  GET_ACCOUNT_EXPERT_ADVISOR_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import {
  GET_TEMPOARARY_ACCOUNT_LIST_URL,
  GET_DEMO_ACCOUNT_LIST_URL,
  GET_LIVE_ACCOUNT_LIST_URL,
  GET_ALL_ACCOUNT_LIST_URL,
  ADD_ACCOUNT_LIST_URL,
  DELETE_ACCOUNT_LIST_URL,
  GET_ALTERNATIVE_NAME_LIST_URL,
  ADD_ALTERNATIVE_NAME_LIST_URL,
  GET_TRADING_ACCOUNT_LIST_URL,
  GET_BROKER_LIST_URL,
  DELETE_TRADING_ACCOUNT_LIST_URL,
  GET_ACCOUNT_EXPERT_ADVISOR_LIST_URL,
} from "../../service/apiEndPoints";
import { getQR } from "./userActions";
import { appIntl } from "../../util/intl";
import { toast } from "react-toastify";

export const getTempoararyAccountList =
  (
    showToast = true,
    pageNumber: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTempoararyAccountListInit(showToast, pageNumber));
  };

export const getTempoararyAccountListInit =
  (
    showToast = true,
    pageNumber: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TEMPOARARY_ACCOUNT_LIST.GET_TEMPOARARY_ACCOUNT_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_TEMPOARARY_ACCOUNT_LIST_URL}?page=${pageNumber}&perPage=${process.env.REACT_APP_RECORDS_PER_PAGE}&sort=DESC&sortBy=createdAt`,
      (res: Object) => dispatch(getTempoararyAccountListSuccess(res)),
      (err: any) => dispatch(getTempoararyAccountListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getTempoararyAccountListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TEMPOARARY_ACCOUNT_LIST.GET_TEMPOARARY_ACCOUNT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getTempoararyAccountListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TEMPOARARY_ACCOUNT_LIST.GET_TEMPOARARY_ACCOUNT_LIST_ERROR,
    });
  };

export const getDemoAccountList =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getDemoAccountListInit(showToast, pageNumber, sort, filter, pageLimit),
    );
  };

export const getDemoAccountListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter = "",
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_DEMO_ACCOUNT_LIST.GET_DEMO_ACCOUNT_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_DEMO_ACCOUNT_LIST_URL}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}${filter}`,
      (res: Object) => dispatch(getDemoAccountListSuccess(res)),
      (err: any) => dispatch(getDemoAccountListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getDemoAccountListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_DEMO_ACCOUNT_LIST.GET_DEMO_ACCOUNT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getDemoAccountListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_DEMO_ACCOUNT_LIST.GET_DEMO_ACCOUNT_LIST_ERROR,
    });
  };

export const getLiveAccountList =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getLiveAccountListInit(showToast, pageNumber, sort, filter, pageLimit),
    );
  };

export const getLiveAccountListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter = "",
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIVE_ACCOUNT_LIST.GET_LIVE_ACCOUNT_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_LIVE_ACCOUNT_LIST_URL}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}${filter}`,
      (res: Object) => dispatch(getLiveAccountListSuccess(res)),
      (err: any) => dispatch(getLiveAccountListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getLiveAccountListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_LIVE_ACCOUNT_LIST.GET_LIVE_ACCOUNT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getLiveAccountListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_LIVE_ACCOUNT_LIST.GET_LIVE_ACCOUNT_LIST_ERROR,
    });
  };

export const getAllAccountListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_ACCOUNT_LIST.GET_ALL_ACCOUNT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAllAccountListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ALL_ACCOUNT_LIST.GET_ALL_ACCOUNT_LIST_ERROR,
    });
  };

export const getAllAccountList =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter = "",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getAllAccountListInit(showToast, pageNumber, sort, filter, pageLimit),
    );
  };

export const getAllAccountListInit =
  (
    showToast = true,
    pageNumber: number,
    sort: any,
    filter = "",
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALL_ACCOUNT_LIST.GET_ALL_ACCOUNT_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_ALL_ACCOUNT_LIST_URL}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}${filter}`,
      (res: Object) => dispatch(getAllAccountListSuccess(res)),
      (err: any) => dispatch(getAllAccountListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const deleteAllAccountList =
  (
    showToast = true,
    type: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteAllAccountListInit(showToast, type, id));
  };

export const deleteAllAccountListInit =
  (
    showToast = true,
    type: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_ACCOUNT_LIST.DELETE_ACCOUNT_LIST_INITIALIZATION,
    });
    apiCall(
      `${DELETE_ACCOUNT_LIST_URL}`,
      (res: Object) =>
        dispatch(deleteAllAccountListSuccess(res, type, showToast)),
      (err: any) => dispatch(deleteAccountListError()),
      {
        ids: id,
        type: type,
      },
      METHOD.PUT,
      { showToast },
    );
  };

export const deleteAllAccountListSuccess =
  (
    res: any,
    type: any,
    showToast = true,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_ACCOUNT_LIST.DELETE_ACCOUNT_LIST_SUCCESS,
      payload: res.data,
    });
    // if (showToast) {
    //   toast.success(appIntl().formatMessage({ id: "Delete.Account" }));
    // }
    dispatch(
      getAllAccountList(false, 1, { by: "createdAt", sort: "DESC" }, ""),
    );
    dispatch(getQR());
  };

export const addAllAccountList =
  (
    showToast = true,
    account: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addAllAccountListInit(showToast, account));
  };

export const addAllAccountListInit =
  (
    showToast = true,
    account: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ACCOUNT_LIST.ADD_ACCOUNT_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_ACCOUNT_LIST_URL,
      (res: Object) => dispatch(addAllAccountListSuccess(res, account.type)),
      (err: any) => dispatch(addAllAccountListError()),
      account,
      METHOD.POST,
      { showToast },
    );
  };

export const addAllAccountListSuccess =
  (res: any, type: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ACCOUNT_LIST.ADD_ACCOUNT_LIST_SUCCESS,
      payload: res.data,
    });
    dispatch(
      getAllAccountList(false, 1, { by: "createdAt", sort: "DESC" }, ""),
    );
    dispatch(getQR());
  };

export const addAllAccountListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ACCOUNT_LIST.ADD_ACCOUNT_LIST_ERROR,
    });
  };

export const addAccountList =
  (
    showToast = true,
    account: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addAccountListInit(showToast, account));
  };

export const addAccountListInit =
  (
    showToast = true,
    account: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ACCOUNT_LIST.ADD_ACCOUNT_LIST_INITIALIZATION,
    });
    apiCall(
      ADD_ACCOUNT_LIST_URL,
      (res: Object) => dispatch(addAccountListSuccess(res, account.type)),
      (err: any) => dispatch(addAccountListError()),
      account,
      METHOD.POST,
      { showToast },
    );
  };

export const addAccountListSuccess =
  (res: any, type: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ACCOUNT_LIST.ADD_ACCOUNT_LIST_SUCCESS,
      payload: res.data,
    });
    if (type === "L") {
      dispatch(
        getLiveAccountList(false, 1, { by: "createdAt", sort: "DESC" }, ""),
      );
    }

    if (type === "D") {
      dispatch(
        getDemoAccountList(false, 1, { by: "createdAt", sort: "DESC" }, ""),
      );
    }

    dispatch(getQR());
  };

export const addAccountListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ACCOUNT_LIST.ADD_ACCOUNT_LIST_ERROR,
    });
  };

export const deleteAccountList =
  (
    showToast = true,
    type: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteAccountListInit(showToast, type, id));
  };

export const deleteAccountListInit =
  (
    showToast = true,
    type: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_ACCOUNT_LIST.DELETE_ACCOUNT_LIST_INITIALIZATION,
    });
    apiCall(
      `${DELETE_ACCOUNT_LIST_URL}`,
      (res: Object) => dispatch(deleteAccountListSuccess(res, type)),
      (err: any) => dispatch(deleteAccountListError()),
      {
        ids: id,
        type: type,
      },
      METHOD.PUT,
      { showToast },
    );
  };

export const deleteAccountListSuccess =
  (res: any, type: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_ACCOUNT_LIST.DELETE_ACCOUNT_LIST_SUCCESS,
      payload: res.data,
    });
    toast.success(appIntl().formatMessage({ id: "Delete.Account" }));
    if (type === "L") {
      dispatch(
        getLiveAccountList(false, 1, { by: "createdAt", sort: "DESC" }, ""),
      );
    }

    if (type === "D") {
      dispatch(
        getDemoAccountList(false, 1, { by: "createdAt", sort: "DESC" }, ""),
      );
    }
    dispatch(getQR());
  };

export const deleteAccountListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_ACCOUNT_LIST.DELETE_ACCOUNT_LIST_ERROR,
    });
  };

export const getAlternativeNameList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAlternativeNameListInit(showToast));
  };

export const getAlternativeNameListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALTERNATIVE_NAME_LIST.GET_ALTERNATIVE_NAME_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_ALTERNATIVE_NAME_LIST_URL}`,
      (res: Object) => dispatch(getAlternativeNameListSuccess(res)),
      (err: any) => dispatch(getAlternativeNameListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getAlternativeNameListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ALTERNATIVE_NAME_LIST.GET_ALTERNATIVE_NAME_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getAlternativeNameListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ALTERNATIVE_NAME_LIST.GET_ALTERNATIVE_NAME_LIST_ERROR,
    });
  };

export const addAlternativeNameList =
  (
    showToast = true,
    values: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addAlternativeNameListInit(showToast, values));
  };

export const addAlternativeNameListInit =
  (
    showToast = true,
    values: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ALTERNATIVE_NAME_LIST.ADD_ALTERNATIVE_NAME_LIST_INITIALIZATION,
    });
    apiCall(
      `${ADD_ALTERNATIVE_NAME_LIST_URL}`,
      (res: Object) => dispatch(addAlternativeNameListSuccess(res)),
      (err: any) => dispatch(addAlternativeNameListError()),
      { name: values },
      METHOD.POST,
      { showToast },
    );
  };

export const addAlternativeNameListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ALTERNATIVE_NAME_LIST.ADD_ALTERNATIVE_NAME_LIST_SUCCESS,
      payload: res,
    });
  };

export const addAlternativeNameListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ALTERNATIVE_NAME_LIST.ADD_ALTERNATIVE_NAME_LIST_ERROR,
    });
  };
export const getTradingAccountList =
  (
    showToast = true,
    pageNumber: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getTradingAccountListInit(showToast, pageNumber));
  };

export const getTradingAccountListInit =
  (
    showToast = true,
    pageNumber: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TRADING_ACCOUNT_LIST.GET_TRADING_ACCOUNT_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_TRADING_ACCOUNT_LIST_URL}?page=${pageNumber}&perPage=${process.env.REACT_APP_RECORDS_PER_PAGE}&sort=DESC&sortBy=createdAt`,
      (res: Object) => dispatch(getTradingAccountListSuccess(res)),
      (err: any) => dispatch(getTradingAccountListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getTradingAccountListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_TRADING_ACCOUNT_LIST.GET_TRADING_ACCOUNT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getTradingAccountListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TRADING_ACCOUNT_LIST.GET_TRADING_ACCOUNT_LIST_ERROR,
    });
  };
export const getBrokerList =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getBrokerListInit(showToast));
  };

export const getBrokerListInit =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_BROKER_LIST.GET_BROKER_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_BROKER_LIST_URL}`,
      (res: Object) => dispatch(getBrokerListSuccess(res)),
      (err: any) => dispatch(getBrokerListError()),
      {},
      METHOD.GET,
      { showToast: false },
    );
  };

export const getBrokerListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_BROKER_LIST.GET_BROKER_LIST_SUCCESS,
      payload: res,
    });
  };

export const getBrokerListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_BROKER_LIST.GET_BROKER_LIST_ERROR,
    });
  };

export const addTradingAccount =
  (
    showToast = true,
    account: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addTradingAccountInit(showToast, account));
  };

export const addTradingAccountInit =
  (
    showToast = true,
    account: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TRADING_ACCOUNT.ADD_TRADING_ACCOUNT_INITIALIZATION,
    });
    apiCall(
      GET_TRADING_ACCOUNT_LIST_URL,
      (res: Object) => dispatch(addTradingAccountSuccess(res, account.type)),
      (err: any) => dispatch(addTradingAccountError()),
      account,
      METHOD.POST,
      { showToast },
    );
  };

export const addTradingAccountSuccess =
  (res: any, type: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_TRADING_ACCOUNT.ADD_TRADING_ACCOUNT_SUCCESS,
      payload: res.data,
    });
    // if (type === "L") {
    //   dispatch(getLiveAccountList(false, 1));
    // }

    // if (type === "D") {
    //   dispatch(getDemoAccountList(false, 1));
    // }
    dispatch(getTradingAccountList(false, 1));
  };

export const addTradingAccountError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_TRADING_ACCOUNT.ADD_TRADING_ACCOUNT_ERROR,
    });
  };
export const deleteTradingAccount =
  (
    showToast = true,
    // type: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(deleteTradingAccountInit(showToast, id));
  };

export const deleteTradingAccountInit =
  (
    showToast = true,
    // type: any,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_TRADING_ACCOUNT.DELETE_TRADING_ACCOUNT_INITIALIZATION,
    });
    apiCall(
      `${DELETE_TRADING_ACCOUNT_LIST_URL}/${id}`,
      (res: Object) => dispatch(deleteTradingAccountSuccess(res)),
      (err: any) => dispatch(deleteTradingAccountError()),
      {},
      METHOD.DELETE,
      { showToast },
    );
  };

export const deleteTradingAccountSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: DELETE_TRADING_ACCOUNT.DELETE_TRADING_ACCOUNT_SUCCESS,
      payload: res.data,
    });
    // if (type === "L") {
    //   dispatch(getLiveAccountList(false, 1));
    // }

    // if (type === "D") {
    //   dispatch(getDemoAccountList(false, 1));
    // }
    dispatch(getTradingAccountList(false, 1));
  };

export const deleteTradingAccountError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: DELETE_TRADING_ACCOUNT.DELETE_TRADING_ACCOUNT_ERROR,
    });
  };

export const getAccountExpertAdvisorList =
  (
    showToast = false,
    sort = "DESC",
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAccountExpertAdvisorListInit(showToast, sort));
  };

export const getAccountExpertAdvisorListInit =
  (
    showToast = false,
    sort = "DESC",
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_EXPERT_ADVISOR_LIST.GET_ACCOUNT_EXPERT_ADVISOR_LIST_INITIALIZATION,
    });
    apiCall(
      GET_ACCOUNT_EXPERT_ADVISOR_LIST_URL,
      (res: Object) => dispatch(getAccountExpertAdvisorListSuccess(res)),
      (err: any) => dispatch(getAccountExpertAdvisorListError()),
      { params: { sort } },
      METHOD.GET,
      { showToast },
    );
  };

export const getAccountExpertAdvisorListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_EXPERT_ADVISOR_LIST.GET_ACCOUNT_EXPERT_ADVISOR_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getAccountExpertAdvisorListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ACCOUNT_EXPERT_ADVISOR_LIST.GET_ACCOUNT_EXPERT_ADVISOR_LIST_ERROR,
    });
  };
