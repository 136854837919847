/*eslint no-empty-function: ["error", { "allow": ["arrowFunctions"] }]*/
import { useEffect, useRef, useState } from "react";
import { Breadcrumb, Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import {
  addAnnouncementComment,
  getAnnouncementComment,
  getAnnouncementDetails,
} from "../../redux/action";
import { Store } from "../../redux/Actions";
import {
  formatDate,
  getFileDetail,
  get_url_extensions,
} from "../../util/utils";
import { EditorState, convertFromHTML } from "draft-js";
import { ContentState } from "draft-js";
import EditorContainer from "../../Components/common/RichTextEditor/Editor";
import Viewer from "react-viewer";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import AnnouncementComments from "./AnnouncementComment";
import { useLocation } from "react-router-dom";
import { string } from "yup/lib/locale";
import NoDatFound from "../../Components/common/NoDatFound";
import _ from "lodash";
import { ToastDownload } from "../../Components/common/ToastDownload";
import htmlToDraft from "html-to-draftjs";

const IMAGE_TYPES = ["png", "jpeg", "jpg"];
const VIDEO_TYPES = ["mp4", "mov", "wmv", "avi", "mkv", "webm"];

const AnnouncementDetail = () => {
  const urlParams = useParams();
  const dispatch = useDispatch();
  const location = useNavigate();
  const locations = useLocation();

  const [comment, setComment] = useState("");
  const [img, setImg] = useState({ image: [], show: false, index: 0 });
  const [lastPage, setLastPage] = useState("Announcement" as unknown);
  const [ticketAttachmentIndex, setTicketAttachmentIndex] = useState<any>([]);
  const btnRef = useRef<any>();
  const [LoadToastId, setLoadToastId] = useState<any>([]);

  const isLoading = useSelector(
    (state: Store) => state.announcementsReducer.isLoading,
  ) as any;

  const announcementsDetails: any = useSelector(
    (state: Store) => state.announcementsReducer.announcementsDetails,
  ) as any;
  const announcementComments: any = useSelector(
    (state: Store) => state.announcementsReducer.announcementComments,
  ) as any;
  const [announcementAttachment, setAnnouncemenAttachment] = useState<any>([]);

  const blocksFromHTML = convertFromHTML("Test");
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      ),
    ),
  );

  const editorConfig = {
    toolbar: false,
    editable: false,
    height: "200px",
    overflowY: "unset",
    border: "1px solid transparent",
  };

  useEffect(() => {
    const blocksFromHTML = htmlToDraft(announcementsDetails?.content || "Test");
    const estate = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      ),
    );
    setEditorState(estate);

    // if (announcementsDetails.attechment) {
    //   const ff: any = [];///_.sortBy(files,"extension",)
    //   const files: any = [];
    //   announcementsDetails?.attechment.map((i: any) => {
    //     const a: any = getFileDetail([i.attachmentPath])
    //     if (IMAGE_TYPES.includes(a?.[0]?.extention)) {
    //       files.push({ ...a[0], id: i.id })
    //     }
    //     else {
    //       ff.push({ ...a[0], id: i.id })
    //     }
    //   });

    //   //  getFileDetail(
    //   //   announcementsDetails?.attechment.map((i: any) => i.attachmentPath)
    //   //   );
    //   setAnnouncemenAttachment([...files,...ff]);

    //   const f: any = [];

    //   let index: any = 0;
    //   (announcementAttachment || []).map((i: any) => {
    //     if (IMAGE_TYPES.includes(get_url_extensions(i.attachmentPath))) {
    //       // f.push({ src: i.path, alt: 'No Image' })
    //       f[i.id] = index;
    //       index++;
    //     }
    //   })
    //   setTicketAttachmentIndex(f);
    //   setImg({ show: false, image: f, index: 0 });
    // }

    if (announcementsDetails.attechment) {
      const files = getFileDetail(
        announcementsDetails?.attechment.map((i: any) => i.attachmentPath),
      );
      const f: any = [];
      const ff: any = []; ///_.sortBy(files,"extension",)
      const ff1: any = []; ///_.sortBy(files,"extension",)

      (announcementsDetails?.attechment || []).map((i: any) => {
        const a: any = getFileDetail([i.attachmentPath]);

        if (IMAGE_TYPES.includes(i.extention)) {
          f.push({ src: i.path, alt: "No Image" });
          ff.push(a[0]);
        } else {
          ff1.push(a[0]);
        }
      });
      setAnnouncemenAttachment([...ff, ...ff1]);
      setImg({ show: false, image: f, index: 0 });
      // }
    }
  }, [announcementsDetails]);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getAnnouncementDetails(false, parseInt(id)));
      dispatch(getAnnouncementComment(false, id, 1));
    }
    const lastPage = locations.state;
    setLastPage(lastPage);
  }, []);

  const onAddCommnent = () => {
    const { id } = urlParams;

    if (comment.trim().length > 0) {
      dispatch(
        addAnnouncementComment(true, { comment: comment, announcement: id }, 1),
      );
      setComment("");
    }
  };

  const onCommentImageClick = (imgs: any) => {
    const filesImg: any = [];
    const index = ticketAttachmentIndex[imgs?.id || 0] || 0;
    announcementAttachment.map((i: any) => {
      if (IMAGE_TYPES.includes(get_url_extensions(i?.path || "") || "")) {
        filesImg.push({ src: i.path, alt: "No Image" });
      }
    });

    setImg({ show: true, image: filesImg, index: index });
  };

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 });
  }, [locations]);

  return (
    <div className="content-wrapper">
      <div className="content">
        <section className="page-content container-fluid">
          <Breadcrumb>
            <Breadcrumb.Item
              style={{ background: "transparent", verticalAlign: "middle" }}
            >
              <Button onClick={() => location(-1)}>
                {" "}
                <span style={{ verticalAlign: "middle" }}>
                  {" "}
                  <BsChevronLeft />{" "}
                  {lastPage === "Dashboard" ? (
                    <FormattedMessage
                      id="dashboard"
                      defaultMessage="Dashboard"
                    />
                  ) : (
                    <FormattedMessage
                      id="announcements"
                      defaultMessage="Announcements"
                    />
                  )}{" "}
                </span>
              </Button>
            </Breadcrumb.Item>
          </Breadcrumb>
          {isLoading && <CenterLoader />}

          <Card style={{ width: "100%" }}>
            <Card.Body>
              {!_.isEmpty(announcementsDetails) ? (
                <>
                  <div className="mt-3">
                    <Card.Text>
                      <div>
                        <h2 style={{ fontWeight: "600" }}>
                          {announcementsDetails.title}
                        </h2>
                        <div>
                          <h4>
                            {" "}
                            <FormattedMessage
                              id="announcements.Description"
                              defaultMessage=" Description:"
                            />
                          </h4>
                          <p>
                            {announcementsDetails.content && (
                              <EditorContainer
                                onEditorStateChange={() => {
                                  //change
                                }}
                                editorState={editorState}
                                toolbar={editorConfig.toolbar}
                                editable={editorConfig.editable}
                                height={editorConfig.height}
                                overflowY={editorConfig.overflowY}
                                border={editorConfig.border}
                              />
                            )}
                          </p>
                        </div>
                        {announcementAttachment.map((i: any) => {
                          if (VIDEO_TYPES.includes(i?.extention)) {
                            return (
                              <div className="embed-responsive embed-responsive-16by9 mb-3">
                                <video
                                  className="notification-video embed-responsive-item"
                                  src={i.path}
                                  controls
                                  loop
                                  muted
                                  autoPlay
                                  playsInline
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}

                        <div className="ml-1">
                          <h6>
                            {" "}
                            <FormattedMessage
                              id="announcements.Attachment"
                              defaultMessage="Attachment"
                            />{" "}
                          </h6>
                          {announcementsDetails.attechment &&
                            announcementAttachment.map(
                              (imgs: any, index: 0) => (
                                <>
                                  {IMAGE_TYPES.includes(imgs.extention) ? (
                                    <>
                                      {imgs.path && (
                                        <>
                                          {" "}
                                          <img
                                            width={100}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              // setImg((prv) => ({
                                              //   // image: (announcementAttachment || []).map((i: any) => { return { src: i.path, alt: 'No Image' } }),
                                              //   // image: imgs.path,
                                              //   ...prv,
                                              //   show: true,
                                              //   index: index
                                              // }));
                                              onCommentImageClick(index);
                                            }}
                                            src={imgs.path}
                                          />
                                          <Viewer
                                            visible={img.show}
                                            onClose={() => {
                                              setImg({
                                                show: false,
                                                image: [],
                                                index: 0,
                                              });
                                            }}
                                            images={img.image}
                                            //   [
                                            //   { src: img.image, alt: "Not Found" },
                                            // ]}
                                            noToolbar={true}
                                            disableMouseZoom={true}
                                            drag={false}
                                            activeIndex={img.index}
                                            onChange={(
                                              activeImage: any,
                                              index: number,
                                            ) => {
                                              setImg((prv: any) => ({
                                                ...prv,
                                                index: index,
                                              }));
                                            }}
                                          />
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <p>
                                      <ToastDownload
                                        Load={LoadToastId}
                                        setLoad={setLoadToastId}
                                        ref={btnRef}
                                      />
                                      <a
                                        // href={imgs.path}
                                        onClick={() => {
                                          if (btnRef.current) {
                                            setLoadToastId((prv: any) => [
                                              ...prv,
                                              `annD${index}`,
                                            ]);
                                            btnRef?.current.downloadFiles(
                                              imgs.path,
                                              `annD${index}`,
                                            );
                                          }
                                        }}
                                      >
                                        {imgs.name}{" "}
                                      </a>
                                    </p>
                                  )}
                                </>
                              ),
                            )}
                        </div>
                      </div>
                    </Card.Text>
                  </div>
                  <>
                    <AnnouncementComments
                      announcementsDetails={announcementsDetails}
                      announcementComments={announcementComments}
                      isLoading={isLoading}
                    />
                  </>
                </>
              ) : (
                <NoDatFound />
              )}
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};
export default AnnouncementDetail;
