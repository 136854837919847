import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { RedeemPermanantEASchema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../common/Loader";
import { FaTimesCircle } from "react-icons/fa";

const ReferralPermanentEaRedeemModal = ({
  details,
  onClose,
  open,
  onRedeem,
  isLoading,
}: any) => {
  const eaList = [
    { name: "SMA Pro + Lite", id: 30 },
    { name: "MKD Pro + Lite", id: 31 },
    { name: "Flash Pro + Lite", id: 9 },
    { name: "S10 Pro + Lite", id: 32 },
  ];

  const onSaveLoading = useSelector(
    (state: Store) => state.accountReducer.onSaveLoading,
  );

  const [buttonClick, setButtonClick] = useState(false);
  const [selectedEAType, setSelectedEAType] = useState(null);

  const formik = useFormik({
    initialValues: {
      EA_version: 1, // [],
      EA: 0,
    },
    validationSchema: RedeemPermanantEASchema,
    onSubmit: (values) => {
      const ac = {
        ea: values.EA,
        eaVersion: values.EA_version,
      };
      onRedeem(ac);
      setButtonClick(true);
    },
  });

  useEffect(() => {
    if (!onSaveLoading && buttonClick) {
      onClose();
      setButtonClick(false);
    }
  }, [onSaveLoading]);

  function onToolChange(option: any) {
    setSelectedEAType(option);
    formik.setFieldValue("EA", option.id);
  }

  const { errors, touched } = formik;

  return (
    <Modal centered size={"lg"} show={open} className="modal-content-md">
      <Modal.Header>
        <h4 className="modal-title">{details?.giftCategory?.name || "Gift"}</h4>
        <button className="close" onClick={onClose}>
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <FormattedMessage
            id="accountmodal.Expert"
            defaultMessage=" Expert Advisor"
          />
          <Select
            classNamePrefix="react-select-dropdown"
            value={selectedEAType}
            onBlur={() => formik.setFieldTouched("EA", true)}
            onChange={onToolChange}
            options={eaList}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            placeholder={useIntl().formatMessage({
              id: "select.ExpertAdvisor",
              defaultMessage: "Expert Advisor",
            })}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
          {errors.EA && touched.EA && (
            <div className="error">
              <FormattedMessage
                id={errors.EA.toString()}
                defaultMessage="require"
              />
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onClose} type="button">
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          className="btn btn-primary"
          onClick={(values: any) => formik.handleSubmit(values)}
        >
          <FormattedMessage id="page.Submit" defaultMessage="Add" />
          <span style={{ flexDirection: "row", display: "inline-flex" }}>
            {isLoading && (
              <div className="ml-2">
                {" "}
                <Loader />
              </div>
            )}
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReferralPermanentEaRedeemModal;
