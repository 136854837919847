import { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { makeStyles } from "@mui/styles";
import { Col, Row, Card } from "react-bootstrap";
import RegisterStep1 from "./First";
import RegisterStep2 from "./Second";
import RegisterStep3 from "./Third";
import RegisterStep4 from "./Four";
import RegisterStep5 from "./Five";
import RegisterStep6 from "./Six";
import RegisterStep7 from "./Seven";
import { Store } from "../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import "./register.css";
import RegisterFinalStep from "./Final";
import { Button, Switch } from "@mui/material";
import {
  setRegister,
  setRegisterStepBack,
  translate,
  setTheme,
} from "../../redux/action";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import Moon from "../../assets/img/moon.svg";
import { FaAngleDown } from "react-icons/fa";

const steps = [
  "register.setp1",
  "register.setp2",
  "register.setp3",
  "register.setp4",
  "register.setp5",
  "register.setp6",
  "register.setp7",
  "register.setp8",
];

const useStyles = makeStyles({
  root: {
    "& .MuiStepper-root": {
      flexDirection: "column",
    },
  },
});
const Register = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const local = useSelector((state: Store) => state.userDataReducer.local);
  const darkTheme = useSelector(
    (state: Store) => state.userDataReducer.darkTheme,
  );
  const isRegisterd = useSelector(
    (state: Store) => state.userDataReducer.isRegisterd,
  );

  const completedStep = useSelector(
    (state: Store) => state.userDataReducer.completedStep,
  );
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (isRegisterd) {
      dispatch(setRegister(false));
      navigate("/email-verification");
    }
  }, [isRegisterd]);

  useEffect(() => {
    setActiveStep(completedStep + 1);
  }, [completedStep]);

  useEffect(() => {
    const body = document.getElementById("body");
    if (darkTheme) {
      body?.classList.add("dark-theme");
    } else {
      body?.classList.remove("dark-theme");
    }
  }, [darkTheme]);

  const translateLocal = (language: string) => {
    dispatch(translate(language));
  };

  const setBack = (step: number) => {
    setActiveStep(completedStep);

    dispatch(setRegisterStepBack(step));
  };

  return (
    <div className="main-container d-flex align-items-center justify-content-center">
      <Row className="h-100p w-100p no-gutters">
        <Col md={12} sm={12} lg={7} className="h-100p">
          <Card
            className="h-100p m-0 overflow-y-auto"
            style={{ display: "block" }}
          >
            <Card.Body
              className="d-flex flex-column bg-register-box"
              style={{ backgroundColor: "transparent" }}
            >
              {activeStep == 0 && <RegisterStep1 />}
              {activeStep == 1 && <RegisterStep2 />}
              {activeStep == 2 && <RegisterStep3 onBack={() => setBack(0)} />}
              {activeStep == 3 && <RegisterStep4 onBack={() => setBack(1)} />}
              {activeStep == 4 && <RegisterStep5 onBack={() => setBack(2)} />}
              {activeStep == 5 && <RegisterStep6 onBack={() => setBack(3)} />}
              {activeStep == 6 && <RegisterStep7 onBack={() => setBack(4)} />}
              {activeStep == 7 && (
                <RegisterFinalStep onBack={() => setBack(5)} />
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={12} sm={12} lg={5} className="bg-register-image h-100p">
          <div className="h-100p d-flex flex-column overflow-y-auto">
            <Row className="mt-4 mr-2">
              <Col style={{ textAlign: "end" }}>
                <Button
                  style={{ backgroundColor: "#68439a" }}
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/")}
                >
                  <FormattedMessage id="logIns" defaultMessage="HOME" />
                </Button>
              </Col>
            </Row>
            <Card.Body
              className="d-flex flex-column justify-content-center bg-register-box"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="nav-wrapper">
                <div style={{ paddingBottom: "10px" }}>
                  <div className="custom-switch" style={{ paddingLeft: "0px" }}>
                    <img src={Moon} alt="" width={22} className="mr-1" />
                    <Switch
                      color="default"
                      checked={darkTheme}
                      onChange={() => {
                        dispatch(setTheme(!darkTheme));
                      }}
                    />
                  </div>
                </div>
                <div className="sl-nav">
                  <ul>
                    <li>
                      <span className="text-white pl-3 font-size-16">
                        <FormattedMessage
                          id="translate"
                          defaultMessage="Translate"
                        />
                        <i className="text-white pl-3 " aria-hidden="true">
                          <FaAngleDown />
                        </i>
                      </span>
                      <div className="triangle"></div>
                      <ul style={{ width: "auto" }}>
                        <li
                          onClick={() => translateLocal("zh")}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "16px auto",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <i className="sl-flag flag-de">
                            <div id="germany"></div>
                          </i>{" "}
                          <span
                            className={local === "zh" ? "active" : ""}
                            style={{
                              display: "inline-block",
                              width: "max-content",
                            }}
                          >
                            中文（繁體）
                          </span>
                        </li>
                        <li
                          onClick={() => translateLocal("cn")}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "16px auto",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <i className="sl-flag flag-de">
                            <div id="germany"></div>
                          </i>{" "}
                          <span
                            className={local === "cn" ? "active" : ""}
                            style={{
                              display: "inline-block",
                              width: "max-content",
                            }}
                          >
                            中文（简体）
                          </span>
                        </li>
                        <li
                          onClick={() => translateLocal("en")}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "16px auto",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <i className="sl-flag flag-usa">
                            <div id="germany"></div>
                          </i>{" "}
                          <span
                            className={local === "en" ? "active" : ""}
                            style={{
                              display: "inline-block",
                              width: "max-content",
                            }}
                          >
                            English
                          </span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <Stepper
                activeStep={activeStep}
                className={
                  classes.root + " align-items-start custom-stepper flex-column"
                }
              >
                {steps.map((label) => {
                  const labelProps = { optional: <div></div>, error: false };
                  return (
                    <Step key={label}>
                      <StepLabel {...labelProps}>
                        <FormattedMessage id={label} defaultMessage="None" />
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Card.Body>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Register;
