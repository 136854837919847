import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncements } from "../../redux/action/announcementsActions";
import { Store } from "../../redux/Actions";
import { customFormatDatetimeWithLocale } from "../../util/utils";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Pagination from "../../Components/common/Paginate/Pagination";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

export type AnnouncementsSortedBy = "createdAt" | "name";
type AnnouncementsSort = "ASC" | "DESC";

export const Announcements = ({
  notice = false,
  lastPage = "Announcement",
}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(
    (state: Store) => state.announcementsReducer.isLoading,
  ) as any;
  const totalRecord = useSelector(
    (state: Store) => state.announcementsReducer.totalRecord,
  ) as any;
  const [pageNumber, setPageNumber] = useState<any>();

  const announcement = useSelector(
    (state: Store) => state.announcementsReducer.announcements,
  ) as any;

  const locale = useSelector((state: Store) => state.userDataReducer.local);
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [AnnouncementsSort, setAnnouncementsSort] = useState<"ASC" | "DESC">(
    "DESC",
  );
  const [AnnouncementsSortedBy, setAnnouncementsSortedBy] =
    useState<AnnouncementsSortedBy>("createdAt");

  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(
        getAnnouncements(
          false,
          pageNumber,
          AnnouncementsSort,
          AnnouncementsSortedBy,
          parseInt(pageLimit),
        ),
      );
    }
  }, [pageNumber, AnnouncementsSort, AnnouncementsSortedBy, pageLimit]);

  useEffect(() => {
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  const viewClassDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    navigate("/announcement/" + id, { state: lastPage });
  };

  const onPageChange = (pageNum: number) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit);
    setPageNumber(pageNum);
  };

  const setSorting = (id: AnnouncementsSortedBy) => {
    if (AnnouncementsSortedBy === id) {
      setAnnouncementsSort(AnnouncementsSort === "DESC" ? "ASC" : "DESC");
    } else {
      setAnnouncementsSortedBy(id);
      setAnnouncementsSort("ASC");
    }
  };

  return (
    <div className="content-wrapper">
      {isLoading && <CenterLoader />}

      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                {notice ? (
                  <FormattedMessage
                    id="announcement.notice"
                    defaultMessage="Important Notices"
                  />
                ) : (
                  <FormattedMessage
                    id="announcements"
                    defaultMessage="Announcements"
                  />
                )}{" "}
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <div className="table-responsive">
                {announcement.length ? (
                  <>
                    <div className="table-responsive">
                      {" "}
                      <table className="table table-hover table-striped m-0">
                        <thead>
                          <tr>
                            <th
                              className="table-field-status"
                              style={{ textAlign: "left", fontWeight: "600" }}
                            >
                              {" "}
                              <FormattedMessage
                                id="announcements.Summary"
                                defaultMessage="Summary"
                              />
                            </th>
                            <th
                              className="table-field-actions"
                              style={{
                                textAlign: "left",
                                fontWeight: "600",
                                cursor: "pointer",
                              }}
                              onClick={() => setSorting("createdAt")}
                            >
                              {" "}
                              <FormattedMessage
                                id="announcements.Date"
                                defaultMessage="Date"
                              />
                              {AnnouncementsSortedBy === "createdAt" ? (
                                AnnouncementsSort === "ASC" ? (
                                  <BsSortUp />
                                ) : (
                                  <BsSortDown />
                                )
                              ) : null}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {announcement.length &&
                            announcement.map((i: any) => (
                              <tr onClick={() => viewClassDetail(i.id)}>
                                <td style={{ cursor: "pointer", width: "68%" }}>
                                  {i.title}
                                </td>
                                <td style={{ cursor: "pointer", width: "20%" }}>
                                  {customFormatDatetimeWithLocale(
                                    i.createdAt,
                                    locale,
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {totalRecord > 10 && (
                      <>
                        <FormControl className="pagelimit">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            <FormattedMessage
                              id="page_size"
                              defaultMessage="Page Size"
                            />
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={pageLimit}
                            onChange={(e: any) => {
                              setPageLimit(e.target.value);
                              localStorage.setItem("pageLimit", e.target.value);
                              localStorage.setItem("pageNumber", pageNumber);
                              setPageNumber(1);
                            }}
                          >
                            <FormControlLabel
                              value={10}
                              control={<Radio />}
                              label="10"
                            />
                            <FormControlLabel
                              value={20}
                              control={<Radio />}
                              label="20"
                            />
                            <FormControlLabel
                              value={50}
                              control={<Radio />}
                              label="50"
                            />
                          </RadioGroup>
                        </FormControl>
                        <div>
                          <Pagination
                            itemsPerPage={pageLimit}
                            totalPages={totalRecord}
                            onPageChage={onPageChange}
                            page={pageNumber - 1}
                          />
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <p>{!isLoading && !notice && <NoDatFound />}</p>
                )}
              </div>
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default Announcements;
