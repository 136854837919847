import { useState } from "react";
import { isEdge } from "react-device-detect";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";

const MyPasswordInputWrapper = ({
  value,
  className,
  name,
  onBlur,
  onChange,
  errors,
  touched,
  inputDefaultMessage,
  inputDefaultMessageId,
  wrapperStyle = {},
  inputStyle = {},
  disabled = false,
}) => {
  const intl = useIntl();

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((oldShowPassword) => !oldShowPassword);
  };

  const Icon = showPassword ? AiFillEyeInvisible : AiFillEye;

  return (
    <div className={className}>
      <div
        style={{
          ...wrapperStyle,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ position: "relative" }}>
          <input
            value={value}
            style={{ ...inputStyle }}
            disabled={disabled}
            type={showPassword ? "text" : "password"}
            className="form-control"
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={intl.formatMessage({
              id: inputDefaultMessageId,
              defaultMessage: inputDefaultMessage,
            })}
          />
          {!isEdge && (
            <Icon className="show-password-icon" onClick={toggleShowPassword} />
          )}
        </div>

        {errors[name] && touched[name] && (
          <div className="text-error-login">
            <FormattedMessage id={errors[name].toString()} />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPasswordInputWrapper;
