import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import { checkEmail, setRegisterSteps } from "../../redux/action";
import { FormattedMessage, useIntl } from "react-intl";
import { Store } from "../../redux/Actions";
import { RegisterStep2Schema } from "../../util/validationSchema";
import TermsAndConditions from "./TermsAndConditions";
import PasswordStrengthBar from "react-password-strength-bar";
import { isEdge } from "react-device-detect";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const RegisterStep2 = () => {
  const dispatch = useDispatch();
  const users = useSelector((state: Store) => state.userDataReducer.userObject);
  const isUniqueEmail = useSelector(
    (state: Store) => state.userDataReducer.uniqueEmail,
  );
  const isLoading = useSelector(
    (state: Store) => state.userDataReducer.isLoading,
  );
  const [buttonClick, setButtonClick] = useState(false);
  const [agree, setAgree] = useState(false);
  const [UniqueEmail, setUniqueEmail] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: users?.email || "",
      password: users?.password || "",
      confirmPassword: users?.password || "",
    },
    validationSchema: RegisterStep2Schema,
    onSubmit: (values) => {
      dispatch(checkEmail(values.email));
      setButtonClick(true);
    },
  });

  const { touched, errors, values } = formik;

  useEffect(() => {
    setUniqueEmail(isUniqueEmail);
    if (isUniqueEmail && buttonClick) {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);

      localStorage.setItem("reg", JSON.stringify(formData));

      dispatch(
        setRegisterSteps(1, { email: values.email, password: values.password }),
      );
      return;
    } else {
      if (buttonClick && !isUniqueEmail && !isLoading) {
        const e = document.getElementById("email"); //.focus();
        if (e) {
          e.focus();
        }
      }
    }
  }, [isUniqueEmail, buttonClick, isLoading]);

  const emailBlur = () => {
    if (formik.values.email.length > 0) {
      formik.setFieldTouched("email", true);
    }
    setUniqueEmail(true);
  };

  const checkboxChange = (e: any) => {
    if (e.target.checked) {
      setAgree(true);
    } else {
      setAgree(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((oldShowPassword) => !oldShowPassword);
  };

  const PasswordIcon = showPassword ? AiFillEyeInvisible : AiFillEye;

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((oldShowPassword) => !oldShowPassword);
  };

  const ConfirmPasswordIcon = showConfirmPassword
    ? AiFillEyeInvisible
    : AiFillEye;

  return (
    <>
      <div className="mb-4">
        <h1 className="h5 font-weight-600 border-bottom pb-4 mb-4">
          <FormattedMessage
            id="register.open.account"
            defaultMessage="Open Your Account From Here"
          />{" "}
        </h1>
        <div className="form-group">
          <label>
            <FormattedMessage
              id="register.emailAddress"
              defaultMessage="Email Address"
            />
          </label>
          <input
            type="email"
            name="email"
            id="email"
            onBlur={emailBlur}
            onChange={(value) => {
              formik.handleChange(value);
              setUniqueEmail(true);
            }}
            value={formik.values.email}
            className="form-control"
            placeholder={useIntl().formatMessage({
              id: "profile.Email",
              defaultMessage: "Email",
            })}
          />
          {errors.email && touched.email && (
            <div className="error">
              <FormattedMessage id={errors.email.toString()} />{" "}
            </div>
          )}
          {UniqueEmail !== null &&
          !UniqueEmail &&
          !isLoading &&
          buttonClick &&
          values.email.length > 0 ? (
            <div className="error">
              <FormattedMessage
                id="register.emailError"
                defaultMessage="Email already exist.. !"
              />
            </div>
          ) : null}
        </div>
        <div className="form-group">
          <label>
            {" "}
            <FormattedMessage
              id="register.set.password"
              defaultMessage="Set a strong password"
            />{" "}
          </label>
          <div className="position-relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              className="form-control"
              value={formik.values.password}
              placeholder={useIntl().formatMessage({
                id: "profile.Password",
                defaultMessage: "Password",
              })}
            />
            {!isEdge && (
              <PasswordIcon
                className="show-password-register"
                onClick={toggleShowPassword}
              />
            )}
          </div>
          <div className="w-350">
            <PasswordStrengthBar
              className="passwordStrength"
              password={formik.values.password}
              barColors={["#ddd", "#af95d0", "#9471c1", "#794eb1", "#68439a"]}
            />
          </div>
          {errors.password && touched.password && (
            <div className="error">
              {" "}
              <FormattedMessage id={errors.password.toString()} />
            </div>
          )}
          <small className="form-text mt-2">
            <FormattedMessage
              id="register.strong.password"
              defaultMessage=" *For security reasons, we recommend setting a password of at least 9 characters , including at least one lowercase, one uppercase and one symbol ."
            />
          </small>
        </div>
        <div className="form-group">
          <label>
            {" "}
            <FormattedMessage
              id="register.step2.cunfirm.pass"
              defaultMessage="Confirm Password"
            />
          </label>
          <div className="position-relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={formik.values.confirmPassword}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="confirmPassword"
              className="form-control"
              placeholder={useIntl().formatMessage({
                id: "profile.confirmPassword",
                defaultMessage: "Confirm Password",
              })}
            />
            {!isEdge && (
              <ConfirmPasswordIcon
                className="show-password-register"
                onClick={toggleShowConfirmPassword}
              />
            )}
          </div>
          {errors.confirmPassword && touched.confirmPassword && (
            <div className="error">
              <FormattedMessage id={errors.confirmPassword.toString()} />
            </div>
          )}
        </div>
        <div className="mt-4 text-left text-accent-custom">
          <div style={{ maxHeight: "350px", overflowY: "auto" }}>
            <TermsAndConditions checkboxChange={checkboxChange} />
          </div>
        </div>
      </div>
      <div className="text-right mt-auto">
        {agree && Object.keys(errors).length == 0 ? (
          <Button
            className="btn btn-primary term-and-conditions-btn"
            onClick={() => formik.handleSubmit()}
          >
            {" "}
            <FormattedMessage id="register.agreeterm.condition" />
          </Button>
        ) : (
          <Button className="btn btn-primary term-and-conditions-btn" disabled>
            {" "}
            <FormattedMessage id="register.agreeterm.condition" />
          </Button>
        )}
      </div>
    </>
  );
};

export default RegisterStep2;
