import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";
import { Chip } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import {
  editTicketList,
  getQR,
  getTicketCommentList,
  getTicketDetail,
  setMediaUploadEmpty,
} from "../../redux/action";
import {
  AdviseIcon,
  AttachmentIcon,
  BugIcon,
  ProblemIcon,
} from "../../util/iconStyle";
import MoreHorizIcon from "@mui/icons-material/MoreHorizOutlined";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getFileDetail, get_url_extensions } from "../../util/utils";
import Viewer from "react-viewer";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import { FormattedMessage } from "react-intl";
import { _UploadMedia } from "../../service/MediaUpload/classes.service";
import TicketAssignee from "./TicketAssignee";
import TicketComment from "./TicketComment";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { ToastDownload } from "../../Components/common/ToastDownload";
import { FaTrash } from "react-icons/fa";
import { appIntl } from "../../util/intl";
import DeletePopup from "../../Components/common/Popups/DeletePopup";

const MAX_SIZE = 2 * 1024 * 1024; // 2 MB

const IMAGE_TYPES = ["png", "jpeg", "jpg"];

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }: any) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
export const TICKET_COMMENT_ATTACHMENT = "ticketCommentAttachment";

const TicketDetail = () => {
  const urlParams = useParams();

  const { id } = urlParams;

  const dispatch = useDispatch();

  const attchRef = useRef<any>();
  const ticketDetails: any = useSelector(
    (state: Store) => state.ticketReducer.ticketDetails,
  ) as any;
  const [ticketAttachment, setTicketAttachment] = useState<any>([]);
  const [ticketAttachmentIndex, setTicketAttachmentIndex] = useState<any>([]);
  const btnRef = useRef<any>();
  const [LoadToastId, setLoadToastId] = useState<any>([]);

  const [ticketText, setTicketText] = useState({ ...ticketDetails });
  const [img, setImg] = useState({ image: [], show: false, index: 0 });
  const [commentEdit, setCommentEdit] = useState({
    title: false,
    content: false,
  });

  const [show, setShow] = useState({
    modal: false,
    index: 0,
    type: "",
  });

  const isLoading = useSelector(
    (state: Store) => state.ticketReducer.isLoading,
  ) as any;

  const onCSaveLoading = useSelector(
    (state: Store) => state.ticketReducer.onCSaveLoading,
  ) as any;
  const myInfo = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as any;
  const [pageNumber, setPageNumber] = useState(1);
  const [buttonClick, setButtonClick] = useState(false);
  const [load, setLoad] = useState(false);
  const location = useLocation();

  // const ticketsDetails: any = useSelector((sta7te: Store) => state.ticketReducer.) as any;
  // const ticketDetails: any = useSelector((state: Store) => state.ticketReducer.ticketDetails) as any;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!onCSaveLoading && buttonClick) {
      dispatch(setMediaUploadEmpty(TICKET_COMMENT_ATTACHMENT));
      setButtonClick(false);
    }
  }, [onCSaveLoading, buttonClick]);

  useEffect(() => {
    const { id } = urlParams;
    if (id && parseInt(id) > 0) {
      dispatch(getTicketCommentList(false, parseInt(id), pageNumber));
      dispatch(getTicketDetail(false, parseInt(id)));
    }
    dispatch(setMediaUploadEmpty(TICKET_COMMENT_ATTACHMENT));
    dispatch(getQR());
  }, []);

  useEffect(() => {
    setTicketText(ticketDetails);
    if (ticketDetails.attachmentList) {
      const files = [];
      ticketDetails?.attachmentList.map((i: any) => {
        const a: any = getFileDetail([i.attachmentPath]);
        files.push({ ...a[0], id: i.id });
      });

      setTicketAttachment(files);

      const filesImg: any = [];
      let index = 0;
      ticketDetails.attachmentList.map((i: any) => {
        if (IMAGE_TYPES.includes(get_url_extensions(i.attachmentPath))) {
          // filesImg.push({ src: i.attachmentPath, alt: 'No Image' })
          filesImg[i.id] = index;
          index++;
        }
      });
      setTicketAttachmentIndex(filesImg);
      setImg({ show: false, image: filesImg, index: 0 });
    }
  }, [ticketDetails]);

  //Add comment Attachment
  const handleDrop = async (e: any) => {
    const acceptedFiles = e.target.files;
    const files: any = [];
    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > MAX_SIZE) {
        const maxSizeInMB = (MAX_SIZE / 1024 / 1024).toString();
        toast.error(
          appIntl().formatMessage(
            { id: "errors.uploadFileSizeError" },
            { maxSizeInMB: maxSizeInMB },
          ),
        );
      } else {
        files.push(acceptedFiles[i]);
      }
    }
    if (files.length > 0) {
      setLoad(true);
      const { fileUploadResponse, error }: any = await _UploadMedia(
        files[0] as File,
      );
      setLoad(false);

      if (!error) {
        const files = getFileDetail([fileUploadResponse]);
        const ticketAttachments = ticketAttachment;
        ticketAttachments.push(...files);

        setTicketAttachment(() => [...ticketAttachments]);
        const ticketDetail: any = {
          title: ticketText.title,
          content: ticketText.content,
          software: ticketDetails.software.id,
          version: ticketDetails.version.id,
          isPublic: ticketDetails.isPublic,
          status: ticketDetails.status,
          type: ticketDetails.type.id,
          tool: ticketDetails.tool,
          ticketGroupId: ticketDetails.ticketGroup.id,
          attachmentList: ticketAttachments.map((i: any) => i.path),
        };
        const { id } = urlParams;
        delete ticketDetail.createdAt;
        delete ticketDetail.updatedAt;
        delete ticketDetail.reporter;

        dispatch(editTicketList(true, id, ticketDetail, 0));
      }
    }
    e.target.value = "";
  };

  const attchmentClick = () => {
    if (attchRef.current) {
      attchRef?.current.click();
    }
  };

  const onUpdateTicket = (isPublic = false) => {
    const { id } = urlParams;
    const ticketDetail: any = {
      title: ticketText.title,
      content: ticketText.content,
      software: ticketDetails.software.id,
      version: ticketDetails.version.id,
      isPublic: isPublic,
      status: ticketDetails.status,
      type: ticketDetails.type.id,
      tool: ticketDetails.tool,
      ticketGroupId: ticketDetails.ticketGroup.id,
      attachmentList: ticketAttachment.map((i: any) => i.path),
    };
    delete ticketDetail.createdAt;
    delete ticketDetail.updatedAt;
    delete ticketDetail.reporter;

    dispatch(editTicketList(true, id, ticketDetail, 0));
    setAnchorEl(null);
  };

  const onImageDelete = (index: any) => {
    // setLoad(true);
    const ticketAttachments = ticketAttachment;
    const a = ticketAttachments.filter((i: any, ind: any) => ind !== index);
    setTicketAttachment(() => [...a]);
    const { id } = urlParams;
    const ticketDetail: any = {
      title: ticketText.title,
      content: ticketText.content,
      software: ticketDetails.software.id,
      version: ticketDetails.version.id,
      isPublic: ticketDetails.isPublic,
      status: ticketDetails.status,
      type: ticketDetails.type.id,
      tool: ticketDetails.tool,
      ticketGroupId: ticketDetails.ticketGroup.id,
      attachmentList: a.map((i: any) => i.path),
    };
    delete ticketDetail.createdAt;
    delete ticketDetail.updatedAt;
    delete ticketDetail.reporter;
    setButtonClick(true);
    dispatch(editTicketList(true, id, ticketDetail, 0));
    setShow({ modal: false, index: 0, type: "" });

    // setLoad(false);
  };

  const onCommentImageClick = (imgs: any) => {
    const filesImg: any = [];
    const index = ticketAttachmentIndex[imgs?.id || 0] || 0;
    ticketAttachment.map((i: any) => {
      if (IMAGE_TYPES.includes(get_url_extensions(i?.path || "") || "")) {
        filesImg.push({ src: i.path, alt: "No Image" });
      }
    });
    setImg({ show: true, image: filesImg, index: index });
  };
  return (
    <div className="content-wrapper">
      <div className="content">
        <div>
          <input
            type="file"
            ref={attchRef}
            style={{ display: "none" }}
            onChangeCapture={(e: any) => handleDrop(e)}
          />
        </div>
        <section className="page-content container-fluid">
          <Breadcrumb>
            <Breadcrumb.Item
              style={{ background: "transparent", verticalAlign: "middle" }}
            >
              <Link to="/ticket" state={location?.state}>
                {" "}
                <span style={{ verticalAlign: "middle" }}>
                  {" "}
                  <BsChevronLeft />
                  <FormattedMessage
                    id="head.Ticket"
                    defaultMessage="Tickets"
                  />{" "}
                </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          {isLoading && <CenterLoader />}
          {load && <CenterLoader />}

          <Card style={{ width: "100%" }}>
            {ticketDetails && Object.keys(ticketDetails).length ? (
              <Card.Body>
                <Row>
                  <Col lg={9} md={8} sm={6}>
                    <div className="mt-3 ">
                      <Card.Text>
                        {ticketDetails?.type && (
                          <div className="mb-3">
                            {ticketDetails.type.id === 2 && (
                              <Chip
                                icon={
                                  <i
                                    className="MuiListItemIcon-root-tbl"
                                    style={{ fill: "#ffffff", height: "16px" }}
                                  >
                                    {" "}
                                    <BugIcon />
                                  </i>
                                }
                                label="Bug"
                                style={{
                                  color: "#ffffff",
                                  borderColor: "rgb(255, 72, 72)",
                                  background: "rgb(255, 72, 72)",
                                  fontWeight: "500",
                                }}
                                variant="outlined"
                              />
                            )}

                            {ticketDetails.type.id === 1 && (
                              <Chip
                                icon={
                                  <i
                                    className="MuiListItemIcon-root-tbl"
                                    style={{ fill: "#ffffff", height: "16px" }}
                                  >
                                    {" "}
                                    <AdviseIcon />
                                  </i>
                                }
                                label="Advice"
                                style={{
                                  color: "#ffffff",
                                  borderColor: "rgb(223, 184, 20)",
                                  background: "rgb(223, 184, 20)",
                                  fontWeight: "500",
                                }}
                                variant="outlined"
                              />
                            )}

                            {ticketDetails.type.id === 3 && (
                              <Chip
                                icon={
                                  <i
                                    className="MuiListItemIcon-root-tbl"
                                    style={{ fill: "#ffffff", height: "16px" }}
                                  >
                                    {" "}
                                    <ProblemIcon />
                                  </i>
                                }
                                label="Problem"
                                style={{
                                  color: "#ffffff",
                                  borderColor: "#820398",
                                  background: "#9c27b0",
                                  fontWeight: "500",
                                }}
                                variant="outlined"
                              />
                            )}
                            <small className="ml-2">
                              {" "}
                              FFI - {ticketDetails.id}{" "}
                            </small>
                          </div>
                        )}
                        <div>
                          <h2 style={{ fontWeight: "500", color: "#909090" }}>
                            {ticketDetails.title}
                          </h2>

                          {Object.keys(myInfo).length > 0 &&
                            myInfo?.id == ticketDetails?.reporter?.id && (
                              <Row className="ml-1 mt-4">
                                <Button
                                  onClick={attchmentClick}
                                  variant="outlined"
                                  style={{ textTransform: "none" }}
                                  size="small"
                                >
                                  <i
                                    className="MuiListItemIcon-root-tbl"
                                    style={{
                                      fill: "#1976d2",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  >
                                    {" "}
                                    <AttachmentIcon />
                                  </i>
                                  <FormattedMessage
                                    id="announcementDetail.attach"
                                    defaultMessage="Attach"
                                  />{" "}
                                </Button>
                                <div className="dropdown">
                                  <Button
                                    id="demo-customized-button"
                                    aria-controls={
                                      open ? "demo-customized-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    variant="text"
                                    color="inherit"
                                    disableElevation
                                    onClick={handleClick}
                                    endIcon={<MoreHorizIcon />}
                                    style={{ textTransform: "none" }}
                                  ></Button>
                                  <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                      "aria-labelledby":
                                        "demo-customized-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                  >
                                    {!ticketDetails.isPublic ? (
                                      <MenuItem
                                        onClick={() => onUpdateTicket(true)}
                                        disableRipple
                                      >
                                        <FormattedMessage
                                          id="ticketDetail.turnToPublic"
                                          defaultMessage="Turn to public"
                                        />
                                      </MenuItem>
                                    ) : (
                                      <MenuItem
                                        onClick={() => onUpdateTicket(false)}
                                        disableRipple
                                      >
                                        <FormattedMessage
                                          id="ticketDetail.turnToPrivate"
                                          defaultMessage="Delete Ticket"
                                        />
                                      </MenuItem>
                                    )}
                                  </StyledMenu>
                                </div>
                              </Row>
                            )}
                          <div className="mt-3">
                            <h4>
                              <FormattedMessage
                                id="head.Description"
                                defaultMessage="Description"
                              />
                            </h4>
                            <p
                              onClick={() =>
                                setCommentEdit({
                                  ...commentEdit,
                                  content: true,
                                })
                              }
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    ticketDetails.content,
                                  ),
                                }}
                              />
                            </p>
                            <p className="mt-3">
                              <h6>
                                <FormattedMessage
                                  id="announcements.Attachment"
                                  defaultMessage="Attachment"
                                />
                              </h6>
                              <p className="mt-3">
                                <Row>
                                  {ticketDetails.attachmentList &&
                                    ticketAttachment.map(
                                      (imgs: any, index: any) => (
                                        <>
                                          {IMAGE_TYPES.includes(
                                            imgs.extention,
                                          ) ? (
                                            <Col lg={3} md={5} sm={5}>
                                              <div className="attch-container">
                                                {imgs.path && (
                                                  <>
                                                    {" "}
                                                    <img
                                                      width={100}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        // setImg((prv) => ({
                                                        //   // image: imgs.path,
                                                        //   ...prv,
                                                        //   show: true,
                                                        //   index: index
                                                        // }));
                                                        onCommentImageClick(
                                                          imgs,
                                                        );
                                                      }}
                                                      src={imgs.path}
                                                    />
                                                    {Object.keys(myInfo)
                                                      .length > 0 &&
                                                      myInfo?.id ==
                                                        ticketDetails?.reporter
                                                          ?.id && (
                                                        <button
                                                          className="btns"
                                                          onClick={() =>
                                                            // onImageDelete(index)
                                                            setShow({
                                                              modal: true,
                                                              type: "P",
                                                              index: index,
                                                            })
                                                          }
                                                        >
                                                          <i>
                                                            <FaTrash />{" "}
                                                          </i>
                                                        </button>
                                                      )}
                                                    <Viewer
                                                      visible={img.show}
                                                      onClose={() => {
                                                        setImg({
                                                          show: false,
                                                          image: [],
                                                          index: 0,
                                                        });
                                                        // onCommentImageClick(index)
                                                      }}
                                                      images={img.image}
                                                      //   [
                                                      //   { src: img.image, alt: "Not Found" },
                                                      // ]}
                                                      noToolbar={true}
                                                      disableMouseZoom={true}
                                                      drag={false}
                                                      activeIndex={img.index}
                                                      onChange={(
                                                        activeImage: any,
                                                        index: number,
                                                      ) => {
                                                        setImg((prv: any) => ({
                                                          ...prv,
                                                          index: index,
                                                        }));
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            </Col>
                                          ) : (
                                            <p></p>
                                          )}
                                        </>
                                      ),
                                    )}
                                </Row>
                              </p>

                              <p className="mt-3">
                                <Row>
                                  {ticketDetails.attachmentList &&
                                    ticketAttachment.map(
                                      (imgs: any, index: any) => (
                                        <>
                                          {IMAGE_TYPES.includes(
                                            imgs.extention,
                                          ) ? (
                                            <></>
                                          ) : (
                                            <Col lg={12} md={12} sm={12}>
                                              <ToastDownload
                                                Load={LoadToastId}
                                                setLoad={setLoadToastId}
                                                ref={btnRef}
                                              />
                                              <a
                                                target="_blank"
                                                // href={imgs.path}
                                                onClick={() => {
                                                  if (btnRef.current) {
                                                    setLoadToastId(
                                                      (prv: any) => [
                                                        ...prv,
                                                        `TickD${index}`,
                                                      ],
                                                    );
                                                    btnRef?.current.downloadFiles(
                                                      imgs.path,
                                                      `TickD${index}`,
                                                    );
                                                  }
                                                }}
                                              >
                                                {imgs.name}{" "}
                                              </a>
                                              {Object.keys(myInfo).length > 0 &&
                                                myInfo?.id ==
                                                  ticketDetails?.reporter
                                                    ?.id && (
                                                  <a
                                                    onClick={() => {
                                                      setShow({
                                                        modal: true,
                                                        type: "F",
                                                        index: index,
                                                      });
                                                    }}
                                                  >
                                                    <i
                                                      style={{
                                                        fontSize: "10px",
                                                      }}
                                                    >
                                                      <FaTrash />
                                                    </i>
                                                  </a>
                                                )}
                                            </Col>
                                          )}
                                        </>
                                      ),
                                    )}
                                </Row>
                              </p>
                            </p>
                          </div>
                        </div>
                      </Card.Text>
                    </div>
                    <TicketComment />
                  </Col>
                  <Col lg={3} md={4} sm={6}>
                    <TicketAssignee ticketDetail={ticketDetails} id={id} />
                  </Col>
                </Row>
              </Card.Body>
            ) : (
              <p>{!isLoading && <NoDatFound />}</p>
            )}
          </Card>
          {show.modal && (
            <DeletePopup
              message={
                <FormattedMessage
                  id={
                    show.type === "P"
                      ? "ticketDetail.conformDeleteP"
                      : "ticketDetail.conformDeleteF"
                  }
                  // defaultMessage="Are you sure want to Delete the file ?"
                />
              }
              onClose={() =>
                setShow((prv: any) => ({
                  ...prv,
                  modal: false,
                  type: "",
                  index: 0,
                }))
              }
              open={show.modal}
              isLoading={isLoading}
              onConfirm={() => onImageDelete(show.index)}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default TicketDetail;
