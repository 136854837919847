/* eslint-disable no-case-declarations */
import {
  GET_LIBRARY_LIST,
  GET_LIBRARY_VERSION_LIST,
} from "../constants/action-types";
import { LibraryData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  libraryList: [],
  onSaveLoading: false,
  libraryDetail: {},
  advertisement: {},
  currentVData: [],
  archiveVData: [],
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: LibraryData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_LIBRARY_LIST.GET_LIBRARY_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_LIBRARY_LIST.GET_LIBRARY_LIST_SUCCESS:
        const allPost = action.payload;
        return {
          ...state,
          libraryList: allPost,
          isLoading: false,
        };

      case GET_LIBRARY_LIST.GET_LIBRARY_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_SUCCESS:
        const versionDetail: any = action.payload;
        return {
          ...state,
          libraryDetail: versionDetail.LibraryDetail,
          currentVData: versionDetail.data,
          archiveVData: versionDetail.ArchiveData,
          advertisement: versionDetail.Libraryadvertisement,
          isLoading: false,
        };

      case GET_LIBRARY_VERSION_LIST.GET_LIBRARY_VERSION_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
