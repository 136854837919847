/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_INDUSTRY_LIST,
  GET_JOB_LIST,
  GET_REGISTRATION_MATERIAL,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_INDUSTRY_LIST_URL,
  GET_JOB_LIST_URL,
  GET_REGISTRATION_MATERIAL_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";

export const getIndustryTypeList =
  (
    showToast = true,
    local: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getIndustryTypeListInit(showToast, local));
  };

export const getIndustryTypeListInit =
  (
    showToast = true,
    local: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_INITLIZATION,
    });
    apiCall(
      GET_INDUSTRY_LIST_URL + local.toUpperCase(),
      (res: Object) => dispatch(getIndustryTypeListSuccess(res)),
      (err: any) => dispatch(getIndustryTypeListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getIndustryTypeListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getIndustryTypeListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_INDUSTRY_LIST.GET_INDUSTRY_LIST_ERORR,
    });
  };

export const getJobTitleList =
  (
    showToast = true,
    local: string,
    industry: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getJobTitleListInit(showToast, local, industry));
  };

export const getJobTitleListInit =
  (
    showToast = true,
    local: string,
    industry: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_JOB_LIST.GET_JOB_LIST_INITLIZATION,
    });
    apiCall(
      GET_JOB_LIST_URL + local.toUpperCase() + "/" + industry,
      (res: Object) => dispatch(getJobTitleListSuccess(res)),
      (err: any) => dispatch(getJobTitleListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getJobTitleListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_JOB_LIST.GET_JOB_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getJobTitleListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_JOB_LIST.GET_JOB_LIST_ERORR,
    });
  };

export const getRegistrationMaterial =
  (
    showToast = true,
    local: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getRegistrationMaterialInit(showToast, local));
  };

export const getRegistrationMaterialInit =
  (
    showToast = true,
    local: string,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_INITIALIZATION,
    });
    apiCall(
      GET_REGISTRATION_MATERIAL_URL + local.toUpperCase(),
      (res: Object) => dispatch(getRegistrationMaterialSuccess(res)),
      (err: any) => dispatch(getRegistrationMaterialError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getRegistrationMaterialSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_SUCCESS,
      payload: res.data,
    });
  };

export const getRegistrationMaterialError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_REGISTRATION_MATERIAL.GET_REGISTRATION_MATERIAL_ERROR,
    });
  };
