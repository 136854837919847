import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux";
import { getPointList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import NoDatFound from "../../Components/common/NoDatFound";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import Pagination from "../../Components/common/Paginate/Pagination";
import { customFormatDatetimeWithLocale } from "../../util/utils";
import { modifyMessage } from "../../util/utils";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { Card, Col, Row } from "react-bootstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

export const GainedPoints = ({ page, setPage }: any) => {
  const pointLst = useSelector(
    (state: Store) => state.pointReducer.pointLst,
  ) as any;
  const isLoading = useSelector(
    (state: Store) => state.pointReducer.isLoading,
  ) as any;
  const totalRecord = useSelector(
    (state: Store) => state.pointReducer.totalRecord,
  ) as any;

  const locale = useSelector((state: Store) => state.userDataReducer.local) as
    | "zh"
    | "en";

  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterQ, setFilterQ] = useState("");
  const [filterText, setFilterText] = useState("");
  const [Alltext, setAllText] = useState("");

  const categoryIds = [5, 11];

  useEffect(() => {
    dispatch(
      getPointList(
        false,
        5,
        page,
        { by: sortBy, order: sortOrder },
        filterQ,
        pageLimit,
      ),
    );
  }, [page, filterQ, sortOrder, sortBy, pageLimit]);

  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(filterText);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [filterText]);

  useEffect(() => {
    setFilter(filterText);
  }, [Alltext]);

  const setFilter = (text: string) => {
    let q = "";
    if (text.length > 0) q = q + `&q=${text?.trim()}`;

    setFilterQ(q);
    setPage(1);
    return 0;
  };

  const onPageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  return (
    <>
      <div className="mt-2">
        {isLoading && <CenterLoader />}

        <Row className="p-20">
          <Col style={{ display: "inline-flex" }}>
            <label style={{ position: "absolute", bottom: "0" }}>
              <FormattedMessage id="Filter" defaultMessage="Filter" />
              {": "}
            </label>
            <input
              onChange={(e) => {
                setFilterText(e.target.value);
              }}
              type="text"
              style={{
                maxWidth: "220px",
                paddingLeft: "39px",
                borderRadius: "0px",
                border: "none",
                borderBottom: "1px solid",
              }}
              className="form-control"
            />
          </Col>
        </Row>

        {pointLst.length > 0 ? (
          <>
            <div className="table-responsive">
              {" "}
              <table className="table table-hover table-striped m-0">
                <thead>
                  <tr>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => setSorting("id")}
                    >
                      {" "}
                      <FormattedMessage
                        id="ponts.Id"
                        defaultMessage="id"
                      />{" "}
                      {sortBy === "id" ? (
                        sortOrder === "ASC" ? (
                          <BsSortDown />
                        ) : (
                          <BsSortUp />
                        )
                      ) : null}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => setSorting("added")}
                    >
                      {" "}
                      <FormattedMessage
                        id="ponts.Point.Gained"
                        defaultMessage="Points gained"
                      />{" "}
                      {sortBy === "added" ? (
                        sortOrder === "ASC" ? (
                          <BsSortDown />
                        ) : (
                          <BsSortUp />
                        )
                      ) : null}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => setSorting("message")}
                    >
                      {" "}
                      <FormattedMessage
                        id="ponts.Message"
                        defaultMessage="Message"
                      />
                      {sortBy === "message" ? (
                        sortOrder === "ASC" ? (
                          <BsSortDown />
                        ) : (
                          <BsSortUp />
                        )
                      ) : null}
                    </th>
                    <th
                      style={{ cursor: "pointer" }}
                      onClick={() => setSorting("time")}
                    >
                      {" "}
                      <FormattedMessage id="ponts.Time" defaultMessage="Time" />
                      {sortBy === "time" ? (
                        sortOrder === "ASC" ? (
                          <BsSortDown />
                        ) : (
                          <BsSortUp />
                        )
                      ) : null}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pointLst.length &&
                    pointLst.map((i: any) =>
                      categoryIds.includes(i.logCategory.id) ? (
                        <tr>
                          <td>{i.id || 0}</td>
                          <td>{i.points_added || -i.points_deducted || 0}</td>
                          <td>{modifyMessage(i)}</td>
                          <td>
                            {customFormatDatetimeWithLocale(
                              i.createdAt,
                              locale,
                              true,
                            )}
                          </td>
                        </tr>
                      ) : null,
                    )}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <p>{!isLoading && <NoDatFound />}</p>
        )}
      </div>

      {pointLst.length > 0 && totalRecord > 10 ? (
        <>
          <FormControl className="pagelimit">
            <FormLabel id="demo-row-radio-buttons-group-label">
              <FormattedMessage id="page_size" defaultMessage="Page Size" />
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={pageLimit}
              onChange={(e: any) => {
                setPageLimit(e.target.value);
                setPage(1);
              }}
            >
              <FormControlLabel value={10} control={<Radio />} label="10" />
              <FormControlLabel value={20} control={<Radio />} label="20" />
              <FormControlLabel value={50} control={<Radio />} label="50" />
            </RadioGroup>
          </FormControl>
          <Pagination
            itemsPerPage={pageLimit}
            totalPages={totalRecord}
            onPageChage={onPageChange}
            page={page - 1}
          />
        </>
      ) : null}
    </>
  );
};

export default GainedPoints;
