import axios from "axios";
import { getToken } from "./utils";
import { dispatch } from "../redux";
import { logout } from "../redux/action";

const customFetch = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

customFetch.interceptors.request.use((config) => {
  const token = getToken("login-auth-token");
  if (!config.headers) {
    config.headers = {};
  }

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

const handleUnauthorizedError = (error: unknown) => {
  if (axios.isAxiosError(error) && error.response) {
    if (error.response.status === 401) {
      dispatch(logout(false));
    }
  }
};

export { customFetch, handleUnauthorizedError };
