import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import { dispatch } from "../../redux";
import { getClassSignalList, getMyClasses, getQR } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { saveFile } from "../../util/utils";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

export const ClassSingal = () => {
  const ClassSignalLst = useSelector(
    (state: Store) => state.signalReducer.ClassSignalLst,
  ) as Array<Object>;
  const myClasses = useSelector(
    (state: Store) => state.classReducer.myClasses,
  ) as Array<Object>;
  const myInfo: any = useSelector(
    (state: Store) => state.userDataReducer.myInfo,
  ) as Array<Object>;
  const isAPILoading = useSelector(
    (state: Store) => state.signalReducer.isLoading,
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [usrClass, setusrClass] = useState([]);
  const totalRecord = useSelector(
    (state: Store) => state.signalReducer.totalRecord,
  ) as any;
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterQ, setFilterQ] = useState("");
  const [text, settext] = useState("");
  const [Alltext, setAllText] = useState("");

  useEffect(() => {
    dispatch(
      getClassSignalList(false, pageNumber, { by: sortBy, order: sortOrder }),
    );
    dispatch(getQR());
  }, []);

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(text);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [text]);

  useEffect(() => {
    setFilter(text);
  }, [Alltext]);

  useEffect(() => {
    dispatch(
      getClassSignalList(
        false,
        pageNumber,
        { by: sortBy, order: sortOrder },
        filterQ,
        pageLimit,
      ),
    );
  }, [filterQ, sortOrder, sortBy, pageNumber, pageLimit]);

  const setFilter = (text: string) => {
    let q = "";
    if (text.length > 0) q = q + `&q=${text?.trim()}`;

    setFilterQ(q);
    setPageNumber(1);
    return 0;
  };

  useEffect(() => {
    const cla: any = [];
    if (myClasses.length) {
      myClasses.map((i: any) => {
        if (i.status === "approved") {
          cla.push(i?.class?.id);
        }
      });
      setusrClass(cla);
    }
  }, [myClasses]);

  const onPageChange = (pageNum) => {
    setPageNumber(pageNum);
  };
  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  const settingAccessControl = (
    accessRight: any,
    classId: never,
    userId: any,
  ) => {
    if (myInfo.id == userId) {
      return true;
    } else if (accessRight == 0) {
      //public
      return true;
    } else if (accessRight == 1) {
      // class only
      return usrClass.includes(classId);
    } else if (accessRight == 2) {
      //Private
      return myInfo.id == userId;
    }
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                <FormattedMessage
                  id="menu.signal.class"
                  defaultMessage="Class Signal"
                />
              </h1>
            </div>
          </div>
        </header>
        <section className="page-content container-fluid">
          {isAPILoading && <CenterLoader />}
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Row className="p-20">
                <Col style={{ display: "inline-flex" }}>
                  <label style={{ position: "absolute", bottom: "0" }}>
                    <FormattedMessage id="Filter" defaultMessage="Filter" />
                    {": "}
                  </label>
                  <input
                    onChange={(e) => settext(e.target.value)}
                    type="text"
                    style={{
                      maxWidth: "220px",
                      paddingLeft: "39px",
                      borderRadius: "0px",
                      border: "none",
                      borderBottom: "1px solid",
                    }}
                    className="form-control"
                  />
                </Col>
              </Row>

              {ClassSignalLst.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-striped m-0">
                      <thead>
                        <tr>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("name")}
                          >
                            <FormattedMessage
                              id="signalmodal.Name"
                              defaultMessage="Name"
                            />
                            {sortBy === "name" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("accountType")}
                          >
                            <FormattedMessage
                              id="table.head.Type"
                              defaultMessage="Type"
                            />
                            {sortBy === "accountType" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("class")}
                          >
                            <FormattedMessage
                              id="activity.Class"
                              defaultMessage="Class"
                            />
                            {sortBy === "class" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("profit")}
                          >
                            <FormattedMessage
                              id="table.head.Profit"
                              defaultMessage="Profit"
                            />
                            (%)
                            {sortBy === "profit" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("drawdown")}
                          >
                            <FormattedMessage
                              id="table.head.Drawdown"
                              defaultMessage="Drawdown"
                            />
                            (%)
                            {sortBy === "drawdown" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("equity")}
                          >
                            <FormattedMessage
                              id="table.head.Equity"
                              defaultMessage="Equity"
                            />
                            {sortBy === "equity" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("balance")}
                          >
                            <FormattedMessage
                              id="table.head.Balance"
                              defaultMessage="Balance"
                            />
                            {sortBy === "balance" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>

                          <th
                          // style={{ cursor: "pointer" }}
                          // onClick={() => setSorting("isActive")}
                          >
                            <FormattedMessage
                              id="Active"
                              defaultMessage="Active"
                            />
                            {sortBy === "isActive" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>

                          <th
                            style={{ cursor: "pointer" }}
                            onClick={() => setSorting("remarks")}
                          >
                            <FormattedMessage
                              id="table.head.Remark"
                              defaultMessage="Remark"
                            />
                            {sortBy === "remarks" ? (
                              sortOrder === "ASC" ? (
                                <BsSortDown />
                              ) : (
                                <BsSortUp />
                              )
                            ) : null}
                          </th>
                          <th>
                            <FormattedMessage
                              id="signalmodal.Setting"
                              defaultMessage="Setting"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ClassSignalLst.length &&
                          ClassSignalLst.map((i: any) => (
                            <tr>
                              <td>
                                <a href={i.mql5_link} target="_blank">
                                  {" "}
                                  {i.name}{" "}
                                </a>
                              </td>
                              <td>{i?.accountType}</td>
                              <td>{i?.class.name}</td>
                              <td>{i?.profit}</td>
                              <td>{i?.drawdown}</td>
                              <td>{i?.equity}</td>
                              <td>{i?.balance}</td>
                              <td>
                                {i?.isActive ? (
                                  <FormattedMessage
                                    id="page.Yes"
                                    defaultMessage="Yes"
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="page.No"
                                    defaultMessage="No"
                                  />
                                )}
                              </td>
                              <td>{i?.remarks}</td>
                              <td align="left">
                                <>
                                  {i.setting_link ? (
                                    <Button
                                      onClick={() => saveFile(i.setting_link)}
                                      style={{
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        textAlign: "left",
                                        padding: 0,
                                      }}
                                    >
                                      <FormattedMessage
                                        id="page.settings"
                                        defaultMessage="Setting "
                                      />
                                    </Button>
                                  ) : (
                                    <p
                                      style={{
                                        textAlign: "left",
                                        margin: 0,
                                      }}
                                    >
                                      {" "}
                                      N/A
                                    </p>
                                  )}
                                </>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {totalRecord > 10 && (
                    <>
                      <FormControl className="pagelimit">
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          <FormattedMessage
                            id="page_size"
                            defaultMessage="Page Size"
                          />
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={pageLimit}
                          onChange={(e: any) => {
                            setPageLimit(e.target.value);
                            setPageNumber(1);
                          }}
                        >
                          <FormControlLabel
                            value={10}
                            control={<Radio />}
                            label="10"
                          />
                          <FormControlLabel
                            value={20}
                            control={<Radio />}
                            label="20"
                          />
                          <FormControlLabel
                            value={50}
                            control={<Radio />}
                            label="50"
                          />
                        </RadioGroup>
                      </FormControl>
                      <div>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChange}
                          page={pageNumber - 1}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <p>{!isAPILoading && <NoDatFound />}</p>
              )}
            </Card.Body>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default ClassSingal;
