import { BiMessageEdit } from "react-icons/bi";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

interface ManagementRowRemarksTooltipProps {
  summary: string;
}

const ManagementRowRemarksTooltip = (
  props: ManagementRowRemarksTooltipProps,
) => {
  const { summary } = props;
  return (
    <td>
      <Tooltip
        arrow
        placement="right"
        title={<Typography color="inherit">{summary || ""}</Typography>}
      >
        <i>
          <BiMessageEdit size={20} />
        </i>
      </Tooltip>
    </td>
  );
};

export default ManagementRowRemarksTooltip;
