import { FormattedMessage } from "react-intl";

const NoDatFound = () => {
  return (
    <div className="no-data d-flex align-items-center justify-content-center flex-column p-4 p-md-5">
      <div className="icon mb-4">
        <i className="la la-5x la-clipboard-list text-muted"></i>
      </div>
      {/* <DataArray /> */}
      <h3 className="font-size-24 font-weight-600">
        <FormattedMessage
          id="page.Nodatafound"
          defaultMessage="No data found"
        />
      </h3>
      <p className="font-size-18">
        <FormattedMessage
          id="page.Nodatafound.subText"
          defaultMessage="There are no matching details"
        />
      </p>
    </div>
  );
};

export default NoDatFound;
