import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { FaTimesCircle } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import SignatureCanvas from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import { getRegistrationMaterial } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { UploadSignatureSchema } from "../../util/validationSchema";
import Loader from "../common/Loader";
import { getClassDetail } from "../../redux/action/classActions";
import { appIntl } from "../../util/intl";

const UploadSignature = ({
  onSignatureAdd,
  onCancel,
  showModal,
  signaturePath,
  classID,
}: any) => {
  let signatureRef: any;
  const signatureCanvasParentRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const [SelectedFiles, setSelectedFiles] = useState<Blob>();
  const [agree, setAgree] = useState(false);
  const onSaveLoading = useSelector(
    (state: Store) => state.classReducer.onSaveLoading,
  );
  const classDetail: any = useSelector(
    (state: Store) => state.classReducer.classDetail,
  );

  const formik = useFormik({
    initialValues: {
      signature: null,
    },
    validationSchema: UploadSignatureSchema,
    onSubmit: (values) => {
      values.signature = SelectedFiles;
      const aa =
        signatureRef !== null ? signatureRef.getCanvas().toDataURL() : "";
      if (aa.length > 0) {
        const a = new File([dataURItoBlob(aa)], "signature.png");
        values.signature = a;
        const formData = new FormData();
        formData.append("signature", values.signature);
        onSignatureAdd(formData);
        onCancel();
      }
    },
  });

  useEffect(() => {
    dispatch(getClassDetail(classID));
  }, []);

  useEffect(() => {
    dispatch(getRegistrationMaterial(false, "EN"));
  }, []);

  useEffect(() => {
    if (signatureCanvasParentRef.current && signatureRef) {
      const canvas = signatureRef.getCanvas();
      const width = signatureCanvasParentRef.current.offsetWidth;
      const height = signatureCanvasParentRef.current.offsetHeight;

      canvas.width = width;
      canvas.height = height;
    }
  }, [signatureCanvasParentRef, signatureRef]);

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  useEffect(() => {
    if (SelectedFiles) {
      formik.setFieldValue("signature", signatureRef.toDataURL());
    }
  }, [SelectedFiles]);

  const clear = () => {
    signatureRef.clear();
    formik.setFieldValue("signature", null);
    setSelectedFiles(undefined);
  };

  const checkboxChange = (e: any) => {
    if (e.target.checked) {
      setAgree(true);
    } else {
      setAgree(false);
    }
  };

  const isTermsAndConditionExist = () => {
    if (classDetail.terms_condtion_path != "") {
      return true;
    }
    return false;
  };

  const { touched, errors } = formik;

  return (
    <Modal centered size={"lg"} show={showModal} className="modal-content-md">
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage
            id="head.class.Resign"
            defaultMessage="Request Re-sign"
          />
        </h4>
        <button className="close" onClick={onCancel}>
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group show-uplod-img">
          <FormattedMessage
            id="head.class.oldSign"
            defaultMessage="Old Signature"
          />

          <div className="show-image" style={{ textAlign: "center" }}>
            <img style={{ width: "30%", height: "30%" }} src={signaturePath} />
          </div>
        </div>
        <Row className="form-row">
          <Col lg={12}>
            <div className="form-group">
              {/* <FormattedMessage
                id="head.sign"
                defaultMessage="Please sign below to confirm your agreement with the Terms of Service"
              />
              <br /> */}
              <FormattedMessage
                id="head.class.newSign"
                defaultMessage="New Signature"
              />
              <small>
                <FormattedMessage
                  id="head.subhead.sign"
                  defaultMessage="(This signature will also be used to verify your identity.)"
                />
              </small>
              <div
                ref={signatureCanvasParentRef}
                className="signature-canvas-bg"
                style={{
                  border: "1px solid #dfe7f3",
                }}
              >
                <SignatureCanvas
                  ref={(ref) => (signatureRef = ref)}
                  onEnd={() => {
                    formik.setFieldValue("signature", signatureRef.toDataURL());
                    formik.setFieldTouched("signature", true);
                  }}
                  penColor="blue"
                />
                {errors.signature && touched.signature && (
                  <div className="error">
                    {appIntl().formatMessage({ id: errors.signature })}
                  </div>
                )}
              </div>
            </div>
          </Col>
          {isTermsAndConditionExist() && (
            <Col>
              <div>
                <a target="_blank" href={classDetail.terms_condtion_path}>
                  <FormattedMessage
                    id="Click here for Terms and conditions"
                    defaultMessage="Click here for Terms and conditions"
                  />
                </a>
              </div>
              <div className="form-group m-0 mt-2">
                <label
                  className="control control-outline control-primary control--checkbox m-0"
                  htmlFor="as"
                >
                  <FormattedMessage
                    id="Iagreewithtermsandcondition"
                    defaultMessage=" I agree and confirm the relevant terms and conditions"
                  />
                  <input
                    type="checkbox"
                    onChange={(e) => checkboxChange(e)}
                    id="as"
                  />
                  <div className="control__indicator"></div>
                </label>
              </div>
              <div className="form-group mt-4">
                <Button
                  style={{
                    backgroundColor: "#68439a",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={() => clear()}
                >
                  <span
                    style={{ flexDirection: "row", display: "inline-flex" }}
                  >
                    <FormattedMessage
                      id="head.ResetSignature"
                      defaultMessage="Reset Signature"
                    />
                    {onSaveLoading && (
                      <div className="ml-2">
                        {" "}
                        <Loader />
                      </div>
                    )}
                  </span>
                </Button>
                <label className="d-block">&nbsp;</label>
              </div>
            </Col>
          )}
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onCancel} type="button">
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          className="btn btn-primary"
          onClick={(values: any) => {
            formik.handleSubmit(values);
          }}
          disabled={!agree && isTermsAndConditionExist()}
        >
          <FormattedMessage id="page.Add" defaultMessage="add" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadSignature;
