import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import Select from "react-select";
import { dispatch } from "../../redux";
import {
  addAccountList,
  getEAList,
  getTicketToolList,
  getTicketToolVersionList,
} from "../../redux/action";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { AddAccountSchema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../common/Loader";
import { TOOLS } from "../../util/constants/TabConstants";
import { FaRegTimesCircle, FaTimes, FaTimesCircle } from "react-icons/fa";

const AccountModal = ({ headText, onCancle, showModal, accountType }: any) => {
  const tools = useSelector(
    (state: Store) => state.ticketReducer.toolList,
  ) as any;
  const toolVersionList = useSelector(
    (state: Store) => state.ticketReducer.toolVersionList,
  ) as any;

  const EAList = useSelector(
    (state: Store) => state.expertAdvisorReducer.EAList,
  ) as any;

  const onSaveLoading = useSelector(
    (state: Store) => state.accountReducer.onSaveLoading,
  );
  const brokerList = useSelector(
    (state: Store) => state.accountReducer.brokerList,
  ) as Array<Object>;

  const [toolList, setToolList] = useState([]);
  const [buttonClick, setbuttonClick] = useState(false);
  const [selectedEAType, setSelectedEAType] = useState(null);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [selectedEAVersion, setSelectedEAVersion] = useState(null);
  const [EAVersion, setEAVersion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [versionIsLoading, setVersionIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      accountNumber: null,
      remark: "",
      EA_version: [],
      EA_type: -1,
      broker: 1,
      type: accountType,
    },
    validationSchema: AddAccountSchema,
    onSubmit: (values) => {
      const ac = {
        accountNumber: values.accountNumber,
        remark: values.remark,
        EA_version: values.EA_version,
        type: values.type,
        broker: values.broker,
      };
      dispatch(addAccountList(true, ac));
      setbuttonClick(true);
    },
  });

  useEffect(() => {
    if (!onSaveLoading && buttonClick) {
      onCancle();
      setbuttonClick(false);
    }
  }, [onSaveLoading]);

  useEffect(() => {
    if (toolVersionList?.length > 0) {
      setEAVersion(toolVersionList);
      setVersionIsLoading(false);
    }
  }, [toolVersionList]);

  useEffect(() => {
    setEAVersion([]);
    dispatch(getTicketToolList(false, 0));
    dispatch(getEAList(false, 0));
  }, []);

  useEffect(() => {
    // const tl: any = [];
    // tools.map((i: any) => {
    //   if (i.type === TOOLS.EXPERT_ADVISOR) {
    //     if (EAList?.length > 0) {
    //       EAList.map((j: any) => {
    //         if (j.id === i.id) {
    //           tl.push(i);
    //         }
    //       });
    //     }
    //   }
    // });
    setToolList(EAList);
    if (EAList.length > 0) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [tools, EAList]);

  const onToolChange = (option: any) => {
    setSelectedEAType(option);
    setSelectedEAVersion(null);
    formik.setFieldValue("EA_type", option.id);
    dispatch(getTicketToolVersionList(false, parseInt(option.id), 0));
    setVersionIsLoading(true);
  };
  const onBrokerChange = (option: any) => {
    setSelectedBroker(option);
    // setSelectedEAVersion(null);
    // formik.setFieldValue("EA_type", option.id);
    // dispatch(getTicketToolVersionList(false, parseInt(option.id), 0));
  };

  const onToolVersionChange = (option: any) => {
    setSelectedEAVersion(option);
    formik.setFieldValue(
      "EA_version",
      option.map((i: any) => i.id),
    );
  };

  const { errors, touched } = formik;

  return (
    <Modal centered size={"lg"} show={showModal} className="modal-content-md">
      <Modal.Header>
        <h4 className="modal-title">
          <FormattedMessage id={headText} defaultMessage="Add Account" />{" "}
        </h4>
        <button className="close" onClick={onCancle}>
          <FaTimesCircle />
        </button>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="form-group">
            <FormattedMessage
              id="accountmodal.Expert"
              defaultMessage=" Expert Advisor"
            />
            <Select
              classNamePrefix="react-select-dropdown"
              value={selectedEAType}
              onBlur={() => formik.setFieldTouched("EA_type", true)}
              onChange={onToolChange}
              options={toolList}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.id}
              placeholder={useIntl().formatMessage({
                id: "select.ExpertAdvisor",
                defaultMessage: "Expert Advisor",
              })}
              menuPortalTarget={document.body}
              isLoading={isLoading}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {errors.EA_type && touched.EA_type && (
              <div className="error">
                <FormattedMessage
                  id={errors.EA_type.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <FormattedMessage
              id="accountmodal.Version"
              defaultMessage="Version:"
            />
            <Select
              classNamePrefix="react-select-dropdown"
              value={selectedEAVersion}
              onBlur={() => formik.setFieldTouched("EA_version", true)}
              onChange={onToolVersionChange}
              name="EA_version"
              options={EAVersion}
              getOptionLabel={(option: any) => option.version}
              getOptionValue={(option: any) => option.id}
              isMulti
              placeholder={useIntl().formatMessage({
                id: "select.Version",
                defaultMessage: "Version",
              })}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              isLoading={versionIsLoading}
            />
            {errors.EA_version && touched.EA_version && (
              <div className="error">
                <FormattedMessage
                  id={errors.EA_version.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>
        </>

        {/* <div className="form-group">
          <FormattedMessage
            id="table.head.broker_name"
            defaultMessage=" Expert Advisor"
          />
          <Select
            value={selectedBroker}
            onBlur={() => formik.setFieldTouched("broker", true)}
            onChange={(option: any) => {
              setSelectedBroker(option);
              formik.setFieldValue("broker", option.id);
            }}
            options={brokerList}
            getOptionLabel={(option: any) => option.brokerName}
            getOptionValue={(option: any) => option.id}
            placeholder={useIntl().formatMessage({
              id: "select.broker",
              defaultMessage: "Expert Advisor",
            })}
          />
          {errors.broker && touched.broker && (
            <div className="error">
              <FormattedMessage
                id={errors.broker.toString()}
                defaultMessage="require"
              />
            </div>
          )}
        </div> */}

        <div className="form-group">
          <FormattedMessage
            id="accountmodal.Account"
            defaultMessage="Account Number*"
          />
          <input
            type="text"
            name="accountNumber"
            className="form-control"
            value={formik.values.accountNumber}
            onChange={(e: any) => {
              if (e.target.value >= 0) {
                formik.handleChange(e);
              } else {
                return false;
              }
            }}
            maxLength={15}
            onBlur={formik.handleBlur}
            placeholder={useIntl().formatMessage({
              id: "select.AccountNumber",
              defaultMessage: "Account Number",
            })}
          />
          {errors.accountNumber && touched.accountNumber && (
            <div className="error">
              <FormattedMessage
                id={errors.accountNumber.toString()}
                defaultMessage="require"
              />
            </div>
          )}
        </div>
        <div className="form-group">
          <FormattedMessage
            id="accountmodal.Remarks"
            defaultMessage="Remarks"
          />
          <input
            type="text"
            name="remark"
            className="form-control"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={useIntl().formatMessage({
              id: "select.Remark",
              defaultMessage: "Remark",
            })}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onCancle} type="button">
          <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />
        </button>
        <button
          className="btn btn-primary"
          onClick={(values: any) => formik.handleSubmit(values)}
          disabled={onSaveLoading}
        >
          <FormattedMessage id="page.Add" defaultMessage="Add" />
          <span style={{ flexDirection: "row", display: "inline-flex" }}>
            {onSaveLoading && (
              <div className="ml-2">
                {" "}
                <Loader />
              </div>
            )}
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AccountModal;
