const Loader = () => {
  return (
    <div className="item loading-1 text-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em">
        <circle cx="0.6em" cy="0.6em" r="0.5em" className="circle" />
      </svg>
    </div>
  );
};

export default Loader;
