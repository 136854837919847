import { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import Select from "react-select";
import { getRegistrationMaterial, setRegisterSteps } from "../../redux/action";
import { useFormik } from "formik";
import FileUploadImage from "../../Components/common/MediaUpload/FileUploadImage";
import { _RegisterUploadMedia } from "../../service/MediaUpload/classes.service";
import { RegisterStep7Schema } from "../../util/validationSchema";
import { Store } from "../../redux/Actions";
import { FormattedMessage, useIntl } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FaTrash } from "react-icons/fa";

const RegisterStep7 = ({ onBack }: any) => {
  const dispatch = useDispatch();
  const [fileUploadURL, setFileUploadURL] = useState<any>();

  const local = useSelector((state: Store) => state.userDataReducer.local);
  const paymentTypeLst = useSelector(
    (state: Store) => state.registerReducer.paymentTypeLst,
  ) as Array<Object>;
  const [selectedPaymentType, setSelectedPaymentType] = useState<any>(null);
  const [fileLoading, setFileLoading] = useState(false);

  const users = useSelector((state: Store) => state.userDataReducer.userObject);

  useEffect(() => {
    setFileUploadURL(users.slipPath);
    (paymentTypeLst || []).map((c: any) => {
      if (c.paymentTypeId == users.paymentType) {
        setSelectedPaymentType(c);
        formik.setFieldValue("paymentType", c.paymentTypeId);
      }
    });
  }, [users, local, paymentTypeLst]);

  useEffect(() => {
    setSelectedPaymentType(null);
    formik.setFieldValue("paymentType", 0);
  }, [local]);

  useEffect(() => {
    dispatch(getRegistrationMaterial(false, local));
  }, [local]);

  const formik = useFormik({
    initialValues: {
      paymentType: users?.paymentType || 0,
      slipPath: users?.slipPath || "",
    },
    validationSchema: RegisterStep7Schema,
    onSubmit: (values) => {
      dispatch(setRegisterSteps(6, values));
    },
  });
  const { touched, errors } = formik;

  const handleDrop = async (acceptedFiles: any) => {
    setFileLoading(true);

    const { fileUploadResponse, error }: any = await _RegisterUploadMedia(
      acceptedFiles[0] as File,
    );

    if (!error) {
      setFileUploadURL(fileUploadResponse);
      formik.setFieldValue("slipPath", fileUploadResponse);
    }

    setFileLoading(false);
  };

  const onDeleteImage = async () => {
    formik.setFieldValue("slipPath", "");
    setFileUploadURL(undefined);
  };

  return (
    <>
      <div className="mb-4">
        <div className="d-flex flex-wrap justify-content-between border-bottom pb-4 mb-4">
          <h1 className="h5 font-weight-600 m-0">
            <FormattedMessage
              id="register.setp7"
              defaultMessage="Step 7: Upload Certificate of Deposit "
            />{" "}
          </h1>
          <button
            type="button"
            onClick={onBack}
            className="btn btn-primary btn btn-primary"
          >
            <BsChevronLeft />{" "}
            <FormattedMessage id="register.step7.Back" defaultMessage="Back" />{" "}
          </button>
        </div>
        {fileLoading && <CenterLoader />}

        <Row className="form-row">
          <Col>
            <div className="form-group">
              <label>
                <FormattedMessage
                  id="register.step7.Payment"
                  defaultMessage="Payment Method *"
                />{" "}
              </label>
              <Select
                classNamePrefix="react-select-dropdown"
                value={selectedPaymentType}
                onBlur={() => formik.setFieldTouched("paymentType", true)}
                onChange={(option: any) => {
                  formik.setFieldValue("paymentType", option?.paymentTypeId);
                  setSelectedPaymentType(option);
                }}
                options={paymentTypeLst}
                name="paymentType"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.paymentTypeId}
                placeholder={useIntl().formatMessage({
                  id: "register.step6.SelectPaymentType",
                  defaultMessage: "Select Payment Type",
                })}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.paymentType && touched.paymentType && (
                <div className="error">
                  <FormattedMessage
                    id={errors.paymentType.toString()}
                    defaultMessage="error"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="form-row">
          <Col>
            <p>
              {" "}
              <FormattedMessage
                id="register.step7.Select.CorrectPayment"
                defaultMessage="* Please select the correct payment method. If you choose the wrong one, we may delay reviewing your registration."
              />
            </p>
          </Col>
        </Row>
        <Row className="form-row">
          <Col>
            <div className="form-group">
              <label></label>
              {!fileUploadURL && (
                <>
                  <label>
                    <FormattedMessage
                      id="register.step7.Selectfile"
                      defaultMessage="Select file * "
                    />
                  </label>
                  <FileUploadImage handleDrop={handleDrop} optional={false} />
                </>
              )}
              {fileUploadURL && (
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <img
                    style={{
                      textAlign: "center",
                      border: "2px lightgrey solid",
                      width: "250px",
                      borderRadius: "1%",
                    }}
                    src={fileUploadURL}
                    alt="Uploaded Record Of Deposit"
                  />
                  <button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => onDeleteImage()}
                  >
                    <i className="text-danger ">
                      <FaTrash />{" "}
                    </i>
                  </button>
                </div>
              )}
              {errors.slipPath && touched.slipPath && (
                <div className="error">
                  <FormattedMessage
                    id={errors.slipPath.toString()}
                    defaultMessage="error"
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="form-row">
          <Col>
            <p className="mb-2">
              <b>
                <FormattedMessage
                  id="register.step7.Notes"
                  defaultMessage="Notes:"
                />{" "}
              </b>
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step7.Notes1"
                defaultMessage="1. We currently only support payment methods by bank transfer,paypal and FPS ."
              />
            </p>
            <p className="mb-2">
              <FormattedMessage
                id="register.step7.Notes2"
                defaultMessage="2. Please upload a clear payment receipt."
              />
            </p>
            <p className="mb-0">
              <FormattedMessage
                id="register.step7.Notes3"
                defaultMessage="3. If you have any questions, please contact the Customer Service Officer for assistance."
              />
            </p>
          </Col>
        </Row>
      </div>
      <div className="text-right mt-auto">
        <Button
          className="btn btn-primary"
          onClick={() => formik.handleSubmit()}
        >
          {" "}
          <FormattedMessage id="register.step7.Btn" defaultMessage="Next" />
          <BsChevronRight />{" "}
        </Button>
      </div>
    </>
  );
};

export default RegisterStep7;
