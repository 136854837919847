import { FormattedMessage } from "react-intl";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../redux/Actions";

type IToolFilter = {
  onChangeToolFilterType: (event) => void;
  onChangeFilteringQuery: (event) => void;
};

const options = [
  {
    value: "name",
    label: "Name",
  },
  {
    value: "description",
    label: "Description",
  },
];

const optionsChinese = [
  {
    value: "name",
    label: "名稱",
  },
  {
    value: "description",
    label: "描述",
  },
];

export const ToolFilterFunction = (
  toolFilterTypeState: "name" | "description",
  toolList: any[],
  filteringQuery: string,
) => {
  return toolList.filter((tool) => {
    if (toolFilterTypeState === "name") {
      return tool.name.toLowerCase().includes(filteringQuery.toLowerCase());
    } else {
      return tool.info.toLowerCase().includes(filteringQuery.toLowerCase());
    }
  });
};

export const ToolFilter = (prop: IToolFilter) => {
  const locale = useSelector((state: Store) => state.userDataReducer.local);

  const { onChangeToolFilterType, onChangeFilteringQuery } = prop;

  return (
    <div style={{ marginLeft: "30px", position: "relative" }}>
      <label style={{ top: "8px", position: "absolute" }}>
        <FormattedMessage id="Filter" defaultMessage="Filter" />
        {": "}
      </label>
      <input
        onChange={(e) => onChangeFilteringQuery(e.target.value)}
        type="text"
        defaultValue=""
        style={{
          maxWidth: "220px",
          paddingLeft: "39px",
          borderRadius: "0px",
          border: "none",
          borderBottom: "1px solid",
          display: "inline-block",
          marginBottom: "10px",
        }}
        className="form-control"
      />
      <div
        style={{ width: "200px", marginLeft: "10px", display: "inline-block" }}
      >
        <Select
          key={locale}
          defaultValue={locale !== "zh" ? options[0] : optionsChinese[0]}
          classNamePrefix="react-select-dropdown"
          onChange={(e) => onChangeToolFilterType(e.value)}
          options={locale !== "zh" ? options : optionsChinese}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />
      </div>
    </div>
  );
};

export default ToolFilter;
