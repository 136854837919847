/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  READ_ANNOUNCEMENT,
  GET_ANNOUNCEMENT_LIST,
  GET_ANNOUNCEMENT_DETAILS,
  ADD_ANNOUNCEMENT,
  ADD_ANNOUNCEMENT_COMMENT,
  GET_ANNOUNCEMENT_COMMENT,
  MARKED_ANNOUNCEMENT_READ,
  GET_UNREAD_ANNOUNCEMENT_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { getToken, setToken } from "../../util/utils";
import {
  GET_ANNOUNCEMENT_LIST_URL,
  ADD_ANNOUNCEMENT_COMMENT_LIST_URL,
  GET_ANNOUNCEMENT_COMMENT_LIST_URL,
  GET_ANNOUNCEMENT_DETAIL_LIST_URL,
  GET_UNREAD_ANNOUNCEMENT_LIST_URL,
  MARKED_ANNOUNCEMENT_AS_READ_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";

export const getAnnouncements =
  (
    showToast = false,
    pageNumber: number,
    AnnouncementsSort: "ASC" | "DESC",
    AnnouncementsSortedBy: "createdAt" | "name",
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      getAnnouncementsInit(
        showToast,
        pageNumber,
        AnnouncementsSort,
        AnnouncementsSortedBy,
        pageLimit,
      ),
    );
  };

export const getAnnouncementsInit =
  (
    showToast: boolean,
    pageNumber: number,
    AnnouncementsSort: "ASC" | "DESC",
    AnnouncementsSortedBy: "createdAt" | "name",
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_LIST.GET_ANNOUNCEMENT_LIST_INITLIZATION,
    });
    // announcement/user/list?sort=
    apiCall(
      `${GET_ANNOUNCEMENT_LIST_URL}?sort=${AnnouncementsSort}&sortBy=${AnnouncementsSortedBy}&page=${pageNumber}&perPage=${pageLimit}`,
      (res: Object) => dispatch(getAnnouncementsSuccess(res)),
      (err: any) => {
        dispatch(getAnnouncementsError());
      },
      {},
      METHOD.GET,
      {
        showToast: showToast,
      },
    );
  };

export const getAnnouncementsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_LIST.GET_ANNOUNCEMENT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getAnnouncementsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_LIST.GET_ANNOUNCEMENT_LIST_ERORR,
    });
  };

export const getAnnouncementDetails =
  (
    showToast = false,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAnnouncementDetailsInit(showToast, id));
  };

export const getAnnouncementDetailsInit =
  (
    showToast: boolean,
    id: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_DETAILS.GET_ANNOUNCEMENT_DETAILS_INITIALIZATION,
    });
    apiCall(
      GET_ANNOUNCEMENT_DETAIL_LIST_URL + "/" + id,
      (res: Object) => dispatch(getAnnouncementDetailsSuccess(res)),
      (err: any) => dispatch(getAnnouncementDetailsError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getAnnouncementDetailsSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_DETAILS.GET_ANNOUNCEMENT_DETAILS_SUCCESS,
      payload: res.data,
    });
  };

export const getAnnouncementDetailsError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_DETAILS.GET_ANNOUNCEMENT_DETAILS_ERROR,
    });
  };

export const addAnnouncement =
  (
    showToast = true,
    announcementData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(addAnnouncementInit(showToast, announcementData));
  };

export const addAnnouncementInit =
  (
    showToast = true,
    announcementData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT.ADD_ANNOUNCEMENT_INITIALIZATION,
    });
    apiCall(
      GET_ANNOUNCEMENT_LIST_URL,
      announcementData,
      (res: Object) => dispatch(addAnnouncementSuccess(res)),
      (err: any) => dispatch(addAnnouncementError()),
      METHOD.POST,
      { showToast },
    );
  };

export const addAnnouncementSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT.ADD_ANNOUNCEMENT_SUCCESS,
    });
    dispatch(getAnnouncements(false, 1, "DESC", "createdAt"));
  };

export const addAnnouncementError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT.ADD_ANNOUNCEMENT_ERROR,
    });
  };

export const readAnnouncement =
  (
    showToast = true,
    announcementData: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(readAnnouncementInit(showToast, announcementData));
  };

export const readAnnouncementInit =
  (
    showToast = true,
    announcementData: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: READ_ANNOUNCEMENT.READ_ANNOUNCEMENT_INITIALIZATION,
    });
    apiCall(
      MARKED_ANNOUNCEMENT_AS_READ_URL,
      (res: Object) => dispatch(readAnnouncementSuccess(res)),
      (err: any) => dispatch(readAnnouncementError()),
      { announcementId: announcementData },
      METHOD.PUT,
      { showToast },
    );
  };

export const readAnnouncementSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: READ_ANNOUNCEMENT.READ_ANNOUNCEMENT_SUCCESS,
    });
  };

export const readAnnouncementError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: READ_ANNOUNCEMENT.READ_ANNOUNCEMENT_ERROR,
    });
  };

export const addAnnouncementComment =
  (
    showToast = true,
    announcementData: any,
    pageNumber: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(
      addAnnouncementCommentInit(showToast, announcementData, pageNumber),
    );
  };

export const addAnnouncementCommentInit =
  (
    showToast = true,
    announcementData: any,
    pageNumber: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT_COMMENT.ADD_ANNOUNCEMENT_COMMENT_INITIALIZATION,
    });
    apiCall(
      ADD_ANNOUNCEMENT_COMMENT_LIST_URL,
      (res: Object) =>
        dispatch(
          addAnnouncementCommentSuccess(
            res,
            announcementData.announcement,
            pageNumber,
          ),
        ),
      (err: any) => dispatch(addAnnouncementCommentError()),
      announcementData,
      METHOD.POST,
      { showToast },
    );
  };

export const addAnnouncementCommentSuccess =
  (
    res: any,
    id: any,
    pageNumber,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT_COMMENT.ADD_ANNOUNCEMENT_COMMENT_SUCCESS,
    });
    dispatch(getAnnouncementComment(false, id, pageNumber));
  };

export const addAnnouncementCommentError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: ADD_ANNOUNCEMENT_COMMENT.ADD_ANNOUNCEMENT_COMMENT_ERROR,
    });
  };

export const getAnnouncementComment =
  (
    showToast = true,
    id: any,
    pageNumber: any,
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getAnnouncementCommentInit(showToast, id, pageNumber, pageLimit));
  };

export const getAnnouncementCommentInit =
  (
    showToast = true,
    id: any,
    pageNumber: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_COMMENT.GET_ANNOUNCEMENT_COMMENT_INITIALIZATION,
    });
    apiCall(
      `${GET_ANNOUNCEMENT_COMMENT_LIST_URL}${id}?page=${pageNumber}&perPage=${pageLimit}`,
      (res: Object) => dispatch(getAnnouncementCommentSuccess(res)),
      (err: any) => dispatch(getAnnouncementCommentError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getAnnouncementCommentSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_COMMENT.GET_ANNOUNCEMENT_COMMENT_SUCCESS,
      payload: res,
    });
  };

export const getAnnouncementCommentError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_COMMENT.GET_ANNOUNCEMENT_COMMENT_ERROR,
    });
  };
export const getUnreadAnnouncement =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUnreadAnnouncementInit(showToast));
  };

export const getUnreadAnnouncementInit =
  (showToast: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UNREAD_ANNOUNCEMENT_LIST.GET_UNREAD_ANNOUNCEMENT_LIST_INITIALIZATION,
    });

    apiCall(
      `${GET_UNREAD_ANNOUNCEMENT_LIST_URL}?sort=DESC&sortBy=createdAt`,
      (res: Object) => dispatch(getUnreadAnnouncementSuccess(res)),
      (err: any) => {
        dispatch(getUnreadAnnouncementError());
      },
      {},
      METHOD.GET,
      {
        showToast: showToast,
      },
    );
  };

export const getUnreadAnnouncementSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    setToken("unreadAnnouncement", JSON.stringify(res.data));
    dispatch({
      type: GET_UNREAD_ANNOUNCEMENT_LIST.GET_UNREAD_ANNOUNCEMENT_LIST_SUCCESS,
      payload: res,
    });
  };

export const getUnreadAnnouncementError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_UNREAD_ANNOUNCEMENT_LIST.GET_UNREAD_ANNOUNCEMENT_LIST_ERROR,
    });
  };
export const markAnnouncementAsRead =
  (
    showToast = false,
    announcements: Array<number>,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(markAnnouncementAsReadInit(showToast, announcements));
  };

export const markAnnouncementAsReadInit =
  (
    showToast: boolean,
    announcements: Array<number>,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: MARKED_ANNOUNCEMENT_READ.MARKED_ANNOUNCEMENT_READ_INITIALIZATION,
    });
    apiCall(
      MARKED_ANNOUNCEMENT_AS_READ_URL,
      (res: Object) => dispatch(markAnnouncementAsReadSuccess(res)),
      (err: any) => {
        dispatch(markAnnouncementAsReadError());
      },
      { announcements },
      METHOD.PUT,
      {
        showToast: showToast,
      },
    );
  };

export const markAnnouncementAsReadSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: MARKED_ANNOUNCEMENT_READ.MARKED_ANNOUNCEMENT_READ_SUCCESS,
      payload: res,
    });
  };

export const markAnnouncementAsReadError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: MARKED_ANNOUNCEMENT_READ.MARKED_ANNOUNCEMENT_READ_ERROR,
    });
  };
