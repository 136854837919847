import { useEffect, useState } from "react";
import { Card, Button, Row, Col, Breadcrumb } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { dispatch } from "../../redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Store } from "../../redux/Actions";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import CurrentVersion from "./CurrentVersion";
import ArchivedVersion from "./ArchivedVersion";
import { getLibraryVersionList } from "../../redux/action";
import { Link } from "react-router-dom";
import NoDatFound from "../../Components/common/NoDatFound";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import { FormattedMessage } from "react-intl";
import { findLatestVersionDownloadPath, saveFile } from "../../util/utils";
import Lib from "../../assets/img/Library.svg";

export const LibraryVersionDetail = () => {
  const libraryDetail = useSelector(
    (state: Store) => state.libararyReducers.libraryDetail,
  ) as any;
  const currentData = useSelector(
    (state: Store) => state.libararyReducers.currentVData,
  ) as Array<any>;
  const archiveData = useSelector(
    (state: Store) => state.libararyReducers.archiveVData,
  ) as Array<any>;

  const isLoading = useSelector(
    (state: Store) => state.libararyReducers.isLoading,
  ) as any;

  const useParam = useParams();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const libraryAllData = [...currentData, ...archiveData].sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );

  const latestToolDownloadIndex = findLatestVersionDownloadPath(
    libraryAllData,
    true,
  );
  const latestManualDownloadIndex = findLatestVersionDownloadPath(
    libraryAllData,
    false,
  );

  useEffect(() => {
    const { id } = useParam;
    const { state }: any = location;
    const isGranted = state?.isGranted || false;

    if (id && parseInt(id) > 0) {
      dispatch(getLibraryVersionList(false, id, isGranted));
    }
  }, []);

  const setAciveTab = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  return (
    <div className="content-wrapper">
      <div className="content">
        {isLoading && <CenterLoader />}
        <section className="page-content container-fluid">
          <Breadcrumb>
            <Breadcrumb.Item
              style={{ background: "transparent", verticalAlign: "middle" }}
            >
              <Link to="/libraries">
                {" "}
                <span style={{ verticalAlign: "middle" }}>
                  {" "}
                  <BsChevronLeft />
                  <FormattedMessage
                    id="library.head"
                    defaultMessage="Library"
                  />
                </span>
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          {libraryDetail && Object.keys(libraryDetail).length > 0 ? (
            <Row>
              <Col sm={6} md={5} lg={3}>
                <Card style={{ width: "100%" }}>
                  <Card.Body>
                    <Row className="mb-3">
                      <Col style={{ textAlign: "center" }}>
                        <h4> {libraryDetail?.name}</h4>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col style={{ textAlign: "center" }}>
                        <div>
                          {libraryDetail?.image_path ? (
                            <img
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              src={libraryDetail?.image_path || Lib}
                            />
                          ) : (
                            <i>
                              {" "}
                              <img
                                src={Lib}
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                              />{" "}
                            </i>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ textAlign: "center" }}>
                        <h4 style={{ fontWeight: "500" }}>
                          USD$ {libraryDetail?.sellingPrice}
                        </h4>
                      </Col>
                    </Row>

                    <Row className="mb-1">
                      <Col style={{ textAlign: "left" }}>
                        <p style={{ lineHeight: "1.5" }}>
                          {libraryDetail.info}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col style={{ textAlign: "left" }}>
                        {libraryAllData[latestToolDownloadIndex]
                          ?.download_path ? (
                          <Button
                            onClick={() =>
                              saveFile(
                                libraryAllData[latestToolDownloadIndex]
                                  ?.download_path,
                              )
                            }
                            style={{ width: "100%", whiteSpace: "normal" }}
                            size="sm"
                          >
                            <FormattedMessage
                              id="head.DownloadLatestTool"
                              defaultMessage="Download Latest Tool"
                            />
                          </Button>
                        ) : (
                          <Button
                            style={{ width: "100%", whiteSpace: "normal" }}
                            size="sm"
                            disabled
                          >
                            <FormattedMessage
                              id="head.DownloadLatestTool"
                              defaultMessage="Download Latest Tool"
                            />
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-1">
                      <Col style={{ textAlign: "left" }}>
                        {libraryAllData[latestManualDownloadIndex]
                          ?.manual_path ? (
                          <Button
                            onClick={() =>
                              saveFile(
                                libraryAllData[latestManualDownloadIndex]
                                  ?.manual_path,
                              )
                            }
                            style={{ width: "100%", whiteSpace: "normal" }}
                            size="sm"
                          >
                            <FormattedMessage
                              id="head.DownloadLatestManual"
                              defaultMessage="Download Manual Tool"
                            />
                          </Button>
                        ) : (
                          <Button
                            style={{ width: "100%", whiteSpace: "normal" }}
                            size="sm"
                            hidden
                          >
                            <FormattedMessage
                              id="head.DownloadLatestManual"
                              defaultMessage="Download Manual Tool"
                            />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} md={7} lg={9}>
                <Card style={{ width: "100%" }}>
                  <Card.Body>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        padding: "0px",
                      }}
                      style={{ padding: "0px" }}
                      className={classes.tabs}
                    >
                      <Tabs
                        value={activeTab}
                        onChange={setAciveTab}
                        className={classes.tabs}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          label={
                            <FormattedMessage
                              id="tab.CURRENT"
                              defaultMessage="Current"
                            />
                          }
                          {...a11yProps(0)}
                        />
                        <Tab
                          label={
                            <FormattedMessage
                              id="tab.ARCHIVE"
                              defaultMessage="Archieved"
                            />
                          }
                          {...a11yProps(1)}
                        />
                      </Tabs>
                    </Box>
                    <TabPanel value={activeTab} index={0}>
                      <CurrentVersion
                        versionLst={currentData}
                        name={libraryDetail.name}
                      />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                      <ArchivedVersion
                        versionLst={archiveData}
                        name={libraryDetail.name}
                      />
                    </TabPanel>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            <p style={{ lineHeight: "1.5" }}>{!isLoading && <NoDatFound />}</p>
          )}
        </section>
      </div>
    </div>
  );
};

export default LibraryVersionDetail;

const useStyles = makeStyles({
  tabs: {
    "& .MuiBox-root": {
      padding: "0px",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f57c51",
      height: 1,
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#f57c51",
      BsBorderBottom: "1px solid",
    },
    "& .MuiTypography-root": {
      lineHeight: "1.5",
    },
  },
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};
