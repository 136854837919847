/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_SCRIPT_LIST,
  GET_SCRIPT_VERSION_LIST,
  GET_UNAUTHORIZED_SCRIPT_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_SCRIPT_LIST_URL,
  GET_SCRIPT_VERSION_LIST_URL,
  GET_UNAUTHORIZED_SCRIPT_LIST_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";

//Get Admin Roles List
export const getScriptList =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getScriptListInit(showToast, sort));
  };

export const getScriptListInit =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_SCRIPT_LIST.GET_SCRIPT_LIST_INITIALIZATION,
    });
    apiCall(
      GET_SCRIPT_LIST_URL + `?sort=${sort.order}&sortBy=${sort.by}`,
      (res: Object) => dispatch(getScriptListSuccess(res)),
      (err: any) => dispatch(getScriptListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getScriptListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_SCRIPT_LIST.GET_SCRIPT_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getScriptListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_SCRIPT_LIST.GET_SCRIPT_LIST_ERROR,
    });
  };

export const getScriptVersionList =
  (
    showToast = true,
    eaId: string,
    isGranted: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getScriptVersionListInit(showToast, eaId, isGranted));
  };

export const getScriptVersionListInit =
  (
    showToast = true,
    eaId: string,
    isGranted: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      GET_SCRIPT_VERSION_LIST_URL + eaId,
      (res: Object) => dispatch(getScriptVersionListSuccess(res)),
      (err: any) => dispatch(getScriptVersionListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getScriptVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_SUCCESS,
      payload: res,
    });
  };

export const getScriptVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_SCRIPT_VERSION_LIST.GET_SCRIPT_VERSION_LIST_ERROR,
    });
  };

export const getUnauthorizedScriptList =
  (
    showToast = true,
    sort: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUnauthorizedScriptListInit(showToast, sort));
  };

export const getUnauthorizedScriptListInit =
  (showToast = true, sort): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UNAUTHORIZED_SCRIPT_LIST.GET_UNAUTHORIZED_SCRIPT_LIST_INITIALIZATION,
    });
    apiCall(
      GET_UNAUTHORIZED_SCRIPT_LIST_URL +
        `?sort=${sort.order}&sortBy=${sort.by}`,
      (res: Object) => dispatch(getUnauthorizedScriptListSuccess(res)),
      (err: any) => dispatch(getUnauthorizedScriptListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getUnauthorizedScriptListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UNAUTHORIZED_SCRIPT_LIST.GET_UNAUTHORIZED_SCRIPT_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getUnauthorizedScriptListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_UNAUTHORIZED_SCRIPT_LIST.GET_UNAUTHORIZED_SCRIPT_LIST_ERROR,
    });
  };
