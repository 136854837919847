/* eslint-disable no-case-declarations */
import { GET_POINTS_LIST, REDEEM_ACCOUNT } from "../constants/action-types";
import { PointData } from "../Actions";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ActionTypes } from "../action";

const initialState = {
  progress: false,
  isLoading: false,
  onSaveLoading: false,
  pointLst: [],
  totalRecord: 0,
};

const reducer = persistReducer(
  { storage, key: "basecode-demo", whitelist: ["authToken"] },
  (state: PointData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case GET_POINTS_LIST.GET_POINTS_LIST_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
          pointLst: [],
          totalRecord: 0,
        };

      case GET_POINTS_LIST.GET_POINTS_LIST_SUCCESS:
        const { data, totalCount }: any = action.payload;

        return {
          ...state,
          pointLst: data,
          isLoading: false,
          totalRecord: totalCount,
        };

      case GET_POINTS_LIST.GET_POINTS_LIST_ERROR:
        return {
          ...state,
          isLoading: false,
          totalRecord: 0,
        };

      case REDEEM_ACCOUNT.REDEEM_ACCOUNT_INITIALIZATION:
        return {
          ...state,
          isLoading: true,
        };

      case REDEEM_ACCOUNT.REDEEM_ACCOUNT_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

      case REDEEM_ACCOUNT.REDEEM_ACCOUNT_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      default:
        return state;
    }
  },
);

export default reducer;
