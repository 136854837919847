import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_ALL_VPS_MACHINES_LIST,
  GET_REMINDER_VPS_EXPIRE,
  UPDATE_VPS_MACHINE_DETAIL,
  UPDATE_VPS_MACHINE_PASSWORD,
  UPDATE_VPS_MACHINE_STATUS,
} from "../../service/apiEndPoints";
import { VPS } from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";

export const getVPSExpire =
  (showToast = false): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getVpsExpireInit(showToast));
  };

export const getVpsExpireInit =
  (showToast: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS.VPS_INITLIZATION,
    });
    apiCall(
      GET_REMINDER_VPS_EXPIRE,
      (res: Object) => dispatch(getVpsExpireSuccess(res)),
      (err: any) => dispatch(getVpsExpireError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getVpsExpireSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS.VPS_SUCCESS,
      payload: res.data,
    });
  };

export const getVpsExpireError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: VPS.VPS_ERORR,
    });
  };

export const getallVpsMachinesList =
  (
    showToast = false,
    pageNumber = 1,
    sort = { order: "DESC", by: "createdAt" },
    pageLimit = 10,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getallVpsMachinesListInit(showToast, pageNumber, sort, pageLimit));
  };

export const getallVpsMachinesListInit =
  (
    showToast: boolean,
    pageNumber: any,
    sort: any,
    pageLimit: number,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS.ALL_VPS_MACHINES_LIST_INITIALIZATION,
    });
    apiCall(
      `${GET_ALL_VPS_MACHINES_LIST}?sort=${sort.order}&sortBy=${sort.by}&page=${pageNumber}&perPage=${pageLimit}`,
      (res: Object) => dispatch(getallVpsMachinesListSuccess(res)),
      (err: any) => dispatch(getallVpsMachinesListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getallVpsMachinesListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS.ALL_VPS_MACHINES_LIST_SUCCESS,
      payload: res,
    });
  };

export const getallVpsMachinesListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: VPS.ALL_VPS_MACHINES_LIST_ERROR,
    });
  };

export const updateVpsStatus =
  (
    showToast = true,
    data: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(updateVpsStatusInit(showToast, data));
  };

export const updateVpsStatusInit =
  (
    showToast = false,
    data: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS.UPDATE_VPS_MACHINE_STATUS_INITIALIZATION,
    });
    apiCall(
      UPDATE_VPS_MACHINE_STATUS,
      (res: any) => dispatch(updateVpsStatusSuccess(res)),
      (err: any) => dispatch(updateVpsStatusError()),
      data,
      METHOD.PUT,
      { showToast },
    );
  };

export const updateVpsStatusSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS.UPDATE_VPS_MACHINE_STATUS_SUCCESS,
      payload: res,
    });
  };

export const updateVpsStatusError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: VPS.UPDATE_VPS_MACHINE_STATUS_ERROR,
    });
  };

export const vpsResetPassword =
  (
    showToast = true,
    instanceId: Object,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(vpsResetPasswordInit(showToast, instanceId));
  };
export const vpsResetPasswordInit =
  (
    showToast = false,
    instanceId: object,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS.UPDATE_VPS_MACHINE_PASSWORD_INITIALIZATION,
    });
    apiCall(
      UPDATE_VPS_MACHINE_PASSWORD,
      (res: Object) => dispatch(vpsResetPasswordSuccess(res)),
      (err: any) => dispatch(vpsResetPasswordError()),
      instanceId,
      METHOD.PUT,
      { showToast },
    );
  };

export const vpsResetPasswordSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS.UPDATE_VPS_MACHINE_PASSWORD_SUCCESS,
      payload: res,
    });
  };

export const vpsResetPasswordError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: VPS.UPDATE_VPS_MACHINE_PASSWORD_ERROR,
    });
  };

export const updateVpsDetail =
  (
    showToast = true,
    data: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(updateVpsDetailInit(showToast, data));
  };

export const updateVpsDetailInit =
  (
    showToast = false,
    data: any,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS.UPDATE_VPS_MACHINE_DETAIL_INITIALIZATION,
    });
    apiCall(
      `${UPDATE_VPS_MACHINE_DETAIL}/${data.instanceId}`,
      (res: any) => dispatch(updateVpsDetailSuccess(res)),
      (err: any) => dispatch(updateVpsDetailError()),
      data,
      METHOD.PUT,
      { showToast },
    );
  };

export const updateVpsDetailSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: VPS.UPDATE_VPS_MACHINE_DETAIL_SUCCESS,
      payload: res,
    });
  };

export const updateVpsDetailError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: VPS.UPDATE_VPS_MACHINE_DETAIL_ERROR,
    });
  };
