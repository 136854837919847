/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { apiCall, METHOD } from "../../service/baseApiCall";
import {
  GET_UNAUTHORIZED_UTILITY_LIST,
  GET_UTILITY_LIST,
  GET_UTILITY_UNAUTHORIZED_LIST,
  GET_UTILITY_VERSION_LIST,
} from "../constants/action-types";
import { Store } from "../Actions";
import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { setToken } from "../../util/utils";
import {
  GET_UNAUTHORIZED_UTILITY_LIST_URL,
  GET_UTILITY_LIST_URL,
  GET_UTILITY_UNAUTHORIZED_LIST_URL,
  GET_UTILITY_VERSION_LIST_URL,
} from "../../service/apiEndPoints";
import { toast } from "react-toastify";

//Get Admin Roles List
export const getUtilityList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUtilityListInit(showToast));
  };

export const getUtilityListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_LIST.GET_UTILITY_LIST_INITIALIZATION,
    });
    apiCall(
      GET_UTILITY_LIST_URL,
      (res: Object) => dispatch(getUtilityListSuccess(res)),
      (err: any) => dispatch(getUtilityListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getUtilityListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_LIST.GET_UTILITY_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getUtilityListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_UTILITY_LIST.GET_UTILITY_LIST_ERROR,
    });
  };

export const getUtilityVersionList =
  (
    showToast = true,
    eaId: string,
    isGranted: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUtilityVersionListInit(showToast, eaId, isGranted));
  };

export const getUtilityVersionListInit =
  (
    showToast = true,
    eaId: string,
    isGranted: boolean,
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_INITIALIZATION,
    });
    apiCall(
      GET_UTILITY_VERSION_LIST_URL + eaId,
      (res: Object) => dispatch(getUtilityVersionListSuccess(res)),
      (err: any) => dispatch(getUtilityVersionListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getUtilityVersionListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_SUCCESS,
      payload: res,
    });
  };

export const getUtilityVersionListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_UTILITY_VERSION_LIST.GET_UTILITY_VERSION_LIST_ERROR,
    });
  };

export const getUnauthorizedUtilityList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUnauthorizedUtilityListInit(showToast));
  };

export const getUnauthorizedUtilityListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UNAUTHORIZED_UTILITY_LIST.GET_UNAUTHORIZED_UTILITY_LIST_INITIALIZATION,
    });
    apiCall(
      GET_UNAUTHORIZED_UTILITY_LIST_URL,
      (res: Object) => dispatch(getUnauthorizedUtilityListSuccess(res)),
      (err: any) => dispatch(getUnauthorizedUtilityListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getUnauthorizedUtilityListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UNAUTHORIZED_UTILITY_LIST.GET_UNAUTHORIZED_UTILITY_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getUnauthorizedUtilityListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_UNAUTHORIZED_UTILITY_LIST.GET_UNAUTHORIZED_UTILITY_LIST_ERROR,
    });
  };

export const getUtilityUnauthorizedList =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(getUtilityUnauthorizedListInit(showToast));
  };

export const getUtilityUnauthorizedListInit =
  (showToast = true): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_UNAUTHORIZED_LIST.GET_UTILITY_UNAUTHORIZED_LIST_INITIALIZATION,
    });
    apiCall(
      GET_UTILITY_UNAUTHORIZED_LIST_URL,
      (res: Object) => dispatch(getUtilityUnauthorizedListSuccess(res)),
      (err: any) => dispatch(getUtilityUnauthorizedListError()),
      {},
      METHOD.GET,
      { showToast },
    );
  };

export const getUtilityUnauthorizedListSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_UTILITY_UNAUTHORIZED_LIST.GET_UTILITY_UNAUTHORIZED_LIST_SUCCESS,
      payload: res.data,
    });
  };

export const getUtilityUnauthorizedListError =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_UTILITY_UNAUTHORIZED_LIST.GET_UTILITY_UNAUTHORIZED_LIST_ERROR,
    });
  };
