import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTicketList } from "../../redux/action";
import { Store } from "../../redux/Actions";
import { useNavigate } from "react-router";
import { Chip } from "@mui/material";
import { BugIcon, AdviseIcon, ProblemIcon } from "../../util/iconStyle";
import { FormattedMessage } from "react-intl";
import CenterLoader from "../../Components/common/CenterLoader/CenterLoader";
import NoDatFound from "../../Components/common/NoDatFound";
import Pagination from "../../Components/common/Paginate/Pagination";
import { customFormatDatetimeWithLocale, formatDate } from "../../util/utils";
import { useLocation } from "react-router-dom";
import { BsSortDown, BsSortUp } from "react-icons/bs";
import { Card, Col, Row } from "react-bootstrap";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

export const Tickets = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const data: any = location?.state;

  const ticketList = useSelector(
    (state: Store) => state.ticketReducer.ticketList,
  ) as any;
  // const issueList = useSelector(
  //   (state: Store) => state.ticketReducer.issueList
  // ) as any;
  const [ticketNodal, setTicketNodal] = useState(false);
  const navigate = useNavigate();
  const isLoading = useSelector(
    (state: Store) => state.ticketReducer.isLoading,
  ) as any;
  const totalRecord = useSelector(
    (state: Store) => state.ticketReducer.totalRecords,
  ) as any;

  const [pageNumber, setPageNumber] = useState<any>();
  const [pageLimit, setPageLimit] = useState<any>(
    process.env.REACT_APP_RECORDS_PER_PAGE || 10,
  );
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterQ, setFilterQ] = useState("");
  const [filterText, setFilterText] = useState("");
  const [Alltext, setAllText] = useState("");

  useEffect(() => {
    const tid = setTimeout(() => {
      setAllText(filterText);
    }, 1000);
    return () => {
      clearTimeout(tid);
    };
  }, [filterText]);

  useEffect(() => {
    setFilter(filterText);
  }, [Alltext]);

  const setFilter = (text: string) => {
    let q = "";
    if (text.length > 0) q = q + `&q=${text?.trim()}`;

    setFilterQ(q);
    setPageNumber(1);
    return 0;
  };

  const setSorting = (prop) => {
    if (sortBy === prop) {
      setSortOrder(sortOrder === "DESC" ? "ASC" : "DESC");
    } else {
      setSortBy(prop);
      setSortOrder("ASC");
    }
  };

  useEffect(() => {
    if (pageNumber && pageLimit) {
      dispatch(
        getTicketList(
          false,
          pageNumber,
          { by: sortBy, order: sortOrder },
          filterQ,
          parseInt(pageLimit),
        ),
      );
    }
  }, [filterQ, sortOrder, sortBy, pageNumber, pageLimit]);

  useEffect(() => {
    const storedPageNumber = localStorage.getItem("pageNumber");
    const storedPageLimit = localStorage.getItem("pageLimit");
    if (storedPageNumber && storedPageLimit) {
      setPageNumber(storedPageNumber);
      setPageLimit(storedPageLimit);
    } else {
      setPageNumber(1);
      setPageLimit(10);
    }
  }, []);

  useEffect(() => {
    // dispatch(getTicketIssueTypeList());
  }, []);

  const ticketDetail = (id: any) => {
    localStorage.setItem("pageNumber", pageNumber);
    localStorage.setItem("pageLimit", pageLimit);
    navigate("/ticket/" + id, { state: { pageProps: pageNumber } });
  };

  const onPageChange = (pageNum) => {
    localStorage.setItem("pageNumber", pageNum.toString());
    localStorage.setItem("pageLimit", pageLimit);
    setPageNumber(pageNum);
  };

  const locale = useSelector((state: Store) => state.userDataReducer.local);

  return (
    <div className="content-wrapper">
      {isLoading && <CenterLoader />}
      <div className="content">
        <header className="page-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h1>
                {" "}
                <FormattedMessage id="ticket" defaultMessage="Ticket" />
              </h1>
            </div>
            <button
              className="btn btn-primary"
              onClick={() => navigate("/tickets/add")}
            >
              <FormattedMessage
                id="ticket.Create"
                defaultMessage="Create Ticket"
              />
            </button>
          </div>
        </header>

        <section className="page-content container-fluid">
          <div className="card clearfix">
            <div className="card-body p-0 clearfix ">
              <Row className="p-20">
                <Col style={{ display: "inline-flex" }}>
                  <label style={{ position: "absolute", bottom: "0" }}>
                    <FormattedMessage id="Filter" defaultMessage="Filter" />
                    {": "}
                  </label>
                  <input
                    onChange={(e) => {
                      setFilterText(e.target.value);
                    }}
                    type="text"
                    style={{
                      maxWidth: "220px",
                      paddingLeft: "39px",
                      borderRadius: "0px",
                      border: "none",
                      borderBottom: "1px solid",
                    }}
                    className="form-control"
                  />
                </Col>
              </Row>
              {ticketList && ticketList.length ? (
                <>
                  {" "}
                  <Table
                    responsive
                    hover
                    cellPadding={3}
                    cellSpacing={2}
                    striped
                  >
                    <thead>
                      <tr>
                        <th
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("id")}
                        >
                          #
                          {sortBy === "id" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          key="head_summary"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("summary")}
                        >
                          {" "}
                          <FormattedMessage
                            id="ticket.Summary"
                            defaultMessage="Summary"
                          />
                          {sortBy === "summary" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          key="head_type"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("type")}
                        >
                          {" "}
                          <FormattedMessage
                            id="ticket.Type"
                            defaultMessage="Type"
                          />
                          {sortBy === "type" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          key="head_software"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("software")}
                        >
                          {" "}
                          <FormattedMessage
                            id="ticket.Software"
                            defaultMessage="Software"
                          />
                          {sortBy === "software" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          key="head_version"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("version")}
                        >
                          <FormattedMessage
                            id="ticket.Version"
                            defaultMessage="Version"
                          />
                          {sortBy === "version" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          key="head_date"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("date")}
                        >
                          {" "}
                          <FormattedMessage
                            id="ticket.Date"
                            defaultMessage="Date"
                          />
                          {sortBy === "date" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                        <th
                          key="head_status"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSorting("status")}
                        >
                          {" "}
                          <FormattedMessage
                            id="ticket.Status"
                            defaultMessage="Status"
                          />
                          {sortBy === "status" ? (
                            sortOrder === "ASC" ? (
                              <BsSortDown />
                            ) : (
                              <BsSortUp />
                            )
                          ) : null}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketList.length > 0 &&
                        ticketList.map((i: any, index: number) => (
                          <tr key={i.id} onClick={() => ticketDetail(i.id)}>
                            <td>{i?.id}</td>
                            <td>{i?.title}</td>
                            <td>
                              {i?.type?.id === 2 && (
                                <i
                                  className="MuiListItemIcon-root-tbl"
                                  style={{
                                    fill: "rgb(255, 72, 72)",
                                    height: "16px",
                                  }}
                                >
                                  {" "}
                                  <BugIcon />
                                </i>
                              )}
                              {i?.type?.id === 1 && (
                                <i
                                  className="MuiListItemIcon-root-tbl"
                                  style={{
                                    fill: "rgb(223, 184, 20)",
                                    height: "16px",
                                  }}
                                >
                                  {" "}
                                  <AdviseIcon />
                                </i>
                              )}
                              {i?.type?.id === 3 && (
                                <i
                                  className="MuiListItemIcon-root-tbl"
                                  style={{ fill: "#9c27b0", height: "16px" }}
                                >
                                  {" "}
                                  <ProblemIcon />
                                </i>
                              )}
                              {i?.type?.name || "Bug"}
                            </td>
                            <td>{i?.software?.name}</td>
                            <td>{i?.version?.version}</td>
                            <td>
                              {i.updatedAt !== null
                                ? customFormatDatetimeWithLocale(
                                    i.updatedAt,
                                    locale,
                                  )
                                : ""}
                            </td>
                            <td className="text-capitalize">
                              {i?.status === "closed" ? (
                                <Chip
                                  variant="filled"
                                  label={i?.status}
                                  color="success"
                                />
                              ) : null}
                              {i?.status === "open" ? (
                                <Chip
                                  variant="outlined"
                                  label={i?.status}
                                  color="secondary"
                                />
                              ) : null}
                              {i?.status === "inprogress" ? (
                                <Chip
                                  variant="outlined"
                                  label="In Progress"
                                  color="default"
                                />
                              ) : null}
                              {i?.status === "reopen" ? (
                                <Chip
                                  variant="outlined"
                                  label={i?.status}
                                  color="warning"
                                />
                              ) : null}
                              {i?.status === "resolved" ? (
                                <Chip
                                  variant="outlined"
                                  label={i?.status}
                                  color="info"
                                />
                              ) : null}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {totalRecord > 10 && (
                    <>
                      <FormControl className="pagelimit">
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          <FormattedMessage
                            id="page_size"
                            defaultMessage="Page Size"
                          />
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={pageLimit}
                          onChange={(e: any) => {
                            setPageLimit(e.target.value);
                            localStorage.setItem("pageLimit", e.target.value);
                            localStorage.setItem("pageNumber", pageNumber);
                            setPageNumber(1);
                          }}
                        >
                          <FormControlLabel
                            value={10}
                            control={<Radio />}
                            label="10"
                          />
                          <FormControlLabel
                            value={20}
                            control={<Radio />}
                            label="20"
                          />
                          <FormControlLabel
                            value={50}
                            control={<Radio />}
                            label="50"
                          />
                        </RadioGroup>
                      </FormControl>
                      <div>
                        <Pagination
                          itemsPerPage={pageLimit}
                          totalPages={totalRecord}
                          onPageChage={onPageChange}
                          page={pageNumber - 1}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <p>{!isLoading && <NoDatFound />}</p>
              )}
            </div>
            <div className="card-body p-0"></div>
          </div>
        </section>
      </div>
      {/* <TicketModal
        ticketModal={ticketNodal}
        setTicketModal={() => setTicketNodal(false)}
        issueList={issueList}
        page={pageNumber}
      /> */}
    </div>
  );
};

export default Tickets;
