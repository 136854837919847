import { AxiosError } from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { customFetch } from "../../../util/axios";
import { GemsAdvertisementResponse } from "../types";
import { GetAllUserGemsAdvertisementsQueryDto } from "../schemas";

export interface GetAllUserGemsAdvertisementsResponse {
  gemsAdvertisementsCount: number;
  gemsAdvertisementsTotalPages: number;
  gemsAdvertisementsHasNextPage: boolean;
  gemsAdvertisements: GemsAdvertisementResponse[];
}

export const getAllUserGemsAdvertisements = async (
  getAllUserGemsAdvertisementsQuery: GetAllUserGemsAdvertisementsQueryDto,
) => {
  const response = await customFetch.get("/users/gems-advertisements", {
    params: getAllUserGemsAdvertisementsQuery,
  });
  return response.data;
};

export const useGetAllUserGemsAdvertisements = (
  getAllUserGemsAdvertisementsQuery: GetAllUserGemsAdvertisementsQueryDto,
) => {
  const queryClient = useQueryClient();

  const query = useQuery<
    GetAllUserGemsAdvertisementsResponse,
    AxiosError | Error
  >({
    queryKey: [
      "users",
      "gems-advertisements",
      getAllUserGemsAdvertisementsQuery,
    ],
    queryFn: () =>
      getAllUserGemsAdvertisements(getAllUserGemsAdvertisementsQuery),
    keepPreviousData: true,
    staleTime: 60 * 60 * 1000,
  });

  const pageIndex = getAllUserGemsAdvertisementsQuery.pageIndex || 0;
  const { data, isPreviousData } = query;

  useEffect(() => {
    if (!isPreviousData && data?.gemsAdvertisementsHasNextPage) {
      const nextParams = {
        ...getAllUserGemsAdvertisementsQuery,
        pageIndex: pageIndex + 1,
      };

      queryClient.prefetchQuery({
        queryKey: ["users", "gems-advertisements", nextParams],
        queryFn: () => getAllUserGemsAdvertisements(nextParams),
      });
    }
  }, [
    getAllUserGemsAdvertisementsQuery,
    data,
    isPreviousData,
    pageIndex,
    queryClient,
  ]);

  return query;
};
