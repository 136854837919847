import { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import Select from "react-select";
import FileUploadImage from "../common/MediaUpload/FileUploadImage";
import { _RegisterUploadMedia } from "../../service/MediaUpload/classes.service";
import { dispatch } from "../../redux";
import { editClassSignal } from "../../redux/action";
import { useSelector } from "react-redux";
import { Store } from "../../redux/Actions";
import { AddSignalSchema } from "../../util/validationSchema";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "../common/Loader";
import { BsTrash } from "react-icons/bs";
import { getFileName } from "../../util/utils";
import { toast } from "react-toastify";
import CenterLoader from "../common/CenterLoader/CenterLoader";
import { FaTimesCircle } from "react-icons/fa";

const AddSignalModal = ({
  onAddSignal,
  signal,
  onCancle,
  showModal,
  classes,
}: any) => {
  const [selectedClass, setSelectedClass] = useState({});
  const onSaveLoading = useSelector(
    (state: Store) => state.signalReducer.onSaveLoading,
  );
  const local = useSelector((state: Store) => state.userDataReducer.local);

  const [buttonClick, setbuttonClick] = useState(false);
  const [settingFileName, setSettingFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mql5_link: Object.keys(signal).length > 0 ? signal?.mql5_link : "",
      setting_link: Object.keys(signal).length > 0 ? signal?.setting_link : "",
      classId: Object.keys(signal).length > 0 ? signal?.class?.id : 0,
      remarks: Object.keys(signal).length > 0 ? signal?.remarks : "",
      name: Object.keys(signal).length > 0 ? signal?.name : "",
      access_rights:
        Object.keys(signal).length > 0 ? signal?.access_rights : -1,
    },
    validationSchema: AddSignalSchema,
    onSubmit: (values) => {
      if (Object.keys(signal).length <= 0) {
        onAddSignal(values);
      } else {
        dispatch(editClassSignal(true, values, signal.id));
      }
      setbuttonClick(true);
    },
  });

  useEffect(() => {
    if (!onSaveLoading && buttonClick) {
      onCancle();
      resetForm();
      setbuttonClick(false);
    }
  }, [onSaveLoading]);

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    if (Object.keys(signal).length > 0) {
      setSelectedClass({
        class_name: signal?.class?.name,
        class_id: signal?.class?.id,
      });
      setSettingFileName(getFileName(signal.setting_link));
    } else {
      setSelectedClass({});
      setSettingFileName("");
    }
  }, [signal]);

  const handleDrop = async (acceptedFiles: any) => {
    if (acceptedFiles?.[0]?.name) {
      const extention = acceptedFiles[0].name.split(".")[1];

      if (extention === "set" || extention === "ini" || extention === "txt") {
        setLoading(true);
        const { fileUploadResponse, error }: any = await _RegisterUploadMedia(
          acceptedFiles[0] as File,
        );
        if (!error) {
          formik.setFieldValue("setting_link", fileUploadResponse);
          setSettingFileName(acceptedFiles[0].name);
          setLoading(false);
        }
      } else {
        setLoading(false);
        toast.error("Only .set,.ini,.txt file is allowed");
      }
    }
  };

  const { errors, touched, resetForm } = formik;
  return (
    <Modal centered size={"lg"} show={showModal} className="modal-content-md">
      <Modal.Header>
        <h4 className="modal-title">
          {Object.keys(signal).length > 0 ? (
            <FormattedMessage id="page.Edit" defaultMessage="Edit" />
          ) : (
            <FormattedMessage id="page.Add" defaultMessage="Add" />
          )}
          {local === "en" ? "  " : ""}
          <FormattedMessage
            id="signalmodal.Signal"
            defaultMessage="Signal"
          />{" "}
        </h4>
        <button className="close" onClick={onCancle}>
          <FaTimesCircle />
        </button>
      </Modal.Header>
      {loading && <CenterLoader />}
      <form onSubmit={(values: any) => formik.handleSubmit(values)}>
        <Modal.Body>
          <div className="form-group">
            <FormattedMessage id="signalmodal.Class" defaultMessage="Class" />
            <Select
              classNamePrefix="react-select-dropdown"
              value={selectedClass}
              onBlur={() => formik.setFieldTouched("classId", true)}
              onChange={(option: any) => {
                formik.setFieldValue("classId", option?.class_id);
                setSelectedClass(option);
              }}
              options={classes}
              name="classId"
              getOptionLabel={(option: any) => option.class_name}
              getOptionValue={(option: any) => option.class_id}
              placeholder={useIntl().formatMessage({
                id: "select.class",
                defaultMessage: "Select class",
              })}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
            {errors.classId && touched.classId && (
              <div className="error">
                <FormattedMessage
                  id={errors.classId.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>

          <div className="form-group">
            <FormattedMessage
              id="signalmodal.Link"
              defaultMessage=" MQL5 Link"
            />
            <input
              type="text"
              className="form-control"
              name="mql5_link"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mql5_link}
              placeholder={useIntl().formatMessage({
                id: "select.MqlLink",
                defaultMessage: "MQL5 Link",
              })}
            />
            {errors.mql5_link && touched.mql5_link && (
              <div className="error">
                <FormattedMessage
                  id={errors.mql5_link.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>

          <div className="form-group">
            <FormattedMessage id="signalmodal.Name" defaultMessage="Name" />
            <input
              type="text"
              className="form-control"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder={useIntl().formatMessage({
                id: "Signal.Name",
                defaultMessage: "Name",
              })}
            />
            {errors.name && touched.name && (
              <div className="error">
                <FormattedMessage
                  id={errors.name.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>

          <div className="form-group">
            <FormattedMessage
              id="signalmodal.Remarks"
              defaultMessage="Remarks"
            />
            <input
              type="text"
              className="form-control"
              name="remarks"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.remarks}
              placeholder={useIntl().formatMessage({
                id: "select.Remark",
                defaultMessage: "Remark",
              })}
            />
          </div>

          <div className="form-group">
            <FormattedMessage
              id="signalmodal.Access"
              defaultMessage="Access Control"
            />{" "}
            <br />
            <input
              type="radio"
              name="accessControl"
              id="ptall"
              onChange={() => formik.setFieldValue("access_rights", 0)}
              onBlur={() => formik.setFieldTouched("access_rights", true)}
              checked={formik.values.access_rights === 0}
            />{" "}
            <label htmlFor="ptall" className="mr-2">
              <FormattedMessage
                id="signalmodal.Public"
                defaultMessage=" Public to All"
              />{" "}
            </label>
            <input
              type="radio"
              id="ptClass"
              onChange={() => formik.setFieldValue("access_rights", 1)}
              onBlur={() => formik.setFieldTouched("access_rights", true)}
              checked={formik.values.access_rights === 1}
            />
            <label htmlFor="ptClass" className="mr-2">
              {" "}
              <FormattedMessage
                id="signalmodal.Public.class"
                defaultMessage=" Public to Class"
              />{" "}
            </label>
            <input
              type="radio"
              id="private"
              onChange={() => formik.setFieldValue("access_rights", 2)}
              onBlur={() => formik.setFieldTouched("access_rights", true)}
              checked={formik.values.access_rights === 2}
            />{" "}
            <label htmlFor="private" className="mr-2">
              {" "}
              <FormattedMessage
                id="signalmodal.Private"
                defaultMessage="Private"
              />{" "}
            </label>
            {errors.access_rights && touched.access_rights && (
              <div className="error">
                <FormattedMessage
                  id={errors.access_rights.toString()}
                  defaultMessage="require"
                />
              </div>
            )}
          </div>

          <div className="form-group">
            <FormattedMessage
              id="signalmodal.Setting"
              defaultMessage="Setting"
            />
            {!settingFileName || settingFileName?.trim().length <= 0 ? (
              <FileUploadImage
                handleDrop={handleDrop}
                accept=""
                optional={true}
              />
            ) : (
              <div>
                <Row className="align-items-center">
                  <Col md={9}>
                    <p className="m-0">{settingFileName} </p>
                  </Col>
                  <Col md={3} style={{ textAlign: "right" }}>
                    <button
                      className="border-delete-icon delete"
                      onClick={() => {
                        formik.setFieldValue("setting_link", "");
                        setSettingFileName("");
                      }}
                    >
                      <BsTrash />
                    </button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={(e) => {
              onCancle();
              e.preventDefault();
              resetForm();
            }}
            type="button"
          >
            <FormattedMessage id="page.Cancel" defaultMessage="Cancel" />{" "}
          </button>
          <button
            disabled={onSaveLoading}
            type="submit"
            className="btn btn-primary"
          >
            <span style={{ flexDirection: "row", display: "inline-flex" }}>
              {Object.keys(signal).length > 0 ? (
                <FormattedMessage id="page.Edit" defaultMessage="Edit" />
              ) : (
                <FormattedMessage id="page.Add" defaultMessage="Add" />
              )}
              {onSaveLoading && (
                <div className="ml-2">
                  {" "}
                  <Loader />
                </div>
              )}
            </span>
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddSignalModal;
